import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import '../../UserComponents/UserComponent.css';
import Card from 'react-bootstrap/Card';
import Logo1 from '../../assets/Acceptable_photos.png';
import Logo2 from '../../assets/unacceptable_photos1v2.png';
import { toast } from 'react-toastify';


import axios from "axios";
import { RegistrationSelect, RegistrationUpdateProfile, RegistrationUpload } from '../../AllApi/UserApi/UserApi';
import { ClassNames } from '@emotion/react';
import { Navigate, useNavigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { memberImgPath } from '../../AllApi/AdminApi/AdminApi';

const ThirdPage = (props) => {

   // Scroll to the top when component is mounted
 useEffect(() => {
  window.scrollTo(0, 0);
}, []);

  const [isLoading, setIsLoading] = useState(false); // Add isLoading state
  const [Image, setImage] = useState(null);
  const navigate=useNavigate()
  const data = JSON.parse(sessionStorage.getItem("status"))
   
  const userdata = JSON.parse(sessionStorage.getItem("userdata"));
  const userid=userdata.id
 

  
  const NewData = (e) => {
    e.preventDefault();
    // const userid = JSON.parse(sessionStorage.getItem("Userid"));
    setIsLoading(true)
  
    let formData = new FormData();
    const status="0"
    formData.append("file", Image);
    formData.append("member_id", userid);
    // formData.append("image_status",status);


    const member_id = formData.get("member_id");
    // const image_status = formData.get("image_status");
    const file = formData.get("file");
    for (const entry of formData.entries()) {
     
    }
   
    
    const finaldata={
      member_id:member_id,
      // image_status:image_status,
      file:file,
    }
   
    axios
      .post(RegistrationUpload, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })

      .then((res) => {
        setIsLoading(false); 
        if (res.data.message === "UPDATED") {
          // alert("File Uploaded Succesfully");
          toast.success('File Uploaded Succesfully!', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          sessionStorage.setItem("status", 6);
          props.onSubmit();

        }
      })
      .catch((err) => {
        setIsLoading(false);
        // alert("Failed to upload, try again");
        toast.error('Failed to upload, try again', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        console.log(err);
      });
  };

  const updatedata=()=>{
    setIsLoading(true)
    let formData = new FormData();
    const status="0"
    formData.append("file", Image);
    formData.append("member_id", userid);
    // formData.append("image_status",status);


    const member_id = formData.get("member_id");
    // const image_status = formData.get("image_status");
    const file = formData.get("file");
    for (const entry of formData.entries()) {
     
    }
   
    
    const finaldata={
      member_id:member_id,
      // image_status:image_status,
      file:file,
    }
   
    axios
      .post(RegistrationUpload, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })

      .then((res) => {
        setIsLoading(false);
        if (res.data.message === "UPDATED") {
          // alert("File Uploaded Succesfully");
          toast.success('File Uploaded Succesfully', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          
        }
      })
      .catch((err) => {
        setIsLoading(false);
        // alert("Failed to upload, try again");
        toast.error('Failed to upload, try again', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        
        console.log(err);
      });


  }

  const [update, setUpdate] = useState(false)
  const [updateimage, setupdateimage] = useState(false)
  //Get Api Call

  const [isEditing, setIsEditing] = useState(false);
 

  const handleEditClick = () => {
   
    
    setIsEditing(true);
    setUpdate(true)
  };
  const [userStatus, setUserStatus] = useState(null)




  const getdata = () => {

    

    axios
      .get(`${RegistrationSelect}${userid}`)
      .then((res) => {

        if (res.data.json !== null) {

          const api = res.data
          api.map((api, index) => {
            setImage(api.image)
           
          })
          setIsEditing(false)
          setupdateimage(true)
          setUpdate(true)
        }
      })
      .catch((error) => {
        // alert("failed to fetch data");
      });
  };
  useEffect(() => {
    setUserStatus(data)
    if (data >5) {
      getdata()
    }

  }, [data])
 

 const seedata =()=>{
  if(data == 9){
    navigate('/mainpage')
  }
  else{
  navigate('/payment')
  } 
}
   
const setImgfun=(e)=>{
  setupdateimage(false)
  setImage(e.target.files[0])
}


  return (
  <>
    
  <div className='m-0'>
  
  <Container className='container-third rounded p-3 my-3 p-md-5 '>
  <div className='pe-5 ' style={{ display: "flex", justifyContent: "end" }}>
    {

      userStatus >5
        ? (<button
          className='rounded mt-0' style={{ padding: "7px 16px", backgroundColor: "rgb(233, 224, 227)", border: "0px", color: "rgb(134, 28, 18)", marginBottom:"15px",marginTop:"-24px", fontSize: "20px" }} onClick={handleEditClick}> <b>Edit</b></button>

        ) : null
    }
  </div>
    <div className=' rounded shadow p-3 pb-2 py-sm-5' style={{ backgroundColor:"white"}} >
      <Row>
      <Form  onSubmit={NewData} encType="multipart/form-data">
      
      <Form.Group className="mb-3" controlId="formBasicCheckbox">
        <Form.Label><h5 style={{color:"rgb(134, 28, 18)"}}><b>Upload Your Photo / तुमचा फोटो उपलोड करा</b></h5></Form.Label>
        
     <div  className='pt-4'>

      <div style={{display:"flex",justifyContent:"center"}}>
          <b style={{color:"rgb(134, 28, 18)"}}>Admin reserves the right to approve/reject the photos (फोटो मंजूर/नाकारणे याचा अधिकार आयोजकांकडे राहील)</b>
      </div>

      <Row className='p-3'>

      <Col sm={12} md={4} className="pt-2 text-center">
      <Card className='text-center' style={{ width: '13rem' , border:"0px",height:"12rem" }}>
        <Card.Img variant="top" loading="lazy" src={updateimage ? `${memberImgPath}/${Image}`: (Image ? URL.createObjectURL(Image) : '') }  style={{ height: '8rem'}} />
        <Card.Body>
          <Card.Text className='text-center'>
          <b>Uploaded Image </b>
          </Card.Text>
        </Card.Body>
      </Card>
      </Col>   
      

      <Col sm={12} md={4} className="pt-2">
       <Card className='both text-center' style={{ width: '13rem', border:"0px" ,height:"14rem" }}>
        <Card.Img variant="top" className='acceptable text-center' src={Logo1} style={{ height: "180px",}} />
         <Card.Body>
          <Card.Text className='text-center'>
            <b style={{fontSize:"17px"}}>This is acceptable</b>
            <p style={{color:"rgb(0, 247, 0)",fontSize:"45px"}}><b><i class="fa-solid fa-check"></i></b></p>
            <b style={{fontSize:"16px"}}>असे फोटो स्वीकारले जातील</b>
          </Card.Text>
        </Card.Body>
      </Card>
      </Col>

      
      <Col sm={12} md={4} className="pt-3 pt-md-2">
       <Card className='text-center' style={{ width: '13rem' , border:"0px",height:"14rem" }}>
        <Card.Img variant="top" className='unacceptable text-center'  src={Logo2} style={{ height: '8rem',}} />
        <Card.Body>
          <Card.Text className='text-center'>
            <b style={{fontSize:"17px"}}>This is not acceptable </b>
            <p style={{color:"red",fontSize:"45px",lineHeight:"0px",}}><b><i class="fa-solid fa-xmark"></i></b></p>
            <b style={{fontSize:"16px"}}>असे फोटो नामंजूर केले जातील</b>
          </Card.Text>
        </Card.Body>
      </Card>
      </Col>

      </Row>

    </div>

    <div className='row'>
      <div className='col-12 col-md-2'></div>
          <div className='col-12 col-md-8'>
          <Form.Control
                  type="file" style={{marginTop:"80px"}}
                  accept=".jpg,.jpeg,.png"
                  onChange={(e) => setImgfun(e)}
                  required
                  name="file"
                />
          </div>
      <div className='col-12 col-md-2'></div>
    </div>
      

      </Form.Group>

      {/* <div className="pt-3 mt-5" style={{ display: "flex", justifyContent: "center" }}>
            {isLoading ? (
              <Button 
                type="submit"
                style={{
                  display: "flex",
                  backgroundColor: "rgb(134, 28, 18)",
                  border: "none",
                  justifyContent: "center",
                }}
                className="my-4 py-2 px-4 mt-5"
              >
                Submitting....
              </Button>
            ) : update === false ? (
              <Button 
                type="submit"
                style={{
                  display: "flex",
                  backgroundColor: "rgb(134, 28, 18)",
                  border: "none",
                  justifyContent: "center",
                }}
                className="my-4 py-2 px-4 mt-5"
              >
                Submit
              </Button>
            ) : null}
          </div> */}

          <div style={{ display: 'flex', justifyContent: 'end', paddingTop: '20px' }}>
            {!update ? (
              <button className='rounded py-2 px-4 shadow' type='submit' disabled={isLoading} 
                    style={{
                      backgroundColor: 'rgb(134, 28, 18)',
                      border: '0px',
                      color: 'white',
                      fontSize: '20px',
                    }}>
                {isLoading ? 'Saving...' : 'Save'}
              </button>
            ) : (
              <button className='btn btn-save shadow' type='button' onClick={updatedata} disabled={isLoading} 
               style={{
                      backgroundColor: 'rgb(134, 28, 18)',
                      border: '0px',
                      color: 'white',
                      fontSize: '20px',
                    }}>
                {isLoading ? 'Updating...' : 'Update'}
              </button>
            )}
          </div>

    </Form>
      </Row>
    </div>
    {/* <div  style={{ display: "flex", justifyContent: "center" }}>
            {isLoading ? (
              <button className='rounded' style={{ padding: "7px 16px", backgroundColor: "rgb(233, 224, 227)", border: "0px", color: "rgb(134, 28, 18)", marginTop:"20px",marginBottom: "-22px", fontSize: "20px" }} > <b>Updating...</b></button>
            ) : update === true ? (
              <button onClick={updatedata} className='rounded' style={{ padding: "7px 16px", backgroundColor: "rgb(233, 224, 227)", border: "0px", color: "rgb(134, 28, 18)", marginTop:"20px",marginBottom: "-22px", fontSize: "20px" }} > <b>Update</b></button>
            ): null
            }


          </div> */}
          <div  style={{ display: "flex", justifyContent: "end" }}>
            { update === true ? (
              <button onClick={seedata} className='rounded mb-0' style={{ padding: "7px 16px", backgroundColor: "rgb(233, 224, 227)", border: "0px", color: "rgb(134, 28, 18)", marginTop:"20px",marginBottom: "-22px", fontSize: "20px" }} > <b>See Data</b></button>
            ): null
            }


          </div>
  </Container>
  
  </div>
  </>
  )
}

export default ThirdPage




