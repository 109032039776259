import React, { useState, useEffect } from 'react'
import './EditProfileCSS.css'
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Form, Row, Col } from 'react-bootstrap';
import men1 from "../../../assets/men1.jfif";
import logo from "../../../assets/Mainlogo.jpg";
import {useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import AdminMasterHeader from '../../../CommonComponenets/AdminMasterHeader';
import { EducationMaster, SubeducationMaster, subcastMaster, IncomeMaster, RegsitrationSave, StateMaster, DistrictMaster, TalukaMaster, RegistrationSelect, } from '../../../AllApi/UserApi/UserApi';
import { adminUpdateProfile, memberImgPath } from '../../../AllApi/AdminApi/AdminApi';
import { toast } from 'react-toastify';

//===================================multi select=============================================================

import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { style } from '@mui/system';
import AdminMasterFooter from '../../../CommonComponenets/AdminMasterFooter';

//==============================================================================================

const EditMainPage = () => {

  const navigate = useNavigate()
  const location = useLocation();
  const select_member = location.state?.member_id;

  //----------Registration Form basic information data save------------//

  const [selectedOptions, setSelectedOptions] = useState([]);

  const [Enfname, setEnfname] = useState()
  const [Enmname, setEnmname] = useState()
  const [Enlname, setEnlname] = useState()
  // const [Mrfname, setMrfname] = useState()
  // const [Mrmname, setMrmname] = useState()
  // const [Mrlname, setMrlname] = useState()
  const [Gender, setGender] = useState()
  const [Maritialsatatus, setMaritialsatatus] = useState()
  const [Complextion, setComplextion] = useState()
  const [Disability, setDisability] = useState()
  const [Dateob, setDateob] = useState()
  const [BirthTime, setBirthTime] = useState()
  const [Height, setHeight] = useState()
  const [SubCaste, setSubCaste] = useState()

  const [Education, setEducation] = useState()
  const [EduCategory, setEduCategory] = useState([])
  const [SubeducationData, setSubeducationData] = useState([]);
  const [EduType, setEduType] = useState()
  const [OccupationDetail, setOccupationDetail] = useState()
  const [Othereducation, setOthereducation] = useState()
  const [AnnualIncome, setAnnualIncome] = useState()

  const [FaFNameEn, setFaFNameEn] = useState()
  const [FaMNameEn, setFaMNameEn] = useState()
  const [FaLNameEn, setFaLNameEn] = useState()
  // const [FaFNameMr, setFaFNameMr] = useState()
  // const [FaMNameMr, setFaMNameMr] = useState()
  // const [FaLNameMr, setFaLNameMr] = useState()
  const [FarmAcar, setFarmAcar] = useState()
  const [FarmGuntha, setFarmGuntha] = useState()
  const [OtherProperty, setOtherProperty] = useState()
  const [MarriedBro, setMarriedBro] = useState()
  const [UnMarriedBro, setUnMarriedBro] = useState()
  const [MarriedSis, setMarriedSis] = useState()
  const [UnMarriedSis, setUnMarriedSis] = useState()
  const [Compatible, setCompatible] = useState(0);
  const [Businessman, setBusinessman] = useState(0);
  const [Serviceman, setServiceman] = useState(0);
  const [Farmer, setFarmer] = useState(0);
  const [HouseWife, setHouseWife] = useState(0);

  const [AddressEn, setAddressEn] = useState()
  const [CityEn, setCityEn] = useState()
  // const [AddressMr, setAddressMr] = useState()
  // const [CityMr, setCityMr] = useState()
  const [State, setState] = useState()
  const [District, setDistrict] = useState()
  const [Taluka, setTaluka] = useState()
  const [Email, setEmail] = useState()
  const [Mobno1, setMobno1] = useState()
  const [Mobno2, setMobno2] = useState()

  const [Member_id,setMember_id]=useState()
  const [Regid,setRegid]=useState()
  const [image, setImage] = useState('');
  const [updateImage, setUpdateImage] = useState('');
  const [gender1, setGender1] = useState('');
  
  


  //----------------------updateData--------------------------------
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setUpdateImage(file);
  };
  


  const updatedata = (event) => {

    event.preventDefault()

    const idArray = EduCategory.map((item) => item.id);
    const newEduCategory = idArray.join(", ");

    const regdata = {
      reg_id:Regid,
      gender: Gender,
      marital_status: Maritialsatatus,
      disability: Disability,
      complextion: Complextion,
      en_fname: Enfname,
      en_mname: Enmname,
      en_lname: Enlname,
      mr_fname: Enfname,
      mr_mname: Enmname,
      mr_lname: Enfname,
      dob: Dateob,
      birth_time: BirthTime,
      height: Height,
      subcaste_id: SubCaste,
      edu_id: Education,
      subedu_id: newEduCategory,
      occupation: EduType,
      occupation_detail: OccupationDetail,
      other_education: Othereducation,
      income_id: AnnualIncome,
      en_address: AddressEn,
      mr_address: AddressEn,
      en_village: CityEn,
      mr_village: CityEn,
      taluka_id: Taluka,
      district_id: District,
      state_id: State,
      emailid: Email,
      mobileno: Mobno1,
      mobileno2: Mobno2,
      en_fatherfname: Enmname,
      en_fathermname: FaMNameEn,
      en_fatherlname: Enlname,
      mr_fatherfname: Enmname,
      mr_fathermname: FaMNameEn,
      mr_fatherlname: Enlname,
      farm_ac: FarmAcar,
      farm_g: FarmGuntha,
      other_property: OtherProperty,
      b_married: MarriedBro,
      b_unmarried: UnMarriedBro,
      s_married: MarriedSis,
      s_unmarried: UnMarriedSis,
      exp_compatible: Compatible,
      exp_businessman: Businessman,
      exp_serviceman: Serviceman,
      exp_farmer: Farmer,
      exp_housewife: HouseWife,
      member_id:Member_id,

    }

  

  const formData = new FormData();
  formData.append("file", updateImage);


  for (const key in regdata) {
    formData.append(key, regdata[key]);
  }

    axios .put(adminUpdateProfile, formData)
    .then((res) => {
      if (res.data.message === "UPDATE") {
        toast.success('Profile UPDATED successfully!', {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        navigate('/admin/member/mp')
      } 
    })
    .catch((err) => {
      toast.error('Failed To Update, try again!!', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

    });

  }


  const getdata = () => {
    axios
      .get(`${RegistrationSelect}${select_member}`)
      .then((res) => {

        if (res.data.json !== null) {
          const api = res.data;

          api.map((api, index) => {            
            
            setRegid(api.reg_id)
            setImage(api.image);
            setGender(api.gender)
            setGender1(api.gender)
            setMaritialsatatus(api.marital_status)
            setDisability(api.disability)
            setComplextion(api.complextion)
            setEnfname(api.en_fname)
            setEnmname(api.en_mname)
            setEnlname(api.en_lname)
            const [day, month, year] = api.dob.split('/');
            const convertedDate = `${year}-${month}-${day}`;
            setDateob(convertedDate)
            
            const timeParts = api.birth_time.split(" ");
            if (timeParts.length === 2) {
              const [time, ampm] = timeParts;
              const [hoursStr, minutesStr] = time.split(":");
              let hours = parseInt(hoursStr, 10);
              if (ampm === "PM" && hours !== 12) {
                hours += 12;
              } else if (ampm === "AM" && hours === 12) {
                hours = 0;
              }

              const formattedTime = `${hours
                .toString()
                .padStart(2, "0")}:${minutesStr}`;
              
              setBirthTime(formattedTime);
            }

            setHeight(api.height)
            setSubCaste(api.subcaste_id)

            setEducation(api.edu_id);

            axios
            .get(`${SubeducationMaster}/${api.edu_id}`)
            .then((res) => {
              if (res.data !== null) {
                setSubeducationData(res.data);
                const matchingOptions = res.data.filter((option) =>
                  api.subedu_id.includes(option.id)
                );
                setEduCategory(matchingOptions);               
              }
            })
            .catch((err) => {
              console.log(err);
            });

            setEduType(api.occupation)
            setOccupationDetail(api.occupation_detail)
            setOthereducation(api.other_education)
            setAnnualIncome(api.income_id)
            setAddressEn(api.en_address)
            setCityEn(api.en_village)
            setTaluka(api.taluka_id)
            setDistrict(api.district_id)
            setState(api.state_id)
            setEmail(api.emailid)
            setMobno1(api.mobileno)
            setMobno2(api.mobileno2)
            setFaFNameEn(api.en_fatherfname)
            setFaMNameEn(api.en_fathermname)
            setFaLNameEn(api.en_fatherlname)
            setFarmAcar(api.farm_ac)
            setFarmGuntha(api.farm_g)
            setOtherProperty(api.other_property)
            setMarriedBro(api.b_married)
            setUnMarriedBro(api.b_unmarried)
            setMarriedSis(api.s_married)
            setUnMarriedSis(api.s_unmarried)
            setMember_id(api.member_id)


            setHouseWife(api.exp_housewife);
            setCompatible(api.exp_compatible);
            setBusinessman(api.exp_businessman);
            setServiceman(api.exp_serviceman);
            setFarmer(api.exp_farmer);
            let expdata=[]
            
            if(api.exp_housewife == 1){
              expdata.push("HouseWife")
            }
            if(api.exp_compatible == 1){
              expdata.push("Compatible")
            }
            if(api.exp_businessman == 1){
              expdata.push("Businessman")
            }
            if(api.exp_serviceman == 1){
              expdata.push("Serviceman")
            }
            if(api.exp_farmer == 1){
              expdata.push("Farmer")
            }

            if(expdata.length >0){
              setSelectedOptions(expdata)
            }
            
          })

        }
      })
      .catch((error) => {
        console.log("==error==",error);        
      });
  };


  useEffect(() => {
      getdata();
  }, []);

  //master use state and other code
  const [EducationData, setEducationData] = useState([])
  const [SubCastData, setSubCastData] = useState([])
  const [IncomeData, setIncomeData] = useState([])
  const [StateData, setStateData] = useState([])
  const [DistictData, setDistictData] = useState([])
  const [TalukaData, setTalukaData] = useState([])

  const status = JSON.parse(sessionStorage.getItem("status"));


  const getEducationData = () => {
    axios.get(EducationMaster)
      .then((res) => {
        if (res.data !== null) {
          setEducationData(res.data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

    const getSubeducationData = (educationId) => {
      if (!Education) return;
      axios.get(`${SubeducationMaster}/${educationId}`) 
        .then((res) => {
          if (res.data !== null) {
            setSubeducationData(res.data)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }

  const getSubCastData = () => {
    axios.get(subcastMaster)
      .then((res) => {
        if (res.data !== null) {
          setSubCastData(res.data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const getIncomeData = () => {
    axios.get(IncomeMaster)
      .then((res) => {
        if (res.data !== null) {
          setIncomeData(res.data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const getStateData = () => {
    axios.get(StateMaster)
      .then((res) => {
        if (res.data !== null) {
          setStateData(res.data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const getDistrictData = () => {

    axios.get(`${DistrictMaster}${State}`)

      .then((res) => {
        if (res.data !== null) {
          setDistictData(res.data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const getTalukaData = () => {

    axios.get(`${TalukaMaster}${State}/${District}`)

      .then((res) => {
        if (res.data !== null) {
          setTalukaData(res.data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }


  useEffect(() => {
    getEducationData();
    getSubCastData();
    getIncomeData();
    getStateData();

   }, [])

  useEffect(() => {
    getDistrictData()
    getTalukaData()

    }, [State, District])

    //================checkbox====================
  
    const handleCheckboxChange = (event, option) => {
      if (event.target.checked) {
        if (selectedOptions.length < 2) {
          setSelectedOptions([...selectedOptions, option]);
        } else {
          toast.error('You can select only two options!', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          event.target.checked = false;
        }

      } else {
        setSelectedOptions(selectedOptions.filter((item) => item !== option));
      }
    
      if (option === 'Farmer') {
        setFarmer(event.target.checked ? 1 : 0);
      }
      if (option === 'Businessman') {
        setBusinessman(event.target.checked ? 1 : 0);
      }
      if (option === 'Compatible') {
        setCompatible(event.target.checked ? 1 : 0);
      }
      if (option === 'HouseWife') {
        setHouseWife(event.target.checked ? 1 : 0);
      }
      if (option === 'Serviceman') {
        setServiceman(event.target.checked ? 1 : 0);
      }
  
      
    };


//-----------------------------------------------------------------

  const handleClose = () => {
   navigate("/admin/member/mp")
  };
  
//--------------------------------------CSS--------------------
  const info = {
    display: "flex",
    alignItems: "center",
    paddingTop: "5px",
    backgroundColor: "#660505",
    color: "white",
  }
 
  
//============================================================================================================
  return (
    <>
    
    <div className="p-0">
        {' '}
        <AdminMasterHeader />{' '}
      </div>

        <div className="editProfileTitle col-12 py-1 ps-3 pe-3" style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
          <h2 style={{ fontWeight: '550'}}>Edit Profiles</h2>
          <button className='rounded'  onClick={handleClose}  style={{ padding: "5px 15px", backgroundColor: "red", border: "0px", color: "white", fontSize: "18px",width:"100px" }}> <b>Back</b> </button>
        </div>

        <form onSubmit={(event) => updatedata(event)}>
        
            <div className="row profile-details m-0">
      

             <div className='col-12 col-md-3 pt-3'> 
                          <div className="row">
                          <div className="col-2 col-md-0"></div>
                          <div className="col-8 col-md-12">
                          <img 
                            style={{border:"1px solid grey",height:"220px"}}
                                src={`${memberImgPath}/${image}`}
                                alt={`${image}'s Photo`}
                                  className="small-photo mb-2" />
                          </div>
                          <div className="col-2 col-md-0"></div>
                          </div>

                            <div className='col-12 d-flex'>
                              <div className='col-4'><p>Change Image :</p></div>
                              <div className='col-8'>
                              <input className='col-12' type="file" accept=".jpg,.jpeg,.png"  onChange={(e) => handleImageChange(e)} />
                              </div>
                            </div>
 
                  </div>
              

                <div className='col-12 col-md-9 mt-3'>
                
                  {/* Basic Information */}
                  <div className='row m-0 border mb-3 pb-3' style={{ backgroundColor: "#e9e0e3",boxShadow:"1px 1px 5px   grey" }}>
                
                      <div className='col-12 border-bottom mb-4' style={info}><h6>Basic Information/मुलभूत माहिती</h6></div>

                      <Row className="col-12 d-flex mb-2"  style={{flexWrap:"wrap",justifyContent:"space-between"}}>
                      <Col md={4} className='d-flex'>
                      <Form.Group as={Col}>
                        <Form.Label><p>First Name (पहिले नाव):</p></Form.Label>
                        <Form.Control  style={{marginTop:"-20px",marginBottom:"10px"}} value={Enfname} onChange={(e) => setEnfname(e.target.value)} required />
                        </Form.Group>
                      </Col>
                      <Col md={4} className='d-flex'>
                      <Form.Group as={Col}>
                        <Form.Label><p>Middle Name(मधले नाव):</p></Form.Label>
                        <Form.Control  style={{marginTop:"-20px",marginBottom:"10px"}} value={Enmname} onChange={(e) => setEnmname(e.target.value)} required  />
                        </Form.Group>
                      </Col>
                      <Col md={4} className='d-flex'>
                      <Form.Group as={Col}>
                        <Form.Label><p>Last Name (आडनाव):</p></Form.Label>
                        <Form.Control  style={{marginTop:"-20px",marginBottom:"10px"}} value={Enlname} onChange={(e) => setEnlname(e.target.value)} required />
                      </Form.Group>
                      </Col>
                    </Row>

                    {/* <Row className="col-12 d-flex mb-2"  style={{flexWrap:"wrap",justifyContent:"space-between"}}>
                    <Col sm={4} className='d-flex'>
                    <Form.Group as={Col}>
                        <Form.Label><p>पहिले नाव (मराठी):</p></Form.Label>
                        <Form.Control  style={{marginTop:"-20px",marginBottom:"10px"}} value={Mrfname} onChange={(e) => setMrfname(e.target.value)} required  />
                        </Form.Group>
                      </Col>
                      <Col sm={4} className='d-flex'>
                      <Form.Group as={Col}>
                        <Form.Label><p>मधले नाव (मराठी):</p></Form.Label>
                        <Form.Control  style={{marginTop:"-20px",marginBottom:"10px"}} value={Mrmname} onChange={(e) => setMrmname(e.target.value)} required />
                        </Form.Group>
                      </Col>
                      <Col sm={4} className='d-flex'>
                      <Form.Group as={Col}>
                        <Form.Label><p>आडनाव (मराठी):</p></Form.Label>
                        <Form.Control  style={{marginTop:"-20px",marginBottom:"10px"}} value={Mrlname} onChange={(e) => setMrlname(e.target.value)} required  />
                        </Form.Group>
                      </Col>
                    </Row> */}

                    <Row className="col-12 d-flex mb-2"  style={{flexWrap:"wrap",justifyContent:"space-between"}}>
                      <Col md={4} className='d-flex'>
                    <Form.Group as={Col}>
                        <Form.Label><p>Gender/लिंग:</p></Form.Label>
                         <Form.Select value={Gender} style={{marginTop:"-20px",marginBottom:"10px"}}
                                                    onChange={(e) => setGender(e.target.value)}
                                                    required
                                                    >
                                                    <option value="">Select Gender</option>
                                                    <option value="1">Male</option>
                                                    <option value="2">Female</option>
                                                </Form.Select>
                      </Form.Group>
                      </Col>
                      <Col md={4} className='d-flex'>
                      <Form.Group as={Col}> 
                        <Form.Label><p>Marital Status/वैवाहिक स्थिती:</p></Form.Label>
                        <Form.Select value={Maritialsatatus} style={{marginTop:"-20px",marginBottom:"10px"}}
                                                    onChange={(e) => setMaritialsatatus(e.target.value)}
                                                    required
                                                    >
                                                    <option value="">Select Marital Status </option>
                                                    <option value="1">Unmarried</option>
                                                    <option value="2">Widow</option>
                                                    <option value="3">Divorcee</option>
                                                </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col md={4} className='d-flex'>
                      <Form.Group as={Col}>
                        <Form.Label><p>Complextion/रंग:</p></Form.Label>
                        <Form.Select  value={Complextion} style={{marginTop:"-20px",marginBottom:"10px"}}
                                                        onChange={(e) => setComplextion(e.target.value)}
                                                        required
                                                       >
                                                        <option value="">Choose...</option>
                                                        <option value="Fair">Fair - गोरा</option>
                                                        <option value="Wheatish">Wheatish - गहू </option>
                                                        <option value="Dark">Dark - सावळा</option>
                                                </Form.Select></Form.Group>
                      </Col>
                      </Row>

                      <Row className="col-12 d-flex mb-2"  style={{flexWrap:"wrap",justifyContent:"space-between"}}>
                      <Col md={4} className='d-flex'>
                    <Form.Group as={Col}>
                        <Form.Label><p>Disability/दिव्यांग:</p></Form.Label>
                         <Form.Select  value={Disability} style={{marginTop:"-20px",marginBottom:"10px"}}
                                                  onChange={(e) => setDisability(e.target.value)}
                                                  required
                                                  >
                                                  <option value="1">No</option>
                                                  <option value="2">Yes</option>
                                                </Form.Select>
                    </Form.Group>
                      </Col>
                      <Col md={4} className='d-flex'>
                      <Form.Group as={Col} id="dateInput">
                        <Form.Label><p>Date of birth/जन्मतारीख:</p></Form.Label>
                        <Form.Control style={{marginTop:"-20px",marginBottom:"10px"}} type='date'  value={Dateob} onChange={(e) => setDateob(e.target.value)} required/> </Form.Group>
                      </Col>
                      <Col md={4} className='d-flex'>
                      <Form.Group as={Col}> 
                        <Form.Label><p>Birth time/जन्मवेळ:</p></Form.Label>
                        <Form.Control style={{marginTop:"-20px",marginBottom:"10px"}} type='time' value={BirthTime} onChange={(e) => setBirthTime(e.target.value)} required /> </Form.Group>
                      </Col>
                      </Row>

                      <Row className="col-12 d-flex mb-2"  style={{flexWrap:"wrap",justifyContent:"space-between"}}>
                      <Col md={4} className='d-flex'>
                      <Form.Group as={Col}>
                        <Form.Label><p>Height/उंची:</p></Form.Label>
                                              <Form.Select value={Height} style={{marginTop:"-20px",marginBottom:"10px"}}
                                                  onChange={(e) => setHeight(e.target.value)}
                                                  data-validetta="required"
                                                  required>
                                                  <option value="">Choose...</option>
                                                  <option value="Below 4 ft">Below 4ft
                                                  </option>
                                                  <option value="4 ft &nbsp; 06 in">4ft 06in
                                                  </option>
                                                  <option value="4 ft &nbsp; 07 in">4ft 07in
                                                  </option>
                                                  <option value="4 ft &nbsp; 08 in">4ft 08in
                                                  </option>
                                                  <option value="4 ft &nbsp; 09 in">4ft 09in
                                                  </option>
                                                  <option value="4 ft &nbsp; 10 in">4ft 10in
                                                  </option>
                                                  <option value="4 ft &nbsp; 11 in">4ft 11in
                                                  </option>
                                                  <option value="5 ft">5ft
                                                  </option>
                                                  <option value="5 ft &nbsp; 01 in">5ft 01in
                                                  </option>
                                                  <option value="5 ft &nbsp; 02 in">5ft 02in
                                                  </option>
                                                  <option value="5 ft &nbsp; 03 in">5ft 03in
                                                  </option>
                                                  <option value="5 ft &nbsp; 04 in">5ft 04in
                                                  </option>
                                                  <option value="5 ft &nbsp; 05 in">5ft 05in
                                                  </option>
                                                  <option value="5 ft &nbsp; 06 in">5ft 06in
                                                  </option>
                                                  <option value="5 ft &nbsp; 07 in">5ft 07in
                                                  </option>
                                                  <option value="5 ft &nbsp; 08 in">5ft 08in
                                                  </option>
                                                  <option value="5 ft &nbsp; 09 in">5ft 09in
                                                  </option>
                                                  <option value="5 ft &nbsp; 10 in">5ft 10in
                                                  </option>
                                                  <option value="5 ft &nbsp; 11 in">5ft 11in
                                                  </option>
                                                  <option value="6 ft">6ft
                                                  </option>
                                                  <option value="6 ft &nbsp; 01 in">6ft 01in
                                                  </option>
                                                  <option value="6 ft &nbsp; 02 in">6ft 02in
                                                  </option>
                                                  <option value="6 ft &nbsp; 03 in">6ft 03in
                                                  </option>
                                                  <option value="6 ft &nbsp; 04 in">6ft 04in
                                                  </option>
                                                  <option value="6 ft &nbsp; 05 in">6ft 05in
                                                  </option>
                                                  <option value="6 ft &nbsp; 06 in">6ft 06in
                                                  </option>
                                                  <option value="6 ft &nbsp; 07 in">6ft 07in
                                                  </option>
                                                  <option value="6 ft &nbsp; 08 in">6ft 08in
                                                  </option>
                                                  <option value="6 ft &nbsp; 09 in">6ft 09in
                                                  </option>
                                                  <option value="6 ft &nbsp; 10 in">6ft 10in
                                                  </option>
                                                  <option value="6 ft &nbsp; 11 in">6ft 11in
                                                  </option>
                                                  <option value="7 ft">7ft
                                                  </option>
                                                  <option value="Above 7 ft">Above 7ft
                                                  </option>
                                          </Form.Select>
                         </Form.Group>
                      </Col>
                      <Col md={4} className='d-flex'>
                      <Form.Group as={Col}>
                        <Form.Label><p>Subcast/पोटजाती:</p></Form.Label>                        
                                        <Form.Select aria-label="Default select example" style={{marginTop:"-20px",marginBottom:"10px"}}
                                          value={SubCaste}
                                          onChange={(e) => setSubCaste(e.target.value)}
                                          required >
                                          <option value="">Choose...</option>
                                          {
                                            SubCastData.map((value, index) => {
                                              return (
                                                <option value={value.id} key={index.id}>{value.subcastename}</option>
                                              )
                                            }
                                            )}
                                       </Form.Select>
                          </Form.Group>
                        </Col>
                        <Col md={4} className='d-flex'></Col>
                      </Row>
                   
                  </div>

                  {/* Education Information */}
                  <div className='row m-0 border mt-3 pb-3' style={{ backgroundColor: "#e9e0e3",boxShadow:"1px 1px 5px grey" }}>
                    <div className='col-12 border-bottom mb-3' style={info}><h6>Education & Occupation Information/शिक्षण आणि व्यवसाय माहिती</h6></div>

                    <Row className="col-12 d-flex mb-2"  style={{flexWrap:"wrap",justifyContent:"space-between"}}>
                      
                      
                      <Col md={6} className='d-flex'>
                      <Form.Group as={Col}>
                        <Form.Label><p style={{ lineHeight: "18px" }}>Education Category/शिक्षण श्रेणी:</p></Form.Label>                           
                                                  <Form.Select style={{marginTop:"-20px",marginBottom:"10px"}}
                                                    value={Education}
                                                    onChange={(e) => {
                                                    const newValue = e.target.value;
                                                   
                                                    setEducation(newValue);
                                                    setEduCategory([]);
                                                    if (newValue != null) {
                                                      getSubeducationData(newValue);
                                                    }
                                                  }}
                                                    required>
                                                    <option value="">Choose...</option>
                                                    {
                                                      EducationData.map((value, index) => {
                                                        return (
                                                          <option value={value.id} key={index.id}>{value.education_name}</option>
                                                        )
                                                      }
                                                      )}
                                                  </Form.Select>
                           </Form.Group>
                      </Col>

                      <Col md={6} className='d-flex'>
                      <Form.Group as={Col}>
                        <Form.Label><p>Education/शिक्षण: * You can select max. 3 options</p></Form.Label>                        
                        <Autocomplete
                              multiple
                              id="movie-tags"
                              value={EduCategory}
                              onChange={(event, newValue) => {
                              if (newValue.length <= 3) {
                                setEduCategory(newValue);
                              } else {
                                toast.warn("Please ,Select Only Three Options!", {
                                  position: "top-right",
                                  autoClose: 3000,
                                  hideProgressBar: false,
                                  closeOnClick: true,
                                  pauseOnHover: true,
                                  draggable: true,
                                });
                              }
                            }}
                              required
                              options={SubeducationData || []}
                              getOptionLabel={(option) =>
                                option.subeducation_name || "Unknown"
                              }
                              renderTags={(tagValue, getTagProps) =>
                                tagValue.map((option, index) => (
                                  <Chip
                                    style={{ color: "black"}}
                                    label={option.subeducation_name}
                                    {...getTagProps({ index })}
                                  />
                                ))
                              }
                              renderInput={(params) => (
                                <TextField {...params} placeholder="Select Education" style={{ color: "black",marginBottom:"10px",marginTop:"-20px"}}/>
                              )}
                            />
                        </Form.Group>
                      </Col>

                      <Col md={6} className='d-flex'>

                        <Form.Group as={Col}>
                        <Form.Label><p style={{ lineHeight: "18px" }}>Other Qualification(इतर पात्रता) :</p></Form.Label>
                        <Form.Control style={{marginTop:"-20px",marginBottom:"10px"}} value={Othereducation} onChange={(e) => setOthereducation(e.target.value)} />
                        </Form.Group>
                      </Col>

                      <Col md={6} className='d-flex'>
                      <Form.Group as={Col}>
                        <Form.Label><p style={{ lineHeight: "18px" }}>Occupation Type/
                          व्यवसायाचा प्रकार:</p></Form.Label>                        
                                                  <Form.Select aria-label="Default select example" style={{marginTop:"-20px",marginBottom:"10px"}}
                                                      value={EduType}
                                                      onChange={(e) => setEduType(e.target.value)}
                                                      required>
                                                      <option value="">Choose...</option><option>Business</option>
                                                      <option>Service</option><option>Student</option>
                                                      <option>Farmer</option><option>Other..</option>
                                                    </Form.Select>
                        </Form.Group>
                      </Col>

                      <Col md={6} className='d-flex'>
                      <Form.Group as={Col}>
                        <Form.Label><p>Annual Income/वार्षिक उत्पन्न:</p></Form.Label>                        
                                              <Form.Select aria-label="Default select example" style={{marginTop:"-20px",marginBottom:"10px"}}
                                                value={AnnualIncome}
                                                onChange={(e) => setAnnualIncome(e.target.value)}
                                                required>
                                                <option value="">Choose...</option>
                                                {
                                                  IncomeData.map((value, index) => {
                                                    return (
                                                      <option value={value.id} key={index.id}>{value.amount}</option>
                                                    )
                                                  }
                                                  )}
                                              </Form.Select>
                        </Form.Group>
                      </Col>

                      <Col md={6} className='d-flex'>
                        <Form.Group as={Col}>
                        <Form.Label><p style={{ lineHeight: "18px" }}>Occupation & Addrress/व्यवसाय आणि पत्ता:</p></Form.Label>
                        <Form.Control style={{marginTop:"-20px",marginBottom:"10px"}} value={OccupationDetail} onChange={(e) => setOccupationDetail(e.target.value)} required />
                        </Form.Group>
                      </Col>

                    </Row>
                    
                     
                   
                  </div>

                  {/* Family Information */}
                  <div className='row m-0 border mt-3' style={{ backgroundColor: "#e9e0e3",boxShadow:"1px 1px 5px grey" }}>
                    <div className='col-12 border-bottom mb-3' style={info}><h6>Family Information/कौटुंबिक माहिती</h6></div>
                    <Row className="col-12 d-flex mb-2"  style={{flexWrap:"wrap",justifyContent:"space-between"}}>
                      <Col md={4} className='d-flex'>
                      <Form.Group as={Col}> 
                        <Form.Label><p>Father First Name(वडिलांचे नाव):</p></Form.Label>
                         <Form.Control style={{marginTop:"-20px",marginBottom:"10px"}} type="text" value={Enmname} onChange={(e) => setFaFNameEn(e.target.value)} required disabled /></Form.Group>
                      </Col>
                      <Col md={4} className='d-flex'>
                      <Form.Group as={Col}>
                        <Form.Label><p>Father Middle Name(वडिलांचे मधले नाव):</p></Form.Label>
                          <Form.Control style={{marginTop:"-20px",marginBottom:"10px"}} type="text" value={FaMNameEn} onChange={(e) => setFaMNameEn(e.target.value)} required  /></Form.Group>
                      </Col>
                      <Col md={4} className='d-flex'>
                      <Form.Group as={Col}> 
                        <Form.Label><p>Father Last Name(वडिलांचे आडनाव):</p></Form.Label>
                         <Form.Control style={{marginTop:"-20px",marginBottom:"10px"}} type="text"  value={Enlname} onChange={(e) => setFaLNameEn(e.target.value)} required disabled /></Form.Group>
                      </Col>

                      {/* <Col md={4} className='d-flex'>
                      <Form.Group as={Col}>
                        <Form.Label><p>वडिलांचे नाव(मराठी):</p></Form.Label>
                          <Form.Control style={{marginTop:"-20px",marginBottom:"10px"}} type="text" value={FaFNameMr} onChange={(e) => setFaFNameMr(e.target.value)} required/></Form.Group>
                      </Col>
                      <Col md={4} className='d-flex'>
                      <Form.Group as={Col}>
                        <Form.Label><p>वडिलांचे मधले नाव(मराठी):</p></Form.Label>
                          <Form.Control style={{marginTop:"-20px",marginBottom:"10px"}} type="text" value={FaMNameMr} onChange={(e) => setFaMNameMr(e.target.value)} required /></Form.Group>
                      </Col>
                      <Col md={4} className='d-flex'>
                      <Form.Group as={Col}>
                        <Form.Label><p>वडिलांचे आडनाव(मराठी):</p></Form.Label>
                          <Form.Control style={{marginTop:"-20px",marginBottom:"10px"}} type="text" value={FaLNameMr} onChange={(e) => setFaLNameMr(e.target.value)} required  /></Form.Group>
                      </Col> */}
                  {gender1 == 1 && (
                    <>
                      <Col md={4} className='d-flex'>
                      <Form.Group as={Col}>
                        <Form.Label><p>Farm in Acar/शेत जमीन एकर:</p></Form.Label>                        
                                              <Form.Select aria-label="Default select example" style={{marginTop:"-20px",marginBottom:"10px"}}
                                                  value={FarmAcar}
                                                  onChange={(e) => setFarmAcar(e.target.value)}
                                                  required>
                                                  <option value="">Choose... </option>
                                                  <option >0</option><option >1</option>
                                                  <option >2</option><option >3</option>
                                                  <option >4</option><option >5</option>
                                                  <option >6</option><option >7</option>
                                                  <option >8</option><option >9</option>
                                                  <option >10</option><option >11</option>
                                                  <option >12</option><option >13</option>
                                                  <option >14</option><option >15</option>
                                                  <option >16</option><option >17</option>
                                                  <option >18</option><option >19</option>
                                                  <option >20</option><option >21</option>
                                                  <option >22</option><option >23</option>
                                                  <option >24</option><option >25</option>
                                                  <option >26</option><option >27</option>
                                                  <option >28</option><option >29</option>
                                                  <option >30</option><option >31</option>
                                                  <option >32</option><option >33</option>
                                                  <option >34</option><option >35</option>
                                                  <option >36</option><option >37</option>
                                                  <option >38</option><option >39</option>
                                                  <option >40</option><option >41</option>
                                                  <option >42</option><option >43</option>
                                                  <option >44</option><option >45</option>
                                                  <option >46</option><option >47</option>
                                                  <option >48</option><option >49</option>
                                                  <option >50</option><option >Above 50...</option>
                                                </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col md={4} className='d-flex'>
                      <Form.Group as={Col}>
                        <Form.Label><p>Farm in Guntha/शेत जमीन गुंठे:</p></Form.Label>                        
                               <Form.Select aria-label="Default select example" style={{marginTop:"-20px",marginBottom:"10px"}}
                                          value={FarmGuntha} 
                                          onChange={(e) => setFarmGuntha(e.target.value)}
                                          required>
                                          <option value="">Choose... </option>
                                          <option >0</option><option >1</option>
                                          <option >2</option><option >3</option>
                                          <option >4</option><option >5</option>
                                          <option >6</option><option >7</option>
                                          <option >8</option><option >9</option>
                                          <option >10</option><option >11</option>
                                          <option >12</option><option >13</option>
                                          <option >14</option><option >15</option>
                                          <option >16</option><option >17</option>
                                          <option >18</option><option >19</option>
                                          <option >20</option><option >21</option>
                                          <option >22</option><option >23</option>
                                          <option >24</option><option >25</option>
                                          <option >26</option><option >27</option>
                                          <option >28</option><option >29</option>
                                          <option >30</option><option >31</option>
                                          <option >32</option><option >33</option>
                                          <option >34</option><option >35</option>
                                          <option >36</option><option >37</option>
                                          <option >38</option><option >39</option>
                                </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col md={4} className='d-flex'></Col>
                      <Col md={12} className='d-flex'>
                      <Form.Group as={Col}>
                        <Form.Label><p>Other Property/इतर मालमत्ता:</p></Form.Label>
                          <Form.Control style={{marginTop:"-20px",marginBottom:"10px"}}
                                                          type="text"
                                                          value={OtherProperty} onChange={(e) => setOtherProperty(e.target.value)}  /> </Form.Group>
                      </Col>
                    </>
                    )}

                    </Row>

                    <Row className="col-12 d-flex mb-2"  style={{flexWrap:"wrap",justifyContent:"space-between"}}>
                   {/* Brother Details */}
                       <Row className='col-12 col-md-6 d-flex'>
                         <Form.Group as={Col} md={2}>
                              <Form.Label className='para'>
                                <p>Brother/भाऊ:</p>
                              </Form.Label>
                            </Form.Group>

                            <Form.Group as={Col} md={5}>
                              <Form.Label>
                                <p>Unmarried/अविवाहित</p>
                              </Form.Label>
                              <Form.Control style={{marginTop:"-20px",marginBottom:"10px"}}
                                type='number' value={UnMarriedBro}
                                onChange={(e) => setUnMarriedBro(e.target.value)}
                                required
                              />
                            </Form.Group>

                            <Form.Group as={Col} md={5}>
                              <Form.Label>
                                <p>Married/विवाहित</p>
                              </Form.Label>
                              <Form.Control style={{marginTop:"-20px",marginBottom:"10px"}}
                                type='number' value={MarriedBro}
                                onChange={(e) => setMarriedBro(e.target.value)}
                                required
                              />
                            </Form.Group>
                        </Row>

                         {/* Sister Details */}
                         <Row className='col-12 col-md-6 d-flex'>
                            <Form.Group as={Col} md={2}>
                              <Form.Label className='para'>
                                <p>Sister/बहीण:</p>
                              </Form.Label>
                            </Form.Group>

                            <Form.Group as={Col} md={5}>
                              <Form.Label>
                                <p>Unmarried/अविवाहित</p>
                              </Form.Label>
                              <Form.Control style={{marginTop:"-20px",marginBottom:"10px"}}
                                type='number'
                                value={UnMarriedSis}
                                onChange={(e) => setUnMarriedSis(e.target.value)}
                                required
                              />
                            </Form.Group>

                            <Form.Group as={Col} md={5}>
                              <Form.Label>
                                <p>Married/विवाहित</p>
                              </Form.Label>
                              <Form.Control style={{marginTop:"-20px",marginBottom:"10px"}}
                                type='number'
                                value={MarriedSis}
                                onChange={(e) => setMarriedSis(e.target.value)}
                                required
                              />
                            </Form.Group>
                          </Row>
                    </Row>
                  </div>

                  {/* Contact Information */}
                  <div className='row m-0 border mt-3 pb-3' style={{ backgroundColor: "#e9e0e3",boxShadow:"1px 1px 5px grey" }}> 
                    <div className='col-12 border-bottom mb-3' style={info}><h6>Contact Information/संपर्क माहिती</h6></div>

                    <Row className="col-12 d-flex mb-2"  style={{flexWrap:"wrap",justifyContent:"space-between"}}>
                      <Col md={7}>
                      <Form.Group as={Col}> 
                        <Form.Label><p>Address(पत्ता):</p></Form.Label>
                        <Form.Control style={{marginTop:"-20px",marginBottom:"10px"}} value={AddressEn} onChange={(e) => setAddressEn(e.target.value)} required /> </Form.Group>
                        
                      </Col>
                      <Col md={5}>
                      <Form.Group as={Col}> 
                        <Form.Label><p>Village/City(गाव/शहर):</p></Form.Label>
                        <Form.Control style={{marginTop:"-20px",marginBottom:"10px"}} value={CityEn} onChange={(e) => setCityEn(e.target.value)}
                               required /> </Form.Group>
                        
                      </Col>
                      </Row>

                    {/* <Row className="col-12 d-flex mb-2"  style={{flexWrap:"wrap",ustifyContent:"space-between"}}>
                      <Col md={7}>
                      <Form.Group as={Col}>
                        <Form.Label><p>पत्ता(मराठी):</p></Form.Label>
                         <Form.Control style={{marginTop:"-20px",marginBottom:"10px"}} value={AddressMr} onChange={(e) => setAddressMr(e.target.value)} required /> </Form.Group>
                        
                      </Col>
                      <Col md={5}>
                      <Form.Group as={Col}>
                        <Form.Label><p>गाव/शहर(मराठी):</p></Form.Label>
                         <Form.Control style={{marginTop:"-20px",marginBottom:"10px"}} value={CityMr} onChange={(e) => setCityMr(e.target.value)}
                                required /> </Form.Group>
                        
                      </Col>
                    </Row> */}

                    <Row className="col-12 d-flex mb-2"  style={{flexWrap:"wrap",justifyContent:"space-between"}}>
                     <Col md={4} className='d-flex'>
                     <Form.Group as={Col}>
                        <Form.Label><p>State(राज्य):</p></Form.Label>                        
                                            <Form.Select style={{marginTop:"-20px",marginBottom:"10px"}} aria-label="Default select example" value={State}
                                                  onChange={(e) => setState(e.target.value)}
                                                  required>
                                                  <option value="">Choose option</option>
                                                  {StateData.map((value, index) => {
                                                    return (
                                                      <option value={value.id} >{value.stateenname}:{value.statemrname}</option>
                                                    )
                                                  })
                                                  }
                                            </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col md={4} className='d-flex'>
                      <Form.Group as={Col}>
                        <Form.Label><p>District(जिल्हा):</p></Form.Label>                        
                                            <Form.Select style={{marginTop:"-20px",marginBottom:"10px"}} aria-label="Default select example" value={District}
                                                    onChange={(e) => setDistrict(e.target.value)}
                                                    required>
                                                    <option>Choose option</option>
                                                    {DistictData.map((value, index) => {
                                                      return (
                                                        <option value={value.id} >{value.districtenname}:{value.districtmrname}</option>
                                                      )
                                                    })
                                                    }
                                            </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col md={4} className='d-flex'>
                      <Form.Group as={Col}>
                        <Form.Label><p>Taluka(तालुका):</p></Form.Label>                        
                                                <Form.Select style={{marginTop:"-20px",marginBottom:"10px"}} aria-label="Default select example" value={Taluka}
                                                              onChange={(e) => setTaluka(e.target.value)}
                                                              required>
                                                              <option value="">Choose option</option>
                                                              {TalukaData.map((value, index) => {
                                                                return (
                                                                  <option value={value.id} >{value.talukaenname}:{value.talukamrname}</option>
                                                                )
                                                              })
                                                              }
                                                </Form.Select>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row className="col-12 d-flex mb-2"  style={{flexWrap:"wrap",justifyContent:"space-between"}}>
                    <Col md={4} className='d-flex'>
                    <Form.Group as={Col}>
                        <Form.Label><p style={{ lineHeight: "18px" }}>Email/ईमेल:</p></Form.Label>
                         <Form.Control style={{marginTop:"-20px",marginBottom:"10px"}} type="email" value={Email} onChange={(e) => setEmail(e.target.value)} required/> </Form.Group>
                      </Col>
                      <Col md={4} className='d-flex'>
                      <Form.Group as={Col}>
                        <Form.Label><p style={{ lineHeight: "18px" }}>Mobile No-1/मोबाईल नंबर-1:</p></Form.Label>
                         <Form.Control style={{marginTop:"-20px",marginBottom:"10px"}} type="tell" pattern="[0-9]{10}" maxLength="10" value={Mobno1} onChange={(e) => setMobno1(e.target.value)} required /> </Form.Group>
                      </Col>
                      <Col md={4} className='d-flex'>
                      <Form.Group as={Col}>
                        <Form.Label><p style={{ lineHeight: "18px" }}>Mobile No-2/मोबाईल नंबर-2:</p></Form.Label>
                         <Form.Control style={{marginTop:"-20px",marginBottom:"10px"}} pattern="[0-9]{10}" type="tell" maxLength="10" value={Mobno2} onChange={(e) => setMobno2(e.target.value)} required /></Form.Group>
                      </Col>
                    </Row>
                  </div>

                  {/* Expectations about spouse */}

                  <div  className='row m-0 border mt-3' style={{ backgroundColor: "#e9e0e3",boxShadow:"1px 1px 5px grey" }}>
                  <div className='col-12 border-bottom mb-3' style={info}><h6>Expectations about spouse -/जोडीदाराबाबत अपेक्षा</h6></div>
                  { Gender === 1 ? (
                  <Col sm={12} md={3}>
                  <label
                    className='pt-1  pb-md-3'
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                  
                  <input
                      type='checkbox'
                      name='HouseWife'
                      value='1'
                      onChange={(event) =>
                        handleCheckboxChange(event, 'HouseWife')
                      }  
                      defaultChecked={selectedOptions}
                     checked={selectedOptions.includes('HouseWife')}
                    />
                    <h6 className='ms-2 pt-2'>Homemaker/ गृहिणी</h6>
                  </label>
                </Col>
              ) : null}

                  <Col sm={12} md={3}>
                    <label
                      className='pt-1  pb-md-3'
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                        <input
                      type='checkbox'
                      name='compatible'
                      value='1'
                      onChange={(event) =>
                        handleCheckboxChange(event, 'Compatible')
                      }
                      defaultChecked={selectedOptions}
                      checked={selectedOptions.includes('Compatible')}
                    />
                    <h6 className='ms-2 pt-2'>Compatible/अनुरूप</h6>
                  </label>
                </Col>
                  <Col sm={12} md={2}>
                    <label
                      className='pt-1  pb-md-3'
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <input
                        type='checkbox'
                        name='businessman'
                        value='1'
                        onChange={(event) =>
                          handleCheckboxChange(event, 'Businessman')
                        }
                        defaultChecked={selectedOptions}
                        checked={selectedOptions.includes('Businessman')}
                      />
                      <h6 className='ms-2 pt-2'>Business/व्यवसाय</h6>
                    </label>
                  </Col>
                  <Col sm={12} md={2}>
                  <label
                    className='pt-1  pb-md-3'
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <input
                      type='checkbox'
                      name='serviceman'
                        value='1'
                        onChange={(event) =>
                          handleCheckboxChange(event, 'Serviceman')
                        }
                        defaultChecked={selectedOptions}
                      checked={selectedOptions.includes('Serviceman')}
                    />
                    <h6 className='ms-2 pt-2'>Service/नोकरी</h6>
                  </label>
                </Col>
                <Col sm={12} md={2}>
                  <label
                    className='pt-1 pb-md-3'
                    style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <input
                    type='checkbox'
                    name='farmer'
                    value='1'
                    defaultChecked={selectedOptions}
                    onChange={(event) =>
                      handleCheckboxChange(event, 'Farmer')
                    }
                    checked={selectedOptions.includes('Farmer')}
                  />
                  <h6 className='ms-2 pt-2'>Farmer/शेतकरी</h6>
                </label>
              </Col>
            </div>


                    {/* Buttons */}
                  <div className='row p-0 d-flex m-0 mb-md-3' style={{justifyContent:"center",textAlign:"end",}}>
                    <div>
                        <div className='mt-4' style={{ display: "flex", justifyContent: "end", }}>
                                <button  type='submit' className='rounded' style={{ padding: "8px 22px", backgroundColor: "orange", color: "black",border:"none", marginBottom: "30px", fontSize: "18px" }} > <b>Update</b></button>
                             
                        </div>
                    </div>
                   
                </div>
                 
                </div>
               
               <div className="col-12 col-md-1 pt-3"></div>


               <AdminMasterFooter/>
              </div>

        </form>

    </>
  )
}

export default EditMainPage