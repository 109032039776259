import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import UserMasterFooter from '../UserCommonComponent/UserMasterFooter';
import Logo from '../../assets/Mainlogo.jpg';
import Logo2 from '../../assets/logo2.png';
import { Link } from 'react-router-dom';
import {useNavigate} from 'react-router-dom';


function TermsAndCondition() {

 // Scroll to the top when component is mounted
 useEffect(() => {
  window.scrollTo(0, 0);
}, []);


  return (
 <div>
    <div>
    <Row className='header m-0 p-0 py-1'>
        <Col md={3} sm={12} style={{display:"flex",justifyContent:"center"}}>
            <div>
                <img src={Logo2} style={{mixBlendMode:"darken"}} alt="" width={100} height={100}/>
            </div> 
       </Col> 
       
       <Col md={6} sm={12} style={{display:"flex",justifyContent:"center"}}>
        <div className='text-center '>
            <h5 className='fw-bolder'>दक्षिण भारत जैन सभेचे</h5>
            <h3 className='fw-bolder'>शेठ रा. ध. दावडा दिगंबर जैन बोर्डिंग, सांगली </h3>
            <h5 className='fw-bolder'>जैन वधू - वर नोंदणी 2024</h5>
        </div>
        </Col> 

        <Col md={3} sm={12} style={{display:"flex",justifyContent:"center"}}>
            <div>
                <img src={Logo} style={{mixBlendMode:"darken"}} alt="" width={100} height={100}/>
            </div> 
       </Col> 

        {/* <Col md={1} sm={12} style={{display:"flex",justifyContent:"start"}}>
            <Nav.Link onClick={logoutFun}>Log-Out <i class="fa-solid fa-right-from-bracket"></i></Nav.Link>
        </Col>       */}
      </Row> 

      <Navbar expand="lg" style={{backgroundColor: "#660505"}}>
      <Container fluid>
        <Navbar.Brand href="#"></Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" style={{backgroundColor:"rgb(233, 224, 227)"}} />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="m-auto my-2 my-lg-0"
            style={{ maxHeight: '100px' }}
            navbarScroll
          >
            <Nav.Link href="/" className=' mx-2 text-white'>Home</Nav.Link>
            <Nav.Link href="/#/ReturnAndRefundPrivacy" className=' mx-2 text-white'>Return & Refund Policy</Nav.Link>
            <Nav.Link href="/#/Cancelation" className=' mx-2 text-white'>Cancelation Policy</Nav.Link>
            <Nav.Link href="/#/terms&Condition" className=' mx-2 text-white'>Terms & Condition</Nav.Link>
            <Nav.Link href="/#/PrivacyPolicy" className=' mx-2 text-white'>Privacy Policy</Nav.Link>
          </Nav>
          
        </Navbar.Collapse>
        </Container>
    </Navbar>
    </div>
    <div className='p-md-4 p-3 mx-md-5'>
      <Nav.Link href="/terms&Condition" style={{color: "rgb(134, 28, 18)"}}><h3><b>Terms & Conditions</b></h3></Nav.Link>
      <p>
      These terms and conditions (“Terms and Conditions”) control your use of this website: registration. jainboardingsangli.com (Website).
      </p>
      <p>
      You refers to a user or a paying customer. If You are a company or another person who gives access to Institute services, you agree to take responsibility in full in case of damages or indemnification that could properly lie against the customer.
      </p>
      <p>
      By using or accessing the information on this website, including, but not limited to downloading or accessing services through this Website or through designated Software of the Institute; You agree to abide by the terms and conditions set forth in these “Terms and Conditions”:
      </p>
      <h5>GENERAL TERMS OF USE</h5>
      <p>
      Registering at this Website does not entitle You to the right of admission/selection for any course. The Institute Selection Committee reserves the right to select or reject a candidate without any notice or assigning any reason whatsoever.
      </p>
      <p>
      Selected candidates are required to comply with the rules laid down under the various Policies and Institute (Dakshin Bharat Jain Sabha's SRDDD Jain Boarding Sangli), reserves the right to expel a student or terminate the candidature at any time if found otherwise.
      </p>
      <p>
      As a user, You agree to maintain (i) all content in the account including but not limited to academic content, assessments, email/SMS content, profile information and current billing and contact information, (ii) Email/mobile phone number list and Address Book maintenance including opt-out contact information and immediate removal of opt-outs and bounces from email/mobile phone number lists and the address book, (iii) Payment of all agreed course fees (iv) full responsibility for all activity associated with your use of this Website and services and (v) compliance with all other obligations set forth in these Terms and Conditions.
      </p>
      <p>
      You will provide current, complete, accurate information in the registration section of this Website. You will further update and keep that information current as needed. Email login must be a valid email address maintained by You. You are solely responsible for maintaining the confidentiality of your password and account information. You will immediately notify the Institute of any unauthorized account activity, or any unauthorized use of your email list(s) or any other breach of security You become aware of by emailing at: jainboardingsangli@gmail.com
      </p>
      <p>
      Excluding third party course materials,Institute (Dakshin Bharat Jain Sabha's SRDDD Jain Boarding Sangli) owns all copyrights, patents, trade secrets, trademarks and other intellectual property rights, title and interest in or pertaining to the services in the course catalogue and all other services developed by Institute for the purpose of these services on offer. No resale, transfer or re-export of the course(s) and/or content is authorized without the prior authorization of Institute (Dakshin Bharat Jain Sabha's SRDDD Jain Boarding Sangli).
      </p>
      <p>
      Institute (Dakshin Bharat Jain Sabha's SRDDD Jain Boarding Sangli) reserves the right to change course prices at any time. In the event of such change, confirmed registrations will be honored at the original price. Changes to Service Tax and other tax and duty components will be incorporated into the Course Fee as and when such changes occur and the student will become liable to pay the incremental fee.
      </p>
      <p>
      Course content for all the services on offer is provided for the purpose of education and guidance only. The course content is regularly reviewed and is subjected to change without notice. Institute (Dakshin Bharat Jain Sabha's SRDDD Jain Boarding Sangli) reserves the right to modify training content without notice.
      </p>
      <p>
      Institute (Dakshin Bharat Jain Sabha's SRDDD Jain Boarding Sangli) may terminate your access at any time for any reason. The provisions regarding a disclaimer of warranty, accuracy of information and indemnification shall survive such termination. Institute (Dakshin Bharat Jain Sabha's SRDDD Jain Boarding Sangli) may also monitor access to this Website.
      </p>
      <p>
      To the extent allowed by local law, in no event will Institute (Dakshin Bharat Jain Sabha's SRDDD Jain Boarding Sangli) or its affiliates, subcontractors or suppliers be liable for any incidental, indirect, special consequential damages.
      </p>
      <p>
      By registering on this Website, You give your consent to receive, from time to time, text messages from Institute (Dakshin Bharat Jain Sabha's SRDDD Jain Boarding Sangli) which may include offers from Us or our affiliates and partners.
      </p>
      <h5>ONLINE PAYMENT TERMS</h5>
      <p>
      The following section states the Terms and Conditions which will be applicable to the user using the Institute's on-line payment process for various services/services offered by Us. Please read this page carefully. If these Terms & Conditions are not acceptable to You, please do not use this process. By using Institute's online payment process, You are indicating your acceptance to be bound by these Terms & Conditions. Institute (Dakshin Bharat Jain Sabha's SRDDD Jain Boarding Sangli) reserves the right to revise these Terms & Conditions from time to time.
      </p>
      <p>
      User information entered during the online payment process is kept secure and confidential and will not be divulged to any third party, other than the payment gateway service provider.
      </p>
      <p>
      The Institute will not be held responsible for any damages the user may suffer as a result of using the payment process. The user agrees and shall defend, indemnify, save and hold the Institute harmless from any and all demands, liabilities, losses, costs and claims that may arise or result from incorrect use of the online payment process or any other causes attributable to the user.
      </p>
      <p>
      The Institute will not be responsible for any refunds arising out of any failures in online payments in case the amount gets deducted from user’s saving/current account and does not get credited in the Institute's account, before the transaction is complete. Users in this case will have to contact either the Bank or Us to clarify the same.
      </p>
      <p>
         In no event will the user be eligible for the refund of the payment made online.
      </p>
      <p>
      All the refunds of credit & debit card transactions, which are subject to the Institute's policy, will be made by cheques only; no refund will be processed either by online transfer or in cash.
      </p>
      <p>
      Receipts of online payments along with confirmation of admissions by Institute (Dakshin Bharat Jain Sabha's SRDDD Jain Boarding Sangli) should be maintained by the user safely, as this will be a pre-condition for processing any refund.
      </p>
      <p>
      Successful completion of the online application process and payment alone will not make the user eligible for the admission. Since the online application system does not perform any check on the qualification of user or other essential conditions during the application process, hence, successful completion of the online application process may not make the user eligible for the course admission. The course admission will be totally at the discretion of Institute (Dakshin Bharat Jain Sabha's SRDDD Jain Boarding Sangli) and the same will be confirmed upon scrutiny of the admission details provided by the user and Institute (Dakshin Bharat Jain Sabha's SRDDD Jain Boarding Sangli) is not liable to give reason for any rejection.
      </p>
      <p>
      It is the user’s responsibility to ensure payment of full fees as applicable. Such payment of fees by or on behalf of the user does not in itself constitute acceptance of user’s candidature for the course. When the user successfully completes the online payment transaction using credit/debit cards option, funds will be deducted from the credit/debit card mentioned by the user and the payments will be credited to the Institute . Any payment received through this website will be treated as having been made by the user, even though the same may have been made by some other person/party, in such event, the Institute shall not be liable to entertain any correspondence from any person other than the user’s parents/guardian.
      </p>
      <p>
      In case the user registers successfully but fails to pay the required fee on or before the stipulated date, such application will stand cancelled without any further notification.
      </p>
      <p>
      The Institute shall not be liable to protect data from third parties if there is any failure on the part of the user or any person acting on behalf of the user, during the online payment process or in respect of any omission to provide accurate information in the course of the online payment process.
      </p>
      <p>
      The Institute will be entitled to use the information given during the online payment process for the purpose of registration of the user and also for statistical purposes or for its internal use.
      </p>
      </div>
      <div>
      <Container fluid className='footer1-container p-md-2 m-0 px-md-5' style={{boxSizing:"border-box"}}>
      <Row className='py-1' >
        <Col sm={12} md={5} className='text-white text-center  py-3 border'>
        <div >
          <img src={Logo}  alt="" width={100} height={100}/>
        </div> 
        <div className='text-center' style={{color: "rgb(134, 28, 18)"}}>
            <h6 className='fw-bolder'>दक्षिण भारत जैन सभेचे</h6>
            <h5 className='fw-bolder'>शेठ रा. ध. दावडा दिगंबर जैन बोर्डिंग, सांगली </h5>
            <h6 className='fw-bolder'>जैन वधू - वर नोंदणी 2024</h6>
        </div>
        </Col>
        <Col sm={12} md={3} className='text-center py-3 border' style={{lineHeight:"40px"}}>
            <div className='text-start ps-5'>
            <h4  style={{color:"rgb(134, 28, 18)", paddingBottom:"15px"}}><b>Information</b></h4>
            </div>
            <div className='text-start ps-5'>
            <Link to="/PrivacyPolicy"
                  style={{textDecoration:"none", color:"white",color: "rgb(134, 28, 18)" }} >
                   <i style={{paddingRight:"10px"}} class="fa-solid fa-greater-than"></i>   
                    Privacy Policy 
            </Link><br/>
            <Link to="/terms&Condition"
                  style={{textDecoration:"none", color:"white",color: "rgb(134, 28, 18)"}} >
                  <i style={{paddingRight:"10px"}} class="fa-solid fa-greater-than"></i>  
                  Terms & Condition
            </Link><br/>
            <Link to="/ReturnAndRefundPrivacy"
                  style={{textDecoration:"none", color:"white",color: "rgb(134, 28, 18)"}} >
                  <i style={{paddingRight:"10px"}} class="fa-solid fa-greater-than"></i>  
                  Return & Refund Policy
            </Link><br/>
            <Link to="/Cancelation"
                  style={{textDecoration:"none", color:"white",color: "rgb(134, 28, 18)"}} >
                  <i style={{paddingRight:"10px"}} class="fa-solid fa-greater-than"></i>  
                  Cancelation Policy
            </Link><br/>
            {/* <Link to="/Registration1"
                  style={{textDecoration:"none", color:"white",color: "rgb(134, 28, 18)"}} >
                  <i style={{paddingRight:"10px"}} class="fa-solid fa-greater-than"></i>  
                  Registration
            </Link><br/> */}
            <Link to="/loginBarCode"
                  style={{textDecoration:"none", color:"white",color: "rgb(134, 28, 18)"}} >
                  <i style={{paddingRight:"10px"}} class="fa-solid fa-greater-than"></i>  
                  Admin Login(Entry Pass)
            </Link><br/>
            <Link to="/admin"
                  style={{textDecoration:"none", color:"white",color: "rgb(134, 28, 18)"}} >
                  <i style={{paddingRight:"10px"}} class="fa-solid fa-greater-than"></i>  
                  Admin Login
            </Link><br/>
            </div>
        </Col>
        <Col sm={12} md={4} className='text-center py-3' style={{lineHeight:"40px"}}>
            <div className='text-start ps-5'>
            <h4  style={{color: "rgb(134, 28, 18)", paddingBottom:"15px"}}><b>Contact</b></h4>
            </div>
            <div className='text-start ps-5'>
            <a style={{textDecoration: "none",  color: "rgb(134, 28, 18)" }}> 
              <i style={{ paddingRight: "10px" }} class="fa-solid fa-address-book"></i>
             <span>Dakshin Bharat Jain Sabha's Sheth RDD Jain Boarding, Sangli</span>
              </a>
              <br/>
            <a style={{textDecoration: "none",  color: "rgb(134, 28, 18)" }}> 
              <i style={{ paddingRight: "10px" }} class="fa-solid fa-location-dot"></i>
              <b>Address</b> : <span>37, Mahavirnagar, Opp Sangli Highschool, Sangli-416416</span>
              </a>
              <br/>
            <a href="mailto:jainboardingsangli@gmail.com" style={{textDecoration: "none",  color: "rgb(134, 28, 18)" }}>
              <i style={{ paddingRight: "10px" }} className="fa-solid fa-envelope"></i>   
              <b>Email</b> : <span>jainboardingsangli@gmail.com</span>
              </a>
              <br/>
              <a href="tel:02332623206" style={{ textDecoration: "none",  color: "rgb(134, 28, 18)" }}>
              <i style={{ paddingRight: "10px" }} className="fa-solid fa-phone"></i> 
             <b>Contact</b> : 0233 2623206
            </a><br/>
            </div>
        </Col>
      </Row>
      <Row>
        <Col>
        <div style={{display:"flex", justifyContent:"center", color:"white", padding:"20px 0px 20px 0px", borderTop:"1px solid rgb(134, 28, 18) ",color: "rgb(134, 28, 18)"}}>
            &copy; 2024 Jain Boarding | Managed By : TechnoAarv Solution
        </div>
        </Col>
      </Row>
    </Container>
      </div>
    </div>
   )
} 

export default TermsAndCondition

