import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../UserComponents/UserComponent.css';
import { useNavigate } from 'react-router-dom';
import { green } from '@mui/material/colors';
import afterlogo from '../assets/login-after.png';
import Card from 'react-bootstrap/Card';
import { RegistrationSelect, RegistrationStop } from '../AllApi/UserApi/UserApi';
import axios from 'axios';
import UserMasterHeader from '../UserComponents/UserCommonComponent/UserMasterHeader';
import UserMasterFooter from '../UserComponents/UserCommonComponent/UserMasterFooter';
import { toast } from 'react-toastify';
import Logo from '../assets/Mainlogo.jpg';
import Logo2 from '../assets/logo2.png';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import blink from '../assets/photo_rejected.gif'
import { memberImgPath } from '../AllApi/AdminApi/AdminApi';

const MainPage = () => {

//---------------------------------Disabled back button-------------------------------
useEffect(() => {
  window.history.pushState(null, null, window.location.href)

  const handleBackButton = (event) => {
    event.preventDefault()
    window.history.forward()
  }

  window.addEventListener('popstate', handleBackButton)

  return () => {
    window.removeEventListener('popstate', handleBackButton)
  }
}, [])


  const userdata = JSON.parse(sessionStorage.getItem("userdata"));
  const member_id = userdata.id


  const [Userdata, setUserdata] = useState()
  const [lockDateOk,setlockDateOk]=useState(false)   




  const getUserData = () => {


    axios.get(`${RegistrationSelect}${member_id}`)
      .then((res) => {
        if (res.data.json !== null) {
          setUserdata(res.data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    getUserData()
  }, [])

  // -----------handle checkbox-----------
  const navigate = useNavigate()
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleNextClick = () => {

    // Here you can add your logic to navigate to the next page
    // if the checkbox is checked.
    if (isChecked) {


      if (Userdata && Array.isArray(Userdata)) {


        Userdata.forEach((value) => {
          if ((value.marital_status == "2" && value.gender === 2) || value.disability === "2") {
            if ((value.marital_status == "2" && value.gender === 2)) {
              // alert("Your Recipst has been sent to Your Mail")
              toast.success('Your Recipst has been sent to Your Mail!', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
            }
            else if (value.disability === "2") {
              // alert("Your Recipit Will be Send On Mail After Admin Approval")
              toast.success('Your Recipit Will be Send On Mail After Admin Approval !', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
            }

          }
          else {
            navigate('/payment2');
          }
        }
        );

      }


      // sessionStorage.setItem("status", 5)




    }
    // else {
    //   alert("Please accept the terms and conditions.");
    // }
  }

  // =====================Header=====================
  

  const logoutFun = () => {
    sessionStorage.removeItem("userdata");
    sessionStorage.clear()
    navigate("/");    
  }

  useEffect(() => {
    let token=sessionStorage.getItem("token");
    let userdata=sessionStorage.getItem("userdata");

    if(token==null || userdata==null){
      sessionStorage.clear()
      navigate("/")
    }
  }, [])

  const regFun = () => {

    // if(lockDateOk===true){
    //   alert("Registration For Supplement Book Will Be Started On 23-Nov-2023 , पुरवणी पुस्तिका नोंदणी 23 नोव्हेंबर 23 ला सुरु होईल")
    //         }
    //         else{

    const state = 1
    navigate('/user/regForm', { state: { state } })

    // <Registartion1 />
  }
// }

  const regPrint = () => {
    navigate('/payment')
  }  

  //--------------------------CSS---------------------------------
  const style1 = { lineHeight: "15px", fontSize: "14px" }
  const style3 = { lineHeight: "15px", fontSize: "14px" }
  const style = { fontSize: "14px", width: "100%", padding: "5px", backgroundColor: "#f796ec", fontWeight: "bold", borderRadius: "10px 10px 0px 0px", border: "1px solid white" }
  const style2 = { border: "1px solid #f796ec", padding: "8px 5px", alignItems: "center", height: "88px", margin: "0px 1px 0px 1px", textAlign: "start" }
  const style4 = {display:"flex",justifyContent:"center", lineHeight: "15px", fontSize: "13px" }
  const style5 = {display:"flex",justifyContent:"center", lineHeight: "15px", fontSize: "13px",flexDirection:"column" }

  return (

    <Container fluid className='m-0 p-0'>
      <div >
        <Container fluid className='p-0 m-0' style={{boxSizing:"border-box"}}>
     
     <UserMasterHeader/>

      <Navbar expand="lg" style={{backgroundColor: "#660505"}}>
      <Container fluid>
        <Navbar.Brand href="#"></Navbar.Brand>
        {/* <Navbar.Toggle aria-controls="navbarScroll" style={{backgroundColor:"rgb(233, 224, 227)"}} />
        <Navbar.Collapse id="navbarScroll"> */}
          <Nav
            className="ms-auto my-2 my-lg-0"
            style={{ maxHeight: '100px' }}
            navbarScroll
          >
            {/* <Nav.Link href="/mainPage" className=' mx-2 text-white'>Home</Nav.Link> */}
            <Nav.Link href="/#/paymentRecipt" className=' mx-2 text-white fs-5 '><b>View Receipt</b></Nav.Link>
          </Nav>
          
        {/* </Navbar.Collapse> */}
      </Container>
    </Navbar>
       

    </Container> 
    </div>

      <Container className='container-fourth rounded p-3 my-3 p-md-5 '>
        <div className='rounded shadow  px-3 pb-2 py-sm-5' style={{ backgroundColor: "white" }} >
          <Row className='mb-2'>
            <Col>
              <div className='mt-5 mt-md-0' style={{ display: "flex", justifyContent: "center", textAlign: "center", color: "red" }}>
                <h5><b>The following text is to be printed in the main booklet as it is. Please make sure before proceeding... <br /> (खालील मजकूर आहे तसा मुख्य पुस्तिकेमध्ये छापला जाणार आहे. कृपया पुढे जाण्याअगोदर खात्री करावी...)</b></h5>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img src={afterlogo} />
              </div>
            </Col>
          </Row>



          {
            Userdata && Array.isArray(Userdata) && Userdata.map((value, index) => {

              return (
                <>

                <div className='row'>
                     <div className='col-12 col-lg-1'></div>

                     <div className='col-12 col-lg-10 p-0' style={{ backgroundColor: "white", overflow: "auto" }} >

                        <div className='p-2' style={{ backgroundColor: "#f796ec", display: "flex", alignItems: "center", width: "1000px" }}>
                          <div className='col-3'></div>
                          <div className='col-9 me-3' style={{ alignItems: "center", display: "flex" }}>
                            <div className='col-9' style={{ margin: "0px 0px 0px -70px", alignItems: "end", }}><p style={{ fontWeight: "bold", fontSize: "20px", }}>{value.en_fname}/{' '}{value.en_fatherfname}{' '}{value.en_fathermname}{' '} {value.en_fatherlname} </p></div>

                            <div className='col-3 me-5' style={{ textAlign: "center", padding: "6px", border: "2px solid white", borderRadius: "50px", backgroundColor: "white", margin: "0px 0px 0px 50px" }}><label style={{ fontWeight: "bold", }}>{value.gender === 1 ? 'APP2024/' : 'APP2024/'}{value.member_id}</label></div>
                          </div>
                        </div>

                        <div className=' d-flex p-0' style={{ border: "2px solid #c42fb2", borderTopColor: "#f796ec", width: "1000px", backgroundColor: "white" }}>
                          <div className='col-2 p-2' style={{ justifyContent: "center", display: "flex" }}>
                            <img src={`${memberImgPath}/${value.image}`} alt={`${value.en_fname}'s Photo`} style={{ width: "100%", height: "200px", border: "1px solid grey" }} />
                          </div>

                          <div className='col-10 px-4 py-2'>

                            <div className='row p-0'>
                              <div className='col-8 p-0 pt-2' style={{ lineHeight: "10px" }}>
                                <p style={{ fontSize: "15px", lineHeight: "18px" }}><b>Add : </b>{' '} {value.en_address}{' '}&nbsp;<b>Village/City : </b>{value.en_village}{' '}&nbsp;<b>Tal : </b>{value.talukaenname}{' '}&nbsp;<b>Dist : </b>{value.districtenname} {' '}&nbsp;<b>State : </b>{value.stateenname}</p>
                                <p style={{ fontSize: "15px", lineHeight: "10px" }}><b>Mob : </b>{' '}{value.mobileno}/{value.mobileno2}</p>
                                <p style={{ fontSize: "15px", lineHeight: "10px" }}><b>Occupation : </b>{' '}{value.occupation},&nbsp;{value.occupation_detail}</p>
                              </div>

                              <div className='col-4 p-0' style={{ alignItems: "end", display: "flex", flexDirection: "column" }}>
                                
                                    <div className='d-flex mb-1 w-100' style={{ fontSize: "14px", lineHeight: "20px", }}>

                                      <div style={{ padding: "10px 15px", color: "white", width: "65px", fontWeight: "bold", backgroundColor: "#f796ec", justifyContent: "center", display: "flex", borderRadius: "10px 0px 0px 10px" }}><b>Brother</b></div>

                                      <div style={{ width: "200px", display: "flex", padding: "10px 10px", border: "1px solid #f796ec"}} >
                              
                                      
                                          <span style={{ fontSize: "13px" }}><b>Married- </b>{value.b_married > 0 ? value.b_married: '-' }</span>
                                        
                                        
                                          <span style={{ fontSize: "13px" }} className='ms-4'><b> Unmarried- </b>{value.b_unmarried > 0 ? value.b_unmarried: ' -'}</span>
                                      

                                
                                      </div>
                                      
                                    </div>
                                  

                                  <div className='d-flex mt-1 w-100' style={{ fontSize: "14px", lineHeight: "20px", }}>

                                      <div style={{ padding: "10px 10px", color: "white", width: "65px", fontWeight: "bold", backgroundColor: "#f796ec", justifyContent: "center", display: "flex", borderRadius: "10px 0px 0px 10px" }}><b>Sister</b></div>

                                      <div style={{ width: "200px", display: "flex", padding: "10px 10px", border: "1px solid #f796ec"}} >
                                          <span style={{ fontSize: "13px" }}><b>Married- </b>{value.s_married > 0 ? value.s_married: '-' }</span>
                                          <span style={{ fontSize: "13px" }}  className='ms-4'><b> Unmarried- </b>{value.s_unmarried > 0 ? value.s_unmarried: '-'}</span> 
                                      </div>

                                  </div>
                                  
                              </div>
                            </div>


                            <div className="row p-0 d-flex mt-2">
                              <div className='col-2 p-0' style={{ textAlign: "center", display: "flex", flexDirection: "column", }}>
                                <div style={style}><b>DOB & Time</b></div>
                                <div style={style2}> <p style={style4}>{value.dob}</p> <p style={style4}>{value.birth_time}</p></div>
                              </div>

                              <div className='col-2 p-0' style={{ textAlign: "center", display: "flex", flexDirection: "column", }}>
                                <div style={style}><b>Height & Comp.</b></div>
                                <div style={style2}> <p style={style4}>{value.height}</p> <p style={style4}>{value.complextion}</p></div>
                              </div>

                              <div className='col-2 p-0' style={{ textAlign: "center", display: "flex", flexDirection: "column", }}>
                                <div style={style}><b>Education</b></div>
                                <div style={style2}> <h6 style={style5} className='text-center'>{value.subeducation_name},&nbsp;<p className='text-center pt-1' style={style5}>{value.other_education}</p></h6>
                                </div>
                              </div>

                              <div className='col-3 p-0' style={{ textAlign: "center", display: "flex", flexDirection: "column", }}>
                                <div style={style}><b>Annual Income & Assets</b></div>
                                <div style={style2}> <h6 style={style4}>{value.amount}</h6>
                                  { value.farm_g > 0 || value.farm_ac > 0 ? (
                                    <>
                                      <h6 style={style4}><b>Farm -&nbsp;</b>
                                      {value.farm_ac > 0 ? (
                                    <span> {value.farm_ac}&nbsp; acre{' '}</span>
                                      ) : null}
                                      {value.farm_g > 0 ? (
                                        <span style={style3}>{' '}&nbsp; {value.farm_g} &nbsp; gunthe{' '}</span>
                                        ) : null
                                      }
                                      </h6>
                                    </>
                                  ) : null}

                                  <span className='text-center' style={style4}>{value.other_property}</span></div>
                              </div>


                              <div className='col-2 p-0' style={{ textAlign: "center", display: "flex", flexDirection: "column",width:"93px"  }}>
                                <div style={{ fontSize: "14px", width: "100%", padding: "5px 0px", backgroundColor: "#f796ec", fontWeight: "bold", borderRadius: "10px 10px 0px 0px", border: "1px solid white" }}><b>Sub Caste </b></div>
                                <div style={style2}> <p style={{lineHeight:"18px",fontSize:"13px"}}>{value.subcastename}</p></div>
                              </div>

                              <div className='p-0' style={{ textAlign: "center", display: "flex", flexDirection: "column",width:"108px" }}>
                                <div style={style}><b>Expectations</b></div>
                                <div style={style2}> <p style={{lineHeight: "20px", fontSize: "13px",display:"flex",flexDirection:"column",textAlign:"center",margin:"0px 12px",flexWrap:"wrap"}}>{' '}{value.exp_housewife === 1 ? 'Housewife ' : null}{value.exp_compatible === 1 ? 'Compatible ' : null}{value.exp_farmer === 1 ? 'Farmer ' : null}{value.exp_serviceman === 1 ? 'Service ' : null}{value.exp_businessman === 1 ? 'Business ' : null}</p></div>
                              </div>

                            </div>
                          </div>
                        </div>

                    </div>


                     <div className='col-12 col-lg-1'></div>
                  </div>

                  
                  {
                    value.image_status === 2 ?
                    <div className='text-center'> <img style={{height:"80px"}} src={blink} alt="" /> </div>
                  : null}
                  

                  <div className='row p-0'>
                    <div className="col-12 col-md-3"></div>
                    <div className='col-12 col-md-6' style={{ justifyContent: "center", display: "flex" }}>
                      <button type="button" className='rounded m-2' style={{ padding: "4px 15px", backgroundColor: "#ff7b00", border: "0px", color: "white", fontSize: "17px" }} onClick={regFun} > <b>Edit Profile</b></button>

                      {/* <button  className='rounded m-2' style={{ padding: "4px 22px", backgroundColor: "green", border: "0px", color: "white",fontSize: "17px" }} onClick={regPrint} > <b>View Profile For Print</b></button> */}
                    </div>
                    <div className="col-12 col-md-3"></div>
                  </div>
                </>
              )
            })}
        </div>
      </Container>
      <div><UserMasterFooter /></div>
    </Container>

  )
}

export default MainPage




