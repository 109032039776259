import React, { useState, useEffect } from 'react'
import './Members.css'
import AdminMasterHeader from '../../CommonComponenets/AdminMasterHeader'
import NavDropdown from 'react-bootstrap/NavDropdown'
import { Container, Row, Col, Form, Button, FormGroup } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import reject from '../../assets/reject.jfif'
import logo from '../../assets/logo2.png'
import logo2 from '../../assets/logonew.jpg'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { MDBDataTable } from 'mdbreact';
import { adminImgPath, adminMemberList, memberImgPath } from '../../AllApi/AdminApi/AdminApi'
import AdminMasterFooter from '../../CommonComponenets/AdminMasterFooter'

const MemberProfiles = ({}) => {
  const navigate = useNavigate()
  const [record, setRecord] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const [filteredMembers, setFilteredMembers] = useState(record)
  const [showLargePhoto, setShowLargePhoto] = useState(false)
  const [selectedPhoto, setSelectedPhoto] = useState(null)
  const [selectedMember, setSelectedMember] = useState(null)
  const [showOperationButtons, setShowOperationButtons] = useState(false)
  const [isViewingProfile, setIsViewingProfile] = useState(false)
  const [genderFilter, setGenderFilter] = useState('All')
  const [occupationFilter, setOccupationFilter] = useState('All')
  const [Image, setImage] = useState()



  //----------------------------------API code------------------------------------------

  useEffect(() => {

    const tokenValue = sessionStorage.getItem('token')

    const tokenConfig = {
      headers: {
        token: tokenValue,
      },
    }

    const fetchMemberData = async () => {
      try {
        const response = await axios.get(
          adminMemberList,
          tokenConfig,
        )
        setRecord(response.data)
        setFilteredMembers(response.data)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }
    fetchMemberData()
  }, [])

  //---------------------------Search code---------------------------------------

  const handleSearchChange1 = (event) => {
    const query = event.target.value
    setSearchQuery(query)

    let filtered = record.filter(
      (member) =>
        member.en_fname.toLowerCase().includes(query.toLowerCase()) ||
        member.emailid.toLowerCase().includes(query.toLowerCase()),
    )
    if (genderFilter !== 'All') {
      filtered = filtered.filter(
        (member) => member.gender === parseInt(genderFilter),
      )
    }
    if (occupationFilter !== 'All') {
      filtered = filtered.filter(
        (member) => member.occupation === occupationFilter,
      )
    }
    setFilteredMembers(filtered)
  }

  //------------------------Filter code--------------------------------------------
  const applyFilters = (query, gender, occupation) => {
    const filtered = record.filter(
      (member) =>
        member.en_fname.toLowerCase().includes(query.toLowerCase()) &&
        //(gender === 'All' || member.gender === 1)  &&
        (gender === 'All' ||
          (gender === 'Male' && member.gender === 1) ||
          (gender === 'Female' && member.gender === 2)) &&
        (occupation === 'All' || member.occupation === occupation),
    )
    setFilteredMembers(filtered)
  }

  const handleFilterChange = (gender, occupation) => {
    setGenderFilter(gender)
    setOccupationFilter(occupation)
    applyFilters(searchQuery, gender, occupation)
  }

  //--------------------------Image click code-----------------------------------
  const handleImageClick = (member) => {
    console.log("=====",member);
    
    setSelectedMember(member)
    setIsViewingProfile(true)
    setShowLargePhoto(true)
  }

  const handleCloseLargePhoto = () => {
    setShowOperationButtons(false)
    setShowLargePhoto(false)
    setSelectedPhoto(null)
    setSelectedMember(null)
  }

  //-------------------------------------------------------------
  const senddataedit = (member) => {
    const member_id = member.member_id
     navigate('/admin/members/update', { state:{member_id}})
  }

  //CSS for Information-------------------------------------------------------------------
  const info = {
    display: 'flex',
    alignItems: 'center',
    paddingTop: '5px',
    backgroundColor: '#660505',
    color: 'white',
  }
  const footerStyle = {
    backgroundColor: '#660505',
    boxShadow: '0 2px 4px -2px rgba(0,0,0,.5)',
    padding: '8px',
    textAlign: 'center',
    color: '#fff',
    fontSize: '12px',
    bottom: 0,
    width: '100%',
    letterSpacing: '1px',
  }
//==========overlay code=================================
  const overlay={
  position:" absolute",
  bottom:"200px", 
  background: "rgb(0, 0, 0)",
  background: "rgba(0, 0, 0, 0.5)",
  color:" #f1f1f1", 
  width: "200px",
  transition: ".5s ease",
  opacity:"1",
  color: "red",
  fontSize:" 20px",
  padding: "10px",
  textAlign: "center",
  fontWeight:"bolder",
  }

  //=====================================================================
  const columns = [
    {
      label: 'Sr.no',
      field: 'srNo',
      sort: 'asc',
    },
    {
      label: 'Member Id',
      field: 'member_id',
      sort: 'asc',
    },
    {
      label: 'Reg.Id',
      field: 'reg_id',
      sort: 'asc',
    },
    {
      label: 'Name',
      field: 'name',
      sort: 'asc',
    },
    {
      label: 'Gender',
      field: 'gender',
      sort: 'asc',
    },
    {
      label: 'Email Id',
      field: 'emailid',
      sort: 'asc',
    },
    {
      label: 'Mobile No',
      field: 'mobileno',
      sort: 'asc',
    },
    {
      label: 'View',
      field: 'action',
    },
    {
      label: 'Edit',
      field: 'action1',
    },
  ];
  
  
  const customRows = filteredMembers.map((member, index) => {
    const { gender, en_fname,en_mname,en_lname, mobileno, emailid,member_id,reg_id } = member;
    const genderLabel = gender === 1 ? 'Male' : 'Female';

    

    return {
      srNo: index + 1,
      reg_id,
      member_id,
      name: `${en_fname} ${en_mname} ${en_lname}`,
      gender:genderLabel,
      mobileno,
      emailid,
      action: (
        <button className="viewButtons1" type='submit' onClick={() => handleImageClick(member)}>View Profile</button>        
      ),
      action1: (
        <button className="viewButtons2" type='submit' onClick={() => senddataedit(member)}>Edit Profile</button>        
      ),
    };
  });

  //==================================================================================
 


  return (
    <>
      <div className="row m-0">
        <div className="p-0">
          {' '}
          <AdminMasterHeader />{' '}
        </div>

        <div className="filter-buttons row">
          <div className="col-12 col-md-4">
            <h2 style={{ fontWeight: '550' }}>Member Profiles</h2>
          </div>

          <div className="filterButtonsBack col-12 col-md-5">
            <Link
              className="filterButtons"
              onClick={() => handleFilterChange('All', 'All')}
            >
              All Members
            </Link>

            <NavDropdown
              className="filterButtons mx-2 "
              id="basic-nav-dropdown"
              title={<span className=" my-auto text-center">Male Members</span>}
            >
              <NavDropdown.Item
                onClick={() => handleFilterChange('Male', 'All')}
              >
                All Male
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => handleFilterChange('Male', 'Business')}
              >
                Business
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => handleFilterChange('Male', 'Service')}
              >
                Service
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => handleFilterChange('Male', 'Farmer')}
              >
                Farmer
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => handleFilterChange('Male', 'Student')}
              >
                Student
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => handleFilterChange('Male', 'Other')}
              >
                Other
              </NavDropdown.Item>
            </NavDropdown>

            <NavDropdown
              className="filterButtons mx-2"
              id="basic-nav-dropdown"
              title={<span className="my-auto">Female Members</span>}
            >
              <NavDropdown.Item
                onClick={() => handleFilterChange('Female', 'All')}
              >
                All Female
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => handleFilterChange('Female', 'Business')}
              >
                Business
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => handleFilterChange('Female', 'Service')}
              >
                Service
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => handleFilterChange('Female', 'Farmer')}
              >
                Farmer
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => handleFilterChange('Female', 'Student')}
              >
                Student
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => handleFilterChange('Female', 'Other')}
              >
                Other
              </NavDropdown.Item>
            </NavDropdown>
          </div>

          <div className="col-12 col-md-3">
            <input
              type="text"
              placeholder="Search by name or email "
              value={searchQuery}
              onChange={handleSearchChange1}
            />
          </div>
        </div>

        {/* ====================================================== */}

        <Row className='row p-0 m-0' style={{justifyContent:"center",display:"flex",textAlign:"center"}}>
          <div className="col-12 col-sm-1"></div>
          <div className="col-12 col-sm-10">
              <MDBDataTable
                className='mb-5 custom-datatable'
                striped
                bordered
                hover
                small
                paging={!showLargePhoto}
                data={{ columns, rows: customRows }}
                style={{ textAlign: "center", fontSize: "14px" ,}}/>
          </div>
          <div className="col-12 col-sm-1"></div>
          </Row>

        {/* ====================================================== */}

        {showLargePhoto && (
          <div className="large-photo-modal">
            <div className="modal-content">
              <Row className="header m-0" style={{ borderBottom: '3px solid' }}>
                <Col md={3}  sm={12}
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <div>
                    <img
                      src={logo}
                      style={{ mixBlendMode: 'darken' }}
                      alt=""
                      width={160}
                      height={130}
                    />
                  </div>
                </Col>

                <Col  md={6}  sm={12}
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <div className="text-center">
                    <h5 className="fw-bolder">दक्षिण भारत जैन सभेचे</h5>
                    <h3 className="fw-bolder">
                      शेठ रा. ध. दावडा दिगंबर जैन बोर्डिंग, सांगली{' '}
                    </h3>
                    <h5 className="fw-bolder">जैन वधू - वर नोंदणी 2024</h5>
                  </div>
                </Col>

                <Col md={3}  sm={12}
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <div>
                    <img
                      src={logo2}
                      style={{ mixBlendMode: 'darken' }}
                      alt=""
                      width={160}
                      height={130}
                    />
                  </div>
                </Col>

              </Row>

              <span
                title="Close"
                className="close1 ms-auto fs-1 me-3"
                onClick={handleCloseLargePhoto}
              >
                &times;
              </span>
              
              {isViewingProfile && (
                <div className="row profile-details m-0">
                  <div
                    className="col-12 col-md-3"
                    style={{
                      justifyContent: 'start',
                      display: 'flex',
                      flexDirection: 'column',
                      height: '350px',margin:"19px 0px 0px 0px",
                    }}
                  >
                
                  <img
                    src={`${memberImgPath}/${selectedMember.image}`}
                    alt={`${selectedMember.en_fname}'s Photo`} style={{height:"250px"}}
                    className="small-photo mb-2 border"
                  />
                
                  </div>

                  <div className="col-12 col-md-9 pt-3">
                    {/* Basic Information */}
                    <div
                      className="row m-0 border"
                      style={{ backgroundColor: '#e9e0e3' }}
                    >
                      <div className="col-12 border-bottom" style={info}>
                        <h6>Basic Information/मुलभूत माहिती</h6>
                      </div>
                      <div
                        className="col-12 d-flex"
                        style={{ flexWrap: 'wrap' }}
                      >
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>First Name (पहिले नाव)</p>
                          </div>
                          <div className="col-6 col-md-8">:{" "}{selectedMember.en_fname}</div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>Middle Name(मधले नाव)</p>
                          </div>
                          <div className="col-6 col-md-8">
                            :{' '}
                            {selectedMember.en_mname}
                          </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>Last Name (आडनाव)</p>
                          </div>
                          <div className="col-6 col-md-8">
                            :{' '}
                            {selectedMember.en_lname}
                          </div>
                        </div>
                      </div>

                      {/* <div
                        className="col-12 d-flex"
                        style={{ flexWrap: 'wrap' }}
                      >
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>पहिले नाव (मराठी)</p>
                          </div>
                          <div className="col-6 col-md-8">
                            :{' '}
                            {selectedMember.mr_fname}
                          </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>मधले नाव (मराठी)</p>
                          </div>
                          <div className="col-6 col-md-8">:{" "}{selectedMember.mr_mname}</div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>आडनाव (मराठी)</p>
                          </div>
                          <div className="col-6 col-md-8">
                            :{' '}
                            {selectedMember.mr_lname}
                          </div>
                        </div>
                      </div> */}

                      <div
                        className=" col-12 d-flex p-2"
                        style={{ flexWrap: 'wrap' }}
                      >
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>Gender/लिंग</p>
                          </div>
                          <div className="col-6 col-md-8">
                          :{" "} {selectedMember.gender === 1 ? 'Male' : 'Female'}
                          </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>Marital Status/वैवाहिक स्थिती</p>
                          </div>
                          <div className="col-6 col-md-8">
                          :{" "}{selectedMember.marital_status}
                          </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>Complextion/रंग</p>
                          </div>
                          <div className="col-6 col-md-8">
                          :{" "}{selectedMember.complextion}
                          </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>Disability/दिव्यांग</p>
                          </div>
                          <div className="ccol-6 col-md-8">
                            :{' '}{selectedMember.disability === "1" ? 'No' : 'Yes'}
                          </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>Date of birth/जन्मतारीख</p>
                          </div>
                          <div className="col-6 col-md-8">:{" "}{selectedMember.dob} </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>Birth time/जन्मवेळ</p>
                          </div>
                          <div className="col-6 col-md-8">
                            :{' '}
                            {selectedMember.birth_time}{' '}
                          </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>Height/उंची</p>
                          </div>
                          <div className="col-6 col-md-8"> :{" "}{selectedMember.height} </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>Subcast/पोटजात</p>
                          </div>
                          <div className="col-6 col-md-8">
                            :{' '}
                            {selectedMember.subcastename}
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Education Information */}
                    <div
                      className="row m-0 border mt-3"
                      style={{ backgroundColor: '#e9e0e3' }}
                    >
                      <div className="col-12 border-bottom" style={info}>
                        <h6>Education & Occupation Information/शिक्षण आणि व्यवसाय माहिती</h6>
                      </div>
                      <div
                        className=" col-12 d-flex p-2"
                        style={{ flexWrap: 'wrap' }}
                      >
                        <div className="col-12 col-md-6 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>Education Category/शिक्षण श्रेणी</p>
                          </div>
                          <div className="col-6 col-md-8">
                            :{' '}
                            {selectedMember.education_name}
                          </div>
                        </div>
                        <div className="col-12 col-md-6  d-flex">
                          <div className="col-6 col-md-4 para">
                            <p style={{ lineHeight: '18px' }}>
                              Education/शिक्षण
                            </p>
                          </div>
                          <div className="col-6 col-md-8">
                          :{" "}{selectedMember.subeducation_name}
                          </div>
                        </div>

                        <div className="col-12 col-md-6  d-flex">
                          <div className="col-6 col-md-4 para">
                            <p style={{ lineHeight: '18px' }}>
                              Other Qualification(इतर पात्रता)
                            </p>
                          </div>
                          <div className="col-6 col-md-8">
                            :{' '}
                            {selectedMember.other_education}
                          </div>
                        </div>

                        <div className="col-12 col-md-6  d-flex">
                          <div className="col-6 col-md-4 para">
                            <p style={{ lineHeight: '18px' }}>
                              Occupation Type/ व्यवसायाचा प्रकार
                            </p>
                          </div>
                          <div className="col-6 col-md-8">
                            :{' '}
                            {selectedMember.occupation}
                          </div>
                        </div>
                        <div className="col-12 col-md-6 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>Annual Income/वार्षिक उत्पन्न</p>
                          </div>
                          <div className="col-6 col-md-8">:{" "} {selectedMember.amount} </div>
                        </div>
                        <div className="col-12 col-md-6 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>Occupation & Addrress/व्यवसाय आणि पत्ता</p>
                          </div>
                          <div className="col-6 col-md-8">:{" "} {selectedMember.occupation_detail} </div>
                        </div>
                      </div>

                      {/* <div className="row d-flex">
                        <div className="col-6 col-md-4 para">
                          <p style={{ lineHeight: '18px' }}>
                            Occupation & Addrress/व्यवसाय आणि पत्ता
                          </p>
                        </div>
                        <div className="col-6 col-md-8">
                          :{' '}
                          {selectedMember.occupation_detail}
                        </div>
                      </div> */}

                    </div>

                    {/* Family Information */}
                    <div
                      className="row m-0 border mt-3"
                      style={{ backgroundColor: '#e9e0e3' }}
                    >
                      <div className="col-12 border-bottom" style={info}>
                        <h6>Family Information/कौटुंबिक माहिती</h6>
                      </div>
                      <div
                        className=" col-12 p-2 d-flex"
                        style={{ flexWrap: 'wrap' }}
                      >
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>Father First Name(वडिलांचे नाव)</p>
                          </div>
                          <div className="col-6 col-md-8">
                          :{" "}{selectedMember.en_mname}
                          </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>Father Middle Name(वडिलांचे मधले नाव)</p>
                          </div>
                          <div className="col-6 col-md-8">
                          :{" "}{selectedMember.en_fathermname}
                          </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>Father Last Name(वडिलांचे आडनाव)</p>
                          </div>
                          <div className="col-6 col-md-8">
                            :{' '}
                            {selectedMember.en_lname}
                          </div>
                        </div>

                        {/* <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>वडिलांचे नाव(मराठी)</p>
                          </div>
                          <div className="col-6 col-md-8">
                            :{' '}
                            {selectedMember.mr_fatherfname}
                          </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>वडिलांचे मधले नाव(मराठी)</p>
                          </div>
                          <div className="col-6 col-md-8">
                            :{selectedMember.mr_fathermname}
                          </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>वडिलांचे आडनाव(मराठी)</p>
                          </div>
                          <div className="col-6 col-md-8">
                            :{' '}
                            {selectedMember.mr_fatherlname}
                          </div>
                        </div> */}

                        <div className="col-12 col-md-6 d-flex">
                          <div className="col-6 para">
                            <p>Farm in Acar/शेत जमीन एकर</p>
                          </div>
                          <div className="col-6">:{" "}{selectedMember.farm_ac}</div>
                        </div>
                        <div className="col-12 col-md-6 d-flex">
                          <div className="col-6 para">
                            <p>Farm in Guntha/शेत जमीन गुंठे</p>
                          </div>
                          <div className="col-6">:{" "}{selectedMember.farm_g}</div>
                        </div>
                        <div className="col-12 d-flex">
                          <div className="col-6 col-md-3 para">
                            <p>Other Property/इतर मालमत्ता</p>
                          </div>
                          <div className="col-6 col-md-9">
                          :{" "}{selectedMember.other_property}
                          </div>
                        </div>
                      </div>
                      <div
                        className=" col-12 d-flex p-2"
                        style={{ flexWrap: 'wrap' }}
                      >
                        <div className="col-12 col-md-6">
                          <div className="col-12 para">
                            <p>Brother/भाऊ:</p>
                          </div>
                            <div className='d-flex' style={{textAlign:"center"}}>
                              <div className="col-6">
                                Unmarried/अविवाहित : {selectedMember.b_unmarried}
                              </div>
                              <div className="col-6">
                                Married/विवाहित : {selectedMember.b_married}
                              </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="col-12 para">
                            <p>Sister/बहीण:</p>
                          </div>
                            <div  className='d-flex' style={{textAlign:"center"}}>
                              <div className="col-6">
                                Unmarried/अविवाहित : {selectedMember.s_unmarried}
                              </div>
                              <div className="col-6">
                                Married/विवाहित : {selectedMember.s_married}
                              </div>
                            </div>
                        </div>
                      </div>
                    </div>

                    {/* Contact Information */}
                    <div
                      className="row m-0 border mt-3"
                      style={{ backgroundColor: '#e9e0e3' }}
                    >
                      <div className="col-12 border-bottom" style={info}>
                        <h6>Contact Information/संपर्क माहिती</h6>
                      </div>
                      <div
                        className="col-12 p-2 d-flex"
                        style={{ flexWrap: 'wrap' }}
                      >
                        <div className="col-12 col-md-7 d-flex">
                          <div className="col-3  para">
                            <p>Address(पत्ता)</p>
                          </div>
                          <div className="col-9 ps-2">
                          :{" "} {selectedMember.en_address}{' '}
                          </div>
                        </div>
                        <div className="col-12 col-md-5 d-flex">
                          <div className="col-4 para ms-md-2">
                            <p>Village/City(गाव/शहर)</p>
                          </div>
                          <div className="col-8 ps-3">
                          :{" "}{selectedMember.en_village}
                          </div>
                        </div>

                        {/* <div className="col-12 col-md-7 d-flex">
                          <div className="col-3 para">
                            <p>पत्ता(मराठी)</p>
                          </div>
                          <div className="col-9 ps-2">
                          :{" "} {selectedMember.mr_address}
                          </div>
                        </div>
                        <div className="col-12 col-md-5 d-flex">
                          <div className="col-4 para ms-md-2">
                            <p>गाव/शहर(मराठी)</p>
                          </div>
                          <div className="col-8 ps-2">
                            :{' '}
                            {selectedMember.mr_village}{' '}
                          </div>
                        </div> */}

                      </div>

                      <div
                        className=" col-12 p-2 d-flex"
                        style={{ flexWrap: 'wrap' }}
                      >
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-4 para">
                            <p>State(राज्य)</p>
                          </div>
                          <div className="col-8 ps-2">
                            :{' '}
                            {selectedMember.stateenname}
                          </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-4 para">
                            <p>District(जिल्हा)</p>
                          </div>
                          <div className="col-8 ps-2">
                            :{' '}
                            {selectedMember.districtenname}
                          </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-4 para">
                            <p>Taluka(तालुका)</p>
                          </div>
                          <div className="col-8 ps-2">
                          :{" "}{selectedMember.talukaenname}
                          </div>
                        </div>

                        {/* <div className="col-12 col-md-4 d-flex">
                          <div className="col-4 para">
                            <p>राज्य(मराठी)</p>
                          </div>
                          <div className="col-8 ps-2">
                          :{" "}{selectedMember.statemrname}
                          </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-4 para">
                            <p>जिल्हा(मराठी)</p>
                          </div>
                          <div className="col-8 ps-2">
                          :{" "}{selectedMember.districtmrname}
                          </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-4 para">
                            <p>तालुका(मराठी)</p>
                          </div>
                          <div className="col-8 ps-2">
                          :{" "}{selectedMember.talukamrname}
                          </div>
                        </div> */}

                      </div>
                      <div
                        className=" col-12 d-flex p-2"
                        style={{ flexWrap: 'wrap' }}
                      >
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-4 para">
                            <p style={{ lineHeight: '18px' }}>Email/ईमेल</p>
                          </div>
                          <div className="col-8">
                            <p>:{" "}{selectedMember.emailid}</p>
                          </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-4 para">
                            <p style={{ lineHeight: '18px' }}>
                              Mobile No-1/मोबाईल नंबर-1
                            </p>
                          </div>
                          <div className="col-8">
                            <p>:{" "}{selectedMember.mobileno}</p>
                          </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-4 para">
                            <p style={{ lineHeight: '18px' }}>
                              Mobile No-2/मोबाईल नंबर-2
                            </p>
                          </div>
                          <div className="col-8">
                            <p>:{" "}{selectedMember.mobileno2}</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Expectations about spouse */}
                    <div
                      className="row m-0 border mt-3 mb-3"
                      style={{ backgroundColor: '#e9e0e3' }}
                    >
                      <div className="col-12 border-bottom" style={info}>
                        <h6>Expectations About Spouse/जोडीदाराबद्दल अपेक्षा</h6>
                      </div>
                      <div className=" col-12 p-2">
                        <div className="row d-flex">
                          <div className="col-5 col-md-3  para">
                            <p>Expectations/अपेक्षा:</p>
                          </div>
                          <div className="col-7 col-md-9"> <p>{selectedMember.exp_housewife === 1 ? 'Homemaker' : null} &nbsp; {selectedMember.exp_compatible === 1 ? 'Compatible' : null} &nbsp; {selectedMember.exp_farmer === 1 ? 'Farmer' : null}{" "}{selectedMember.exp_serviceman === 1 ? 'Service' : null} &nbsp; {selectedMember.exp_businessman === 1 ? 'Business' : null} &nbsp; </p></div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <AdminMasterFooter/>
    </>
  )
}

export default MemberProfiles
