import React, { useEffect, useRef, useState } from 'react'
import AdminMasterFooter from '../CommonComponenets/AdminMasterFooter'
import AdminMasterHeader from '../CommonComponenets/AdminMasterHeader'
import { Col, Form, Row } from 'react-bootstrap'
import { EducationMaster } from '../AllApi/UserApi/UserApi'
import axios from 'axios';
import html2pdf from 'html2pdf.js';
import { saveAs } from 'file-saver';
import { memberImgPath, reportDataPDF } from '../AllApi/AdminApi/AdminApi'
import { toast } from 'react-toastify'
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useReactToPrint } from 'react-to-print'

const AdminPrintReports = () => {

  const [Userdata, setUserdata] = useState()
  const [gender,setGender] = useState()
  const [Maritialsatatus,setMaritialsatatus] = useState()
  const [Disability,setDisability] = useState()
  const [Category,setCategory] = useState()
  const [srNo,setSrNo] = useState()
  const [fromNo,setFromNo] = useState()
  const [TotalNo,setTotalNo] = useState()
  const [EducationData, setEducationData] = useState([]);
  const [showTableData,setShowTableData] = useState(false)

  useEffect(() => {
    Userdata && Array.isArray(Userdata) && Userdata.map((value, index) => {
    }
    )

  }, [Userdata])


  const getUserData = (value) => {
    axios.get(`${reportDataPDF}/${gender}/${value}`)
      .then((res) => {
        
        if (res.data === "NOTFOUND") {
          toast.error("Data Not Found", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          setSrNo('');
          setTotalNo(0);
        }
        else{
            setTotalNo(res.data)
            setSrNo('');
        }
        
      })
      .catch((err) => {
        console.log(err)
      })

      setCategory(value)
  }

  // ==== education api =====

  const getEducationData = () => {
    axios
      .get(EducationMaster)
      .then((res) => {
        if (res.data !== null) {
          setEducationData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  
  const HandleSaveFun =(e)=>{
    e.preventDefault();
    axios.get(`${reportDataPDF}/${gender}/${Category}/${srNo}`)
      .then((res) => {
        if (res.data === "NOTFOUND") {
          toast.error("Data Not Found", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
         
        }
        else{
         const data=res.data         
            setUserdata(data)             
        }
      })
      .catch((err) => {
        console.log(err)
      })
    
    setShowTableData(true)
  }


  useEffect(() => {
      getEducationData();
  }, [])

    // ---------------print-----
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
      documentTitle: 'Payment_Recipt',
      // onAfterPrint: () => alert('Print Success')
    })
  
    const pdfOptions = {
      margin: 10,
      filename: 'Payment_Recipt.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
      pagebreak: { before: '.page-break' }, // Use this class to force page breaks
    };
  
    // Select the content to print (excluding buttons)
    const contentToPrint = document.getElementById('content-to-print');
  
    // Generate the PDF
    html2pdf().from(contentToPrint).set(pdfOptions).outputPdf((pdf) => {
      // Save the PDF to the user's PC
      saveAs(pdf, 'Payment_Recipt.pdf');
    });

  //--------------------------CSS---------------------------------
  // const style1 = { lineHeight: "15px", fontSize: "14px" }
  const style3 = { lineHeight: "15px", fontSize: "12px" }
  const style = { fontSize: "12px", width: "100%", padding: "2px", backgroundColor: "#f796ec", fontWeight: "bold", borderRadius: "10px 10px 0px 0px", border: "1px solid white" }
  const style2 = { border: "1px solid #f796ec", padding: "3px 5px", alignItems: "center", height: "65px", margin: "0px 1px 0px 1px", textAlign: "start" }
  const style4 = { display: "flex", lineHeight: "8px", fontSize: "12px", justifyContent: "center" }
  const style6 = { display: "flex", lineHeight: "8px", fontSize: "12px", justifyContent: "center" }
  const style5 = { display: "flex", lineHeight: "12px", fontSize: "12px", justifyContent: "center", flexDirection: "column" }

  return (
    <>
      <div> <AdminMasterHeader/> </div>
     
     <Row className="m-0 p-0 px-md-5 mx-md-5">
     <Col>
     <div className=' rounded shadow  p-3 m-md-5' style={{ backgroundColor:"white",  boxShadow:" 2px 2px 4px gray"}} >
   <Row>
   <Row className='m-0'>
      <Col className='text-center mb-2'>
        <h2 style={{color:"rgb(124, 58, 237)",fontFamily:"sans-serif",color: "rgb(134, 28, 18)"}}>PRINT REPORT</h2>
      </Col>
      </Row>
      <Form encType="multipart/form-data" onSubmit={HandleSaveFun}>
   <Row className='m-0 '>
    <Col sm={12} md={3} className='mt-3'>
      <Form.Label><b>Gender :</b></Form.Label>
      <Form.Select
        aria-label="Default select example"
        value={gender}
        onChange={(e) => setGender(e.target.value)}
        required
      >
        <option value="">Select Gender</option>
        <option value="1">Male</option>
        <option value="2">Female</option>
      </Form.Select>
    </Col>

    <Col sm={12} md={3} className='mt-3'>
      <Form.Label>
       <b>Category :</b> 
      </Form.Label>
      <Form.Select
        aria-label="Default select example"
        onChange={(e)=>getUserData(e.target.value)}

        value={Category}
        required
      >
        <option value="">Choose...</option>
        <option value="1">Medical</option>
        <option value="2">Engineering</option>
        <option value="3">Graduates & Post Graduates</option>
        <option value="4">Other</option>
        <option value="5">Divorcee</option>
        <option value="6">Widow</option>
        <option value="7">Disability</option>
      </Form.Select>
    </Col>
    <Col sm={12} md={3} className='mt-3'>
      <Form.Label><b>Total Candidates:</b></Form.Label>
        <Form.Control
        value={TotalNo}
          aria-label="Default select example"
        ></Form.Control>
      </Col>
      <Col sm={12} md={3} className='mt-3'>
      <Form.Label><b>From Sr No. :</b></Form.Label>
        <Form.Control
          aria-label="Default select example"
          required
          value={srNo}
          onChange={(e) => setSrNo(e.target.value)}
        ></Form.Control>
      </Col>
   </Row>

   <Row className='m-0 my-2'>
     <Col style={{display:"flex", justifyContent:"center"}}>

             <button className='adminButton py-2 px-3 mx-2 mt-4 rounded' style={{
                backgroundColor: "rgb(233, 224, 227)",
                border: "0px",
                color: "black",
              }} type='submit' ><b>SHOW</b></button>
              
              <button className='adminButton py-2 px-3 mx-2 mt-4 rounded text-end' style={{ backgroundColor: "rgb(233, 224, 227)",
                border: "0px", color: "black",}} onClick={handlePrint}   disabled={!Userdata || Userdata.length == 0} ><b>Print</b></button>

     </Col>
   </Row>
 </Form>
   </Row>
 </div>
     </Col>
   </Row>

   

   {/* ====================== data ==================== */}


   <div ref={componentRef} id="content-to-print" style={{ width: "100%", height: window.innerHeight }}>
   
   {
    Userdata && Array.isArray(Userdata) && Userdata.map((value, index) => {

      return (
        <>
        
        <div className='row m-0'>
                     <div className='col-12 col-lg-1'></div>

                     <div className='col-12 col-lg-10 p-0 ps-4' style={{ backgroundColor: "white", overflow: "auto", }} >

                        <div className='p-2' style={{ backgroundColor: "#f796ec", display: "flex", alignItems: "center", width: "1000px" }}>
                          <div className='col-3'></div>
                          <div className='col-9 me-3' style={{ alignItems: "center", display: "flex" }}>
                            <div className='col-9' style={{ margin: "0px 0px 0px -70px", alignItems: "end", }}><p style={{ fontWeight: "bold", fontSize: "20px", }}>{value.en_fname}/{' '}{value.en_fatherfname}{' '}{value.en_fathermname}{' '} {value.en_fatherlname} </p></div>

                            <div className='col-3 me-5' style={{ textAlign: "center", padding: "6px", border: "2px solid white", borderRadius: "50px", backgroundColor: "white", margin: "0px 0px 0px 50px" }}><label style={{ fontWeight: "bold", }}>{value.gender === 1 ? 'APP2024/' : 'APP2024/'}{value.member_id}</label></div>
                          </div>
                        </div>

                        <div className=' d-flex p-0' style={{ border: "2px solid #c42fb2", borderTopColor: "#f796ec", width: "1000px", backgroundColor: "white" }}>
                          <div className='col-2 p-2' style={{ justifyContent: "center", display: "flex" }}>
                            <img src={`${memberImgPath}/${value.image}`} alt={`${value.en_fname}'s Photo`} style={{ width: "100%", height: "200px", border: "1px solid grey" }} />
                          </div>

                          <div className='col-10 px-4 py-2'>

                            <div className='row p-0'>
                              <div className='col-8 p-0 pt-2' style={{ lineHeight: "10px" }}>
                                <p style={{ fontSize: "15px", lineHeight: "18px" }}><b>Add : </b>{' '} {value.en_address}{' '}&nbsp;<b>Village/City : </b>{value.en_village}{' '}&nbsp;<b>Tal : </b>{value.talukaenname}{' '}&nbsp;<b>Dist : </b>{value.districtenname} {' '}&nbsp;<b>State : </b>{value.stateenname}</p>
                                <p style={{ fontSize: "15px", lineHeight: "10px" }}><b>Mob : </b>{' '}{value.mobileno}/{value.mobileno2}</p>
                                <p style={{ fontSize: "15px", lineHeight: "10px" }}><b>Occupation : </b>{' '}{value.occupation},&nbsp;{value.occupation_detail}</p>
                              </div>

                              <div className='col-4 p-0' style={{ alignItems: "end", display: "flex", flexDirection: "column" }}>
                                
                                    <div className='d-flex mb-1 w-100' style={{ fontSize: "14px", lineHeight: "20px", }}>

                                      <div style={{ padding: "10px 15px", color: "white", width: "65px", fontWeight: "bold", backgroundColor: "#f796ec", justifyContent: "center", display: "flex", borderRadius: "10px 0px 0px 10px" }}><b>Brother</b></div>

                                      <div style={{ width: "200px", display: "flex", padding: "10px 10px", border: "1px solid #f796ec"}} >
                              
                                      
                                          <span style={{ fontSize: "13px" }}><b>Married- </b>{value.b_married > 0 ? value.b_married: '-' }</span>
                                        
                                        
                                          <span style={{ fontSize: "13px" }} className='ms-4'><b> Unmarried- </b>{value.b_unmarried > 0 ? value.b_unmarried: ' -'}</span>
                                      

                                
                                      </div>
                                      
                                    </div>
                                  

                                  <div className='d-flex mt-1 w-100' style={{ fontSize: "14px", lineHeight: "20px", }}>

                                      <div style={{ padding: "10px 10px", color: "white", width: "65px", fontWeight: "bold", backgroundColor: "#f796ec", justifyContent: "center", display: "flex", borderRadius: "10px 0px 0px 10px" }}><b>Sister</b></div>

                                      <div style={{ width: "200px", display: "flex", padding: "10px 10px", border: "1px solid #f796ec"}} >
                                          <span style={{ fontSize: "13px" }}><b>Married- </b>{value.s_married > 0 ? value.s_married: '-' }</span>
                                          <span style={{ fontSize: "13px" }}  className='ms-4'><b> Unmarried- </b>{value.s_unmarried > 0 ? value.s_unmarried: '-'}</span> 
                                      </div>

                                  </div>
                                  
                              </div>
                            </div>


                            <div className="row p-0 d-flex mt-2">
                              <div className='col-2 p-0' style={{ textAlign: "center", display: "flex", flexDirection: "column", }}>
                                <div style={style}><b>DOB & Time</b></div>
                                <div style={style2}> <p style={style4}>{value.dob}</p> <p style={style4}>{value.birth_time}</p></div>
                              </div>

                              <div className='col-2 p-0' style={{ textAlign: "center", display: "flex", flexDirection: "column", }}>
                                <div style={style}><b>Height & Comp.</b></div>
                                <div style={style2}> <p style={style4}>{value.height}</p> <p style={style4}>{value.complextion}</p></div>
                              </div>

                              <div className='col-2 p-0' style={{ textAlign: "center", display: "flex", flexDirection: "column", }}>
                                <div style={style}><b>Education</b></div>
                                <div style={style2}> <h6 style={style5} className='text-center'>{value.subeducation_name},&nbsp;<p className='text-center pt-1' style={style5}>{value.other_education}</p></h6>
                                </div>
                              </div>

                              <div className='col-3 p-0' style={{ textAlign: "center", display: "flex", flexDirection: "column", }}>
                                <div style={style}><b>Annual Income & Assets</b></div>
                                <div style={style2}> <h6 style={style4}>{value.amount}</h6>
                                  { value.farm_g > 0 || value.farm_ac > 0 ? (
                                    <>
                                      <h6 style={style4}><b>Farm -&nbsp;</b>
                                      {value.farm_ac > 0 ? (
                                    <span> {value.farm_ac}&nbsp; acre{' '}</span>
                                      ) : null}
                                      {value.farm_g > 0 ? (
                                        <span style={style3}>{' '}&nbsp; {value.farm_g} &nbsp; gunthe{' '}</span>
                                        ) : null
                                      }
                                      </h6>
                                    </>
                                  ) : null}

                                  <span className='text-center' style={style4}>{value.other_property}</span></div>
                              </div>


                              <div className='col-2 p-0' style={{ textAlign: "center", display: "flex", flexDirection: "column",width:"93px"  }}>
                                <div style={{ fontSize: "12px", width: "100%", padding: "2px 0px", backgroundColor: "#f796ec", fontWeight: "bold", borderRadius: "10px 10px 0px 0px", border: "1px solid white" }}><b>SubCaste </b></div>
                                <div style={style2}> <p style={{lineHeight:"18px",fontSize:"13px"}}>{value.subcastename}</p></div>
                              </div>

                              <div className='p-0' style={{ textAlign: "center", display: "flex", flexDirection: "column",width:"108px" }}>
                                <div style={style}><b>Expectations</b></div>
                                <div style={style2}> <p style={{lineHeight: "20px", fontSize: "13px",display:"flex",flexDirection:"column",textAlign:"center",margin:"0px 12px",flexWrap:"wrap"}}>{' '}{value.exp_housewife === 1 ? 'Housewife ' : null}{value.exp_compatible === 1 ? 'Compatible ' : null}{value.exp_farmer === 1 ? 'Farmer ' : null}{value.exp_serviceman === 1 ? 'Service ' : null}{value.exp_businessman === 1 ? 'Business ' : null}</p></div>
                              </div>

                            </div>
                          </div>
                        </div>

                    </div>


                     <div className='col-12 col-lg-1'></div>
                  </div>
        </>
      )
    })}
    </div>
     
    </>
  )
}

export default AdminPrintReports

