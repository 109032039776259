import React, { useEffect, useState, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import UserMasterHeader from './UserCommonComponent/UserMasterHeader';
import { useLocation, useNavigate } from 'react-router-dom';
import { BarCodeRecipt, PaymentSuccessApi, receiptDataApi, RegistrationSelect } from '../AllApi/UserApi/UserApi';
import axios from 'axios';
import html2pdf from 'html2pdf.js';
import { saveAs } from 'file-saver';
import Logo from '../assets/Mainlogo.jpg';
import Logo2 from '../assets/logo2.png';
import Nav from 'react-bootstrap/Nav';
import blink from '../assets/photo_rejected.gif'
import Navbar from 'react-bootstrap/Navbar';
import { set } from 'react-hook-form';
import { memberImgPath } from '../AllApi/AdminApi/AdminApi';


const PaymentRecipt = () => {
  const location = useLocation();
  // const state = location.state?.member_id;

  const state = location.state;
 
  const userdata = JSON.parse(sessionStorage.getItem("userdata"));
  const mid = userdata.id
  const [member_id, setmember_id] = useState(mid)
  
  const [spinnerstatus, setspinnerstatus] = useState(false)
  
  useEffect(() => {
    if (state === undefined) {
      setmember_id(mid)
    }
    else {
      setmember_id(state)
    }

  }, [state, mid])

  const [barcode, setbarcode] = useState()
  const [userid, setuserid] = useState()
  // ---------------print-----
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Payment_Recipt',
    // onAfterPrint: () => alert('Print Success')
  })

  const pdfOptions = {
    margin: 10,
    filename: 'Payment_Recipt.pdf',
    image: { type: 'jpeg', quality: 0.98 },
    html2canvas: { scale: 2 },
    jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    pagebreak: { before: '.page-break' }, // Use this class to force page breaks
  };

  // Select the content to print (excluding buttons)
  const contentToPrint = document.getElementById('content-to-print');

  // Generate the PDF
  html2pdf().from(contentToPrint).set(pdfOptions).outputPdf((pdf) => {
    // Save the PDF to the user's PC
    saveAs(pdf, 'Payment_Recipt.pdf');
  });



  const [Userdata, setUserdata] = useState()

  useEffect(() => {
    Userdata && Array.isArray(Userdata) && Userdata.map((value, index) => {
      setuserid(value.reg_id)
    }
    )

  }, [Userdata])


  const getUserData = (member_id) => {


    axios.get (`${RegistrationSelect}${member_id}`)
      .then((res) => {
        
        if (res.data.json !== null) {
          setUserdata(res.data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    if (member_id) {
      setspinnerstatus(false)
      getUserData(member_id)
      
        }
    else{
      setspinnerstatus(true)
    }

  }, [member_id])

  // -----------handle checkbox-----------
  const navigate = useNavigate()


  const regFun = () => {
    const state = 1
    navigate('/user/regForm', { state: { state } })

    // <Registartion1 />
  }

  // =============PaymentRecipt==============
  const [Userdatapay, setUserdatapay] = useState([])

  
  const getUserDatapay = (userid) => {


    axios.get(`${receiptDataApi}/${userid}`)
      .then((res) => {        

        if (res.data !== null) {

          setUserdatapay(res.data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    if(userid){

      setspinnerstatus(false)
    getUserDatapay(userid)}
    else{
      setspinnerstatus(true)
    }
  }, [userid])

  //=========BarCode===========


  const BarCodeReciptPay = (userid) => {
    const regdata = {

      reg_id: userid

    };

    axios
      .post(BarCodeRecipt, regdata)
      .then((res) => {
        if (res.data !== null) {

          setbarcode(res.data.barcodeDataUri)

        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if(userid){

      setspinnerstatus(false)
    BarCodeReciptPay(userid)
    }else{
      setspinnerstatus(true)
    }
  }, [userid])

  // =====================Header=====================


  const [showLogoutModal, setShowLogoutModal] = useState(false);

  const handleLogout = () => {
    sessionStorage.removeItem('userdata');
    sessionStorage.clear();
    navigate('/');
  };

  const handleCloseModal = () => setShowLogoutModal(false);
  const handleShowModal = () => setShowLogoutModal(true);

  useEffect(() => {
    let token = sessionStorage.getItem("token");
    let userdata = sessionStorage.getItem("userdata");

    if (token == null || userdata == null) {
      sessionStorage.clear()
      navigate("/")
    }

  }, [])


  //--------------------------CSS---------------------------------
  const style1 = { lineHeight: "15px", fontSize: "14px" }
  const style3 = { lineHeight: "15px", fontSize: "11px" }
  const style = { fontSize: "12px", width: "100%", padding: "3px", backgroundColor: "#f796ec", fontWeight: "bold", borderRadius: "10px 10px 0px 0px", border: "1px solid white" }
  const style2 = { border: "1px solid #f796ec", padding: "8px 5px", alignItems: "center", height: "88px", margin: "0px 1px 0px 1px", textAlign: "start" }
  const style4 = { display: "flex", lineHeight: "15px", fontSize: "11px", justifyContent: "center" }
  const style5 = { display: "flex", lineHeight: "15px", fontSize: "11px", justifyContent: "center", flexDirection: "column" }


  return (
    <>

      <Container fluid className='m-0 p-0 '>
        
        <div className='pe-md-5 pe-2 py-3' style={{ display: "flex", justifyContent: "end" }}>
          <button className='py-2 px-4 rounded' style={{ backgroundColor: "red", color: "white", border: "0px" }} ><b><Nav.Link href="/#/mainPage" className=' mx-2 text-white'>Profile</Nav.Link></b></button> &nbsp; &nbsp;
          <button className='py-2 px-4 rounded' style={{ backgroundColor: "red", color: "white", border: "0px" }} onClick={handlePrint}><b>Download Receipt</b></button>
          <button className='py-2 px-4 rounded mx-2' style={{ backgroundColor: "red", color: "white", border: "0px" }}><Nav.Link  onClick={handleShowModal}><b >Log-Out</b></Nav.Link></button>
        </div>
        <div ref={componentRef} id="content-to-print" style={{ width: "100%", height: window.innerHeight }}>
          <div className='m-0  rounded'  >
            <div>
              <Container fluid className='p-0 m-0 mt-2' style={{ boxSizing: "border-box", }}>
                <Row className='header m-0 p-0 py-2'>
                  <Col md={2} sm={1}></Col>
                  <Col md={2} sm={2} style={{ display: "flex", justifyContent: "center" }}>
                    <div>
                      <img src={Logo2} style={{ mixBlendMode: "darken" }} alt="" width={150} height={120} />
                    </div>
                  </Col>

                  <Col md={4} sm={6} style={{ display: "flex", justifyContent: "center" }}>
                    <div className='text-center '>
                      <h5 className='fw-bolder'>दक्षिण भारत जैन सभेचे</h5>
                      <h4 className='fw-bolder'>शेठ रा. ध. दावडा दिगंबर जैन बोर्डिंग, सांगली </h4>
                      <h5 className='fw-bolder'>जैन वधू - वर नोंदणी 2024</h5>
                    </div>
                  </Col>

                  <Col md={2} sm={2} style={{ display: "flex", justifyContent: "center" }}>
                    <div>
                      <img src={Logo} style={{ mixBlendMode: "darken" }} alt="" width={150} height={120} />
                    </div>
                  </Col>
                  <Col md={2} sm={1}></Col>
                </Row>

              </Container>
            </div>
            <div className='row p-0 m-0 pt-2'>
              <div className='col-12' style={{ display: "flex", justifyContent: "center" }}>
                <div className='text-center '>
                  <h5><b>Confirmation Receipt</b></h5>
                  <span style={{fontSize:"13px"}}><b>19 th Grand Jain Vadhu-Var Melava</b></span> <br/>
                  <span style={{fontSize:"13px"}}><b>Sunday 8 December 2024 from 9 am to 5 pm </b></span> <br/>
                  <span style={{fontSize:"13px"}}><b>Venue : Aacharya Adisagar Sanskrutik Bhavan, Near Maruti Showroom, Kolhapur Road, Ankali, Sangli.</b></span>
                </div>
              </div>
            </div>

  {
    Userdata && Array.isArray(Userdata) && Userdata.map((value, index) => {

      return (
        <>
                <div className='row m-0 pt-2'>
                     <div className='col-12 col-lg-1'></div>

                     <div className='col-12 col-lg-10 p-0 ps-md-4' style={{ backgroundColor: "white", }} >

                        <div className='p-1' style={{ backgroundColor: "#f796ec", display: "flex", alignItems: "center", width: "850px" }}>
                          <div className='col-3'></div>
                          <div className='col-9 me-3' style={{ alignItems: "center", display: "flex" }}>
                            <div className='col-9' style={{ margin: "0px 0px 0px -70px", alignItems: "end", }}><p style={{ fontWeight: "bold", fontSize: "16px", }}>{value.en_fname}/{' '}{value.en_fatherfname}{' '}{value.en_fathermname}{' '} {value.en_fatherlname} </p></div>

                            <div className='col-3 me-5' style={{ textAlign: "center", padding: "4px", border: "2px solid white", borderRadius: "50px", backgroundColor: "white", margin: "0px 0px 0px 50px" }}><label style={{ fontWeight: "bold", }}>{value.gender === 1 ? 'APP2024/' : 'APP2024/'}{value.member_id}</label></div>
                          </div>
                        </div>

                        <div className='d-flex p-0' style={{ border: "2px solid #c42fb2", borderTopColor: "#f796ec", width: "850px", backgroundColor: "white" }}>
                          <div className='col-2 p-1' style={{ justifyContent: "center", display: "flex" }}>
                            <img src={`${memberImgPath}/${value.image}`} alt={`${value.en_fname}'s Photo`} style={{ width: "90%", height: "180px", border: "1px solid grey",marginTop:"15px" }} />
                          </div>

                          <div className='col-10 ps-2 pe-3 py-2'>

                            <div className='row p-0'>
                              <div className='col-8 p-0 pt-2' style={{ lineHeight: "10px" ,}}>
                                <p style={{ fontSize: "12px", lineHeight: "18px" }}><b>Add : </b>{' '} {value.en_address}{' '}&nbsp;<b>Village/City : </b>{value.en_village}{' '}&nbsp;<b>Tal : </b>{value.talukaenname}{' '}&nbsp;<b>Dist : </b>{value.districtenname} {' '}&nbsp;<b>State : </b>{value.stateenname}</p>
                                <p style={{ fontSize: "12px", lineHeight: "10px" }}><b>Mob : </b>{' '}{value.mobileno}/{value.mobileno2}</p>
                                <p style={{ fontSize: "12px", lineHeight: "10px" }}><b>Occupation : </b>{' '}{value.occupation},&nbsp;{value.occupation_detail}</p>
                              </div>

                              <div className='col-4 p-0' style={{ alignItems: "end", display: "flex", flexDirection: "column", }}>
                                
                                    <div className='d-flex mb-1 w-100' style={{ fontSize: "12px", lineHeight: "20px", }}>

                                      <div style={{ padding: "7px 5px", color: "white", width: "60px", fontWeight: "bold", backgroundColor: "#f796ec", justifyContent: "center", display: "flex", borderRadius: "10px 0px 0px 10px" }}><b>Brother</b></div>

                                      <div style={{ width: "270px", display: "flex", padding: "7px 8px", border: "1px solid #f796ec"}} >
                              
                                      
                                          <span style={{ fontSize: "12px" }}><b>Married- </b>{value.b_married > 0 ? value.b_married: '-' }</span>
                                        
                                        
                                          <span style={{ fontSize: "12px" }} className='ms-4'><b> Unmarried- </b>{value.b_unmarried > 0 ? value.b_unmarried: ' -'}</span>
                                      

                                
                                      </div>
                                      
                                    </div>
                                  

                                  <div className='d-flex mt-1 w-100' style={{ fontSize: "12px", lineHeight: "20px", }}>

                                      <div style={{ padding: "7px 10px", color: "white", width: "60px", fontWeight: "bold", backgroundColor: "#f796ec", justifyContent: "center", display: "flex", borderRadius: "10px 0px 0px 10px" }}><b>Sister</b></div>

                                      <div style={{ width: "260px", display: "flex", padding: "7px 8px", border: "1px solid #f796ec"}} >
                                          <span style={{ fontSize: "12px" }}><b>Married- </b>{value.s_married > 0 ? value.s_married: '-' }</span>
                                          <span style={{ fontSize: "12px" }}  className='ms-4'><b> Unmarried- </b>{value.s_unmarried > 0 ? value.s_unmarried: '-'}</span> 
                                      </div>

                                  </div>
                                  
                              </div>
                            </div>


                            <div className="row p-0 d-flex mt-2">
                              <div className='col-2 p-0' style={{ textAlign: "center", display: "flex", flexDirection: "column",width:"105px" }}>
                                <div style={style}><b>DOB & Time</b></div>
                                <div style={style2}> <p style={style4}>{value.dob}</p> <p style={style4}>{value.birth_time}</p></div>
                              </div>

                              <div className='col-2 p-0' style={{ textAlign: "center", display: "flex", flexDirection: "column",width:"105px" }}>
                                <div style={style}><b>Height & Comp.</b></div>
                                <div style={style2}> <p style={style4}>{value.height}</p> <p style={style4}>{value.complextion}</p></div>
                              </div>

                              <div className='col-2 p-0' style={{ textAlign: "center", display: "flex", flexDirection: "column",width:"135px" }}>
                                <div style={style}><b>Education</b></div>
                                <div style={style2}> <h6 style={style5} className='text-center'>{value.subeducation_name},&nbsp;<p className='text-center pt-1' style={style5}>{value.other_education}</p></h6>
                                </div>
                              </div>

                              <div className='col-3 p-0' style={{ textAlign: "center", display: "flex", flexDirection: "column",width:"170px" }}>
                                <div style={style}><b>Annual Income & Assets</b></div>
                                <div style={style2}> <h6 style={style4}>{value.amount}</h6>
                                  { value.farm_g > 0 || value.farm_ac > 0 ? (
                                    <>
                                      <h6 style={style4}><b>Farm -&nbsp;</b>
                                      {value.farm_ac > 0 ? (
                                    <span> {value.farm_ac}&nbsp; acre{' '}</span>
                                      ) : null}
                                      {value.farm_g > 0 ? (
                                        <span style={style3}>{' '}&nbsp; {value.farm_g} &nbsp; gunthe{' '}</span>
                                        ) : null
                                      }
                                      </h6>
                                    </>
                                  ) : null}

                                  <span className='text-center' style={style4}>{value.other_property}</span></div>
                              </div>


                              <div className='col-2 p-0' style={{ textAlign: "center", display: "flex", flexDirection: "column",width:"93px"  }}>
                                <div style={style}><b>Sub Caste </b></div>
                                <div style={style2}> <p className='text-center' style={{lineHeight:"18px",fontSize:"11px",}}>{value.subcastename}</p></div>
                              </div>

                              <div className='p-0' style={{ textAlign: "center", display: "flex", flexDirection: "column",width:"95px" }}>
                                <div style={style}><b>Expectations</b></div>
                                <div style={style2}> <p style={{lineHeight: "20px", fontSize: "11px",display:"flex",flexDirection:"column",textAlign:"center",margin:"0px 12px",flexWrap:"wrap"}}>{' '}{value.exp_housewife === 1 ? 'Housewife ' : null}{value.exp_compatible === 1 ? 'Compatible ' : null}{value.exp_farmer === 1 ? 'Farmer ' : null}{value.exp_serviceman === 1 ? 'Service ' : null}{value.exp_businessman === 1 ? 'Business ' : null}</p></div>
                              </div>

                            </div>
                          </div>
                        </div>

                    </div>


                     <div className='col-12 col-lg-1'></div>
                  </div>


              <div style={{display:"flex",justifyContent:"center"}}>
              {
                    value.image_status === 2 ?
                    <div className='text-center'> <img style={{height:"80px"}} src={blink} alt="" /> </div>
                  : null}
              </div>
        </>
      )
    })}

            {/* //------------------payment-------------// */}
            <Row className='p-0 m-0 py-3 '>
              <Col style={{ textAlign: "center" }}>
                <h6><b style={{ color: "red" }}>Please carry the print of this Confirmation Receipt at the time of Melava, Without Receipt entry will not be allowed to Melava.</b></h6>

                <span><b style={{ fontFamily: "cursive", color: "red",fontSize:"14px" }}>कृपया मेळाव्याच्या वेळी या पावतीची प्रिंट सोबत ठेवा, पावतीशिवाय मेळाव्याला प्रवेश दिला जाणार नाही.</b></span>
              </Col>
            </Row>
            {spinnerstatus === true ? (
              <>
                <div class="spinner-grow text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-secondary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-success" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-danger" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-warning" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-info" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-light" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-dark" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </>
            ) : null
            }

            <Row className="m-0">
              <Col sm={2} md={3}></Col>

              <Col sm={10} md={6}>
                <Row style={{marginTop:"-5px"}}>
                  <Col>
                    <b>Application Id :</b>
                  </Col>
                  <Col>
                    <p>Reg. No. APP2024/{Userdatapay.reg_id}</p>
                  </Col>
                </Row>

                <Row style={{marginTop:"-5px"}}>
                  <Col>
                    <b>Payment Date & Time :</b>
                  </Col>
                  <Col>
                    <p>{Userdatapay.paymentdate}</p>
                  </Col>
                </Row>

                <Row style={{marginTop:"-5px"}}>
                  <Col>
                    <b>Payment Receipt Date :</b>
                  </Col>
                  <Col>
                    <p>{Userdatapay.receipt_date}</p>
                  </Col>
                </Row>

                <Row style={{marginTop:"-5px"}}>
                  <Col>
                    <b>Customer Name :</b>
                  </Col>
                  <Col>
                    <p>{Userdatapay.fname}</p>
                  </Col>
                </Row>

                <Row style={{marginTop:"-5px"}}>
                  <Col>
                    <b>Amount :</b>
                  </Col>
                  <Col>
                    <p>Rs. {Userdatapay.amount}</p>
                  </Col>
                </Row>

                <Row style={{marginTop:"-5px"}}>
                  <Col>
                    <b>Email Id :</b>
                  </Col>
                  <Col>
                    <p>{Userdatapay.emailid}</p>
                  </Col>
                </Row>

                <Row style={{marginTop:"-5px"}}>
                  <Col>
                    <b>Transaction Id :</b>
                  </Col>
                  <Col>
                    <p>{Userdatapay.tnxid}</p>
                  </Col>
                </Row>

                <Row style={{marginTop:"-5px"}}>
                  <Col>
                    <b>Mode of payment :</b>
                  </Col>
                  <Col>
                    <p>{Userdatapay.mode}</p>
                  </Col>
                </Row>

                <Row style={{marginTop:"-5px"}}>
                  <Col>
                    <b>Mobile No :</b>
                  </Col>
                  <Col>
                    <p>{Userdatapay.mobileno}</p>
                  </Col>
                </Row>
              </Col>

              <Col sm={2} md={3}></Col>
            </Row>

            <Row className='m-0 pb-5 pt-2'>
              <Col style={{ display: "flex", justifyContent: "center" }}>
                <img src={barcode} ></img>
              </Col>
            </Row>

             {/* Logout Confirmation Modal */}
          <Modal show={showLogoutModal} onHide={handleCloseModal} centered>
              <Modal.Header closeButton>
                <Modal.Title>Confirm Logout</Modal.Title>
              </Modal.Header>
              <Modal.Body>Are you sure you want to log out?</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                  Cancel
                </Button>
                <Button style={{backgroundColor:"#660505",border:"1px solid #660505"}} onClick={handleLogout}>
                  Confirm
                </Button>
              </Modal.Footer>
            </Modal>


          </div>
        </div>


      </Container>

    </>
  )
}

export default PaymentRecipt




