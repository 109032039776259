import React, { useState ,useEffect} from 'react'
import './Members.css'
import AdminMasterHeader from '../../CommonComponenets/AdminMasterHeader'
import axios from 'axios';
import { adminDisabilityList,adminDisabilityApproval,adminMemberList, RegistrationSelect } from '../../AllApi/AdminApi/AdminApi';

import { toast } from 'react-toastify';
import { Row, Table,Col } from 'react-bootstrap';
import logo from '../../assets/Mainlogo.jpg'
import { MDBDataTable } from 'mdbreact';

const DisabilityProfiles = ({}) => {


// ----------------------------------------------------------------

  const [record, setRecord] = useState([])
  const [record1, setRecord1] = useState([])
  const [disabilityobj, setdisabilityobj] = useState()
  const [showLargePhoto, setShowLargePhoto] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [selectedMember, setSelectedMember] = useState(null);
  const [showOperationButtons, setShowOperationButtons] = useState(false);
  const [Regid, setRegid] = useState() ;

  const [isViewingProfile, setIsViewingProfile] = useState(false)

  const [member_id, setMember_id] = useState()
 
   //----------------------------------API code------------------------------------------


   
   useEffect(() => {
    const tokenValue = sessionStorage.getItem('token')

    const tokenConfig = {
      headers: {
        token: tokenValue,
      },
    }

    const fetchMemberData = async () => {
      try {
        const response = await axios.get(
          adminDisabilityList,
          tokenConfig);
        setRecord(response.data);
        setSelectedMember(response.data);

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    
  fetchMemberData()
}, [])




  //--------------------------Image click code-----------------------------------

  const fetchMemberData1 = async (member_id) => {
    try {
      const response = await axios.get(`${RegistrationSelect}${member_id}`);
        const data=response.data
        const data2=data.find((item)=>item.member_id===member_id)
       
      setRecord1(data2);
      setSelectedMember(response.data);

    } catch (error) {
    console.error('Error fetching data:', error);
      }
    };
    

  const handleImageClick = (member) => {
    setSelectedPhoto(member.photo);
    setSelectedMember(record1);
    setShowOperationButtons(true); 
    setShowLargePhoto(true);
    setMember_id(member.member_id)
    setRegid(member.reg_id)
    const obj=record1.find((item) => item.member_id === member.member_id);
    setdisabilityobj(obj)

    setIsViewingProfile(true)
    fetchMemberData1(member)


  };

  const handleCloseLargePhoto = () => {
    setShowOperationButtons(false); 
    setShowLargePhoto(false);
    setSelectedPhoto(null);
    setSelectedMember(null);
  };
 
  const handleOperationClick = (operation) => {
    let updatedDisabilityStatus 
    if (operation === 'approve') {
      updatedDisabilityStatus = "1";

      toast.success('Disability Approved Successfully!', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      setTimeout(() => {
        window.location.reload();
      }, 2000);

    } else if (operation === 'reject') { 
      updatedDisabilityStatus = "2";

      toast.error('Disability Rejected !!', {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
    }  
   
    const data={
      member_id:record1.member_id,
      disability_status:updatedDisabilityStatus
      
    }
 
    axios.put(adminDisabilityApproval,data)
    .then((res) => {

    })
    .catch((err) => console.log(err))
    handleCloseLargePhoto();
  }; 

  
//CSS for Information-------------------------------------------------------------------
const info={
  display: "flex",
  alignItems:"center",
  paddingTop:"5px",
  backgroundColor:"#660505",
  color:"white",
}
const footerStyle = {
  backgroundColor: '#660505',
  boxShadow: '0 2px 4px -2px rgba(0,0,0,.5)',
  padding: '8px',
  textAlign: 'center',
  color: '#fff',
  fontSize: '12px',
  bottom: 0,
  width: '100%',
  letterSpacing: '1px',
}


//=====================================DATA TABLE============================================

const columns = [
  {
    label: 'Sr.no',
    field: 'srNo',
    sort: 'asc',
  },
  {
    label: 'Member Id',
    field: 'member_id',
    sort: 'asc',
  },
  {
    label: 'Reg.Id',
    field: 'reg_id',
    sort: 'asc',
  },
  {
    label: 'Name',
    field: 'name_en',
    sort: 'asc',
  },
  {
    label: 'Gender',
    field: 'gender',
    sort: 'asc',
  },
  {
    label: 'Email Id',
    field: 'emailid',
    sort: 'asc',
  },
  {
    label: 'Mobile No',
    field: 'mobileno',
    sort: 'asc',
  },
  {
    label: 'View Profile',
    field: 'action',
  },
];


const customRows = record.map((item, index) => {
  const { gender, name_en, mobileno, emailid, member_id,reg_id} = item;
  const genderLabel = gender === 1 ? 'Male' : 'Female';
  return {
    srNo: index + 1,
    member_id,
    reg_id,
    name_en,
    gender:genderLabel,
    mobileno,
    emailid,
    action: (
      <button className="viewButtons1" type='submit' onClick={() => handleImageClick(item.member_id)}>View Profile</button>
      
    ),
  };
});

//=====================================================================================================

  return (
    <>
    <div className="row m-0">
      <div className='p-0'> <AdminMasterHeader/> </div>
      <div className="filter-buttons row">

        <div className='col-12'>
        <h2 style={{ fontWeight: '550',textAlign:"start"}}>Disability Profiles</h2>
        </div>
        </div>


     <Row className='row p-0 m-0' style={{justifyContent:"center",display:"flex",textAlign:"center"}}>
    <div className="col-12 col-sm-1"></div>
    <div className="col-12 col-sm-10">
        <MDBDataTable
          className='mb-5 custom-datatable'
          striped
          bordered
          hover
          small
          paging={!showLargePhoto}
          data={{ columns, rows: customRows }}
          style={{ textAlign: "center", fontSize: "14px" ,}}/>
    </div>
    <div className="col-12 col-sm-1"></div>
    </Row>

      {showLargePhoto && (
          <div className="large-photo-modal">
            <div className="modal-content">
              <Row className="header m-0" style={{ borderBottom: '3px solid' }}>
                <Col
                  md={4}
                  sm={12}
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <div>
                    <img
                      src={logo}
                      style={{ mixBlendMode: 'darken' }}
                      alt=""
                      width={160}
                      height={130}
                    />
                  </div>
                </Col>

                <Col
                  md={8}
                  sm={12}
                  style={{ display: 'flex', justifyContent: 'start' }}
                >
                  <div className="text-center">
                    <h5 className="fw-bolder">दक्षिण भारत जैन सभेचे</h5>
                    <h3 className="fw-bolder">
                      शेठ रा. ध. दावडा दिगंबर जैन बोर्डिंग, सांगली{' '}
                    </h3>
                    <h5 className="fw-bolder">जैन वधू - वर नोंदणी २०२३</h5>
                  </div>
                </Col>
              </Row>

              <span
                title="Close"
                className="close1 ms-auto fs-1 me-3"
                onClick={handleCloseLargePhoto}
              >
                &times;
              </span>
              {isViewingProfile && (
                <div className="row profile-details m-0">
                  <div
                    className="col-12 col-md-3"
                    style={{
                      justifyContent: 'start',
                      display: 'flex',
                      flexDirection: 'column',
                      height: '350px',margin:"19px 0px 0px 0px",
                    }}
                  >
                
                  <img
                    src={`nodeApp/uploads/${record1.image}`}
                    alt={`${record1.en_fname}'s Photo`} style={{height:"250px"}}
                    className="small-photo mb-2 border"
                  />
                
                  </div>

                  <div className="col-12 col-md-9 pt-3">
                    {/* Basic Information */}
                    <div
                      className="row m-0 border"
                      style={{ backgroundColor: '#e9e0e3' }}
                    >
                      <div className="col-12 border-bottom" style={info}>
                        <h6>Basic Information/मुलभूत माहिती</h6>
                      </div>
                      <div
                        className="col-12 d-flex"
                        style={{ flexWrap: 'wrap' }}
                      >
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>First Name (English)</p>
                          </div>
                          <div className="col-6 col-md-8">:{" "}{record1.en_fname}</div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>Middle Name(English)</p>
                          </div>
                          <div className="col-6 col-md-8">
                            :{' '}
                            {record1.en_mname}
                          </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>Last Name (English)</p>
                          </div>
                          <div className="col-6 col-md-8">
                            :{' '}
                            {record1.en_lname}
                          </div>
                        </div>
                      </div>

                      <div
                        className="col-12 d-flex"
                        style={{ flexWrap: 'wrap' }}
                      >
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>पहिले नाव (मराठी)</p>
                          </div>
                          <div className="col-6 col-md-8">
                            :{' '}
                            {record1.mr_fname}
                          </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>मधले नाव (मराठी)</p>
                          </div>
                          <div className="col-6 col-md-8">:{" "}{record1.mr_mname}</div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>आडनाव (मराठी)</p>
                          </div>
                          <div className="col-6 col-md-8">
                            :{' '}
                            {record1.mr_lname}
                          </div>
                        </div>
                      </div>

                      <div
                        className=" col-12 d-flex p-2"
                        style={{ flexWrap: 'wrap' }}
                      >
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>Gender/लिंग</p>
                          </div>
                          <div className="col-6 col-md-8">
                          :{" "} {record1.gender === 1 ? 'Male' : 'Female'}
                          </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>Marital Status/वैवाहिक स्थिती</p>
                          </div>
                          <div className="col-6 col-md-8">
                          :{" "}{record1.marital_status}
                          </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>Complextion/रंग</p>
                          </div>
                          <div className="col-6 col-md-8">
                          :{" "}{record1.complextion}
                          </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>Disability/दिव्यांग</p>
                          </div>
                          <div className="ccol-6 col-md-8">
                            :{' '}{record1.disability === "1" ? 'No' : 'Yes'}
                          </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>Date of birth/जन्मतारीख</p>
                          </div>
                          <div className="col-6 col-md-8">:{" "}{record1.dob} </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>Birth time/जन्मवेळ</p>
                          </div>
                          <div className="col-6 col-md-8">
                            :{' '}
                            {record1.birth_time}{' '}
                          </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>Height/उंची</p>
                          </div>
                          <div className="col-6 col-md-8"> :{" "}{record1.height} </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>Subcast/पोटजात</p>
                          </div>
                          <div className="col-6 col-md-8">
                            :{' '}
                            {record1.subcastename}
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Education Information */}
                    <div
                      className="row m-0 border mt-3"
                      style={{ backgroundColor: '#e9e0e3' }}
                    >
                      <div className="col-12 border-bottom" style={info}>
                        <h6>Education & Occupation Information/शिक्षण आणि व्यवसाय माहिती</h6>
                      </div>
                      <div
                        className=" col-12 d-flex p-2"
                        style={{ flexWrap: 'wrap' }}
                      >
                        <div className="col-12 col-md-6 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>Education/शिक्षण</p>
                          </div>
                          <div className="col-6 col-md-8">
                            :{' '}
                            {record1.education_name}
                          </div>
                        </div>
                        <div className="col-12 col-md-6  d-flex">
                          <div className="col-6 col-md-4 para">
                            <p style={{ lineHeight: '18px' }}>
                              Education Category/शिक्षण श्रेणी
                            </p>
                          </div>
                          <div className="col-6 col-md-8">
                          :{" "}{record1.subeducation_name}
                          </div>
                        </div>
                        <div className="col-12 col-md-6  d-flex">
                          <div className="col-6 col-md-4 para">
                            <p style={{ lineHeight: '18px' }}>
                              Occupation Type/ व्यवसायाचा प्रकार
                            </p>
                          </div>
                          <div className="col-6 col-md-8">
                            :{' '}
                            {record1.occupation}
                          </div>
                        </div>
                        <div className="col-12 col-md-6 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>Annual Income/वार्षिक उत्पन्न</p>
                          </div>
                          <div className="col-6 col-md-8">:{" "} {record1.amount} </div>
                        </div>
                      </div>

                      <div className="row d-flex">
                        <div className="col-6 col-md-4 para">
                          <p style={{ lineHeight: '18px' }}>
                            Occupation & Addrress/व्यवसाय आणि पत्ता
                          </p>
                        </div>
                        <div className="col-6 col-md-8">
                          :{' '}
                          {record1.occupation_detail}
                        </div>
                      </div>
                    </div>

                    {/* Family Information */}
                    <div
                      className="row m-0 border mt-3"
                      style={{ backgroundColor: '#e9e0e3' }}
                    >
                      <div className="col-12 border-bottom" style={info}>
                        <h6>Family Information/कौटुंबिक माहिती</h6>
                      </div>
                      <div
                        className=" col-12 p-2 d-flex"
                        style={{ flexWrap: 'wrap' }}
                      >
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>Father First Name(English)</p>
                          </div>
                          <div className="col-6 col-md-8">
                          :{" "}{record1.en_mname}
                          </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>Father Middle Name(English)</p>
                          </div>
                          <div className="col-6 col-md-8">
                          :{" "}{record1.en_fathermname}
                          </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>Father Last Name(English)</p>
                          </div>
                          <div className="col-6 col-md-8">
                            :{' '}
                            {record1.en_lname}
                          </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>वडिलांचे नाव(मराठी)</p>
                          </div>
                          <div className="col-6 col-md-8">
                            :{' '}
                            {record1.mr_fatherfname}
                          </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>वडिलांचे मधले नाव(मराठी)</p>
                          </div>
                          <div className="col-6 col-md-8">
                            :{record1.mr_fathermname}
                          </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>वडिलांचे आडनाव(मराठी)</p>
                          </div>
                          <div className="col-6 col-md-8">
                            :{' '}
                            {record1.mr_fatherlname}
                          </div>
                        </div>
                        <div className="col-12 col-md-6 d-flex">
                          <div className="col-6 para">
                            <p>Farm in Acar/शेत जमीन एकर</p>
                          </div>
                          <div className="col-6">:{" "}{record1.farm_ac}</div>
                        </div>
                        <div className="col-12 col-md-6 d-flex">
                          <div className="col-6 para">
                            <p>Farm in Guntha/शेत जमीन गुंठे</p>
                          </div>
                          <div className="col-6">:{" "}{record1.farm_g}</div>
                        </div>
                        <div className="col-12 d-flex">
                          <div className="col-6 col-md-3 para">
                            <p>Other Property/इतर मालमत्ता</p>
                          </div>
                          <div className="col-6 col-md-9">
                          :{" "}{record1.other_property}
                          </div>
                        </div>
                      </div>
                      <div
                        className=" col-12 d-flex p-2"
                        style={{ flexWrap: 'wrap' }}
                      >
                        <div className="col-12 col-md-6">
                          <div className="col-12 para">
                            <p>Brother/भाऊ:</p>
                          </div>
                            <div className='d-flex' style={{textAlign:"center"}}>
                              <div className="col-6">
                                Unmarried/अविवाहित : {record1.b_unmarried}
                              </div>
                              <div className="col-6">
                                Married/विवाहित : {record1.b_married}
                              </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="col-12 para">
                            <p>Sister/बहीण:</p>
                          </div>
                            <div  className='d-flex' style={{textAlign:"center"}}>
                              <div className="col-6">
                                Unmarried/अविवाहित : {record1.s_unmarried}
                              </div>
                              <div className="col-6">
                                Married/विवाहित : {record1.s_married}
                              </div>
                            </div>
                        </div>
                      </div>
                    </div>

                    {/* Contact Information */}
                    <div
                      className="row m-0 border mt-3"
                      style={{ backgroundColor: '#e9e0e3' }}
                    >
                      <div className="col-12 border-bottom" style={info}>
                        <h6>Contact Information/संपर्क माहिती</h6>
                      </div>
                      <div
                        className="col-12 p-2 d-flex"
                        style={{ flexWrap: 'wrap' }}
                      >
                        <div className="col-12 col-md-7 d-flex">
                          <div className="col-3  para">
                            <p>Address(English)</p>
                          </div>
                          <div className="col-9 ps-2">
                          :{" "} {record1.en_address}{' '}
                          </div>
                        </div>
                        <div className="col-12 col-md-5 d-flex">
                          <div className="col-4 para ms-md-2">
                            <p>Village/City(English)</p>
                          </div>
                          <div className="col-8 ps-3">
                          :{" "}{record1.en_village}
                          </div>
                        </div>
                        <div className="col-12 col-md-7 d-flex">
                          <div className="col-3 para">
                            <p>पत्ता(मराठी)</p>
                          </div>
                          <div className="col-9 ps-2">
                          :{" "} {record1.mr_address}
                          </div>
                        </div>
                        <div className="col-12 col-md-5 d-flex">
                          <div className="col-4 para ms-md-2">
                            <p>गाव/शहर(मराठी)</p>
                          </div>
                          <div className="col-8 ps-2">
                            :{' '}
                            {record1.mr_village}{' '}
                          </div>
                        </div>
                      </div>

                      <div
                        className=" col-12 p-2 d-flex"
                        style={{ flexWrap: 'wrap' }}
                      >
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-4 para">
                            <p>State(English)</p>
                          </div>
                          <div className="col-8 ps-2">
                            :{' '}
                            {record1.stateenname}
                          </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-4 para">
                            <p>District(English)</p>
                          </div>
                          <div className="col-8 ps-2">
                            :{' '}
                            {record1.districtenname}
                          </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-4 para">
                            <p>Taluka(English)</p>
                          </div>
                          <div className="col-8 ps-2">
                          :{" "}{record1.talukaenname}
                          </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-4 para">
                            <p>राज्य(मराठी)</p>
                          </div>
                          <div className="col-8 ps-2">
                          :{" "}{record1.statemrname}
                          </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-4 para">
                            <p>जिल्हा(मराठी)</p>
                          </div>
                          <div className="col-8 ps-2">
                          :{" "}{record1.districtmrname}
                          </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-4 para">
                            <p>तालुका(मराठी)</p>
                          </div>
                          <div className="col-8 ps-2">
                          :{" "}{record1.talukamrname}
                          </div>
                        </div>
                      </div>
                      <div
                        className=" col-12 d-flex p-2"
                        style={{ flexWrap: 'wrap' }}
                      >
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-4 para">
                            <p style={{ lineHeight: '18px' }}>Email/ईमेल</p>
                          </div>
                          <div className="col-8">
                            <p>:{" "}{record1.emailid}</p>
                          </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-4 para">
                            <p style={{ lineHeight: '18px' }}>
                              Mobile No-1/मोबाईल नंबर-1
                            </p>
                          </div>
                          <div className="col-8">
                            <p>:{" "}{record1.mobileno}</p>
                          </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-4 para">
                            <p style={{ lineHeight: '18px' }}>
                              Mobile No-2/मोबाईल नंबर-2
                            </p>
                          </div>
                          <div className="col-8">
                            <p>:{" "}{record1.mobileno2}</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Expectations about spouse */}
                    <div
                      className="row m-0 border mt-3 mb-3"
                      style={{ backgroundColor: '#e9e0e3' }}
                    >
                      <div className="col-12 border-bottom" style={info}>
                        <h6>Expectations About Spouse/जोडीदाराबद्दल अपेक्षा</h6>
                      </div>
                      <div className=" col-12 p-2">
                        <div className="row d-flex">
                          <div className="col-5 col-md-3  para">
                            <p>Expectations/अपेक्षा:</p>
                          </div>
                          <div className="col-7 col-md-9"> <p>{record1.exp_housewife === 1 ? 'गृहिणी' : null} &nbsp; {record1.exp_compatible === 1 ? 'अनुरूप' : null} &nbsp; {record1.exp_farmer === 1 ? 'शेती' : null}{" "}{record1.exp_serviceman === 1 ? 'नोकरी' : null} &nbsp; {record1.exp_businessman === 1 ? 'व्यवसाय' : null} &nbsp; </p></div>
                        </div>
                      </div>
                    </div>
                  </div>

                  
                </div>
              )}
              {showOperationButtons && (
              <div className="operation-buttons">
                <button className='button1' onClick={() => handleOperationClick('approve')}>Disability Approve</button>
                <button className='button2' onClick={() => handleOperationClick('reject')}>Disability Reject</button>
              </div>
              
            )}
            
            </div>
            
          </div>
        )}

    </div>
    <div style={footerStyle} className='mt-5 fixed-bottom'>
        &copy; 2023-24 Jain Boarding | Managed By : TechnoAarv Solution
      </div>
    </>
  );
};

export default DisabilityProfiles;






