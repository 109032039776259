import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import UserMasterFooter from '../UserCommonComponent/UserMasterFooter';
import Logo from '../../assets/Mainlogo.jpg'
import Logo2 from '../../assets/logo2.png'
import { Link } from 'react-router-dom';
import {useNavigate} from 'react-router-dom';


function PrivacyPolacy() {

 // Scroll to the top when component is mounted
 useEffect(() => {
  window.scrollTo(0, 0);
}, []);


  return (
 <div>
    <div>
    <Row className='header m-0 p-0 py-1'>
        <Col md={3} sm={12} style={{display:"flex",justifyContent:"center"}}>
            <div>
                <img src={Logo2} style={{mixBlendMode:"darken"}} alt="" width={100} height={100}/>
            </div> 
       </Col> 
       
       <Col md={6} sm={12} style={{display:"flex",justifyContent:"center"}}>
        <div className='text-center '>
            <h5 className='fw-bolder'>दक्षिण भारत जैन सभेचे</h5>
            <h3 className='fw-bolder'>शेठ रा. ध. दावडा दिगंबर जैन बोर्डिंग, सांगली </h3>
            <h5 className='fw-bolder'>जैन वधू - वर नोंदणी 2024</h5>
        </div>
        </Col> 

        <Col md={3} sm={12} style={{display:"flex",justifyContent:"center"}}>
            <div>
                <img src={Logo} style={{mixBlendMode:"darken"}} alt="" width={100} height={100}/>
            </div> 
       </Col> 

        {/* <Col md={1} sm={12} style={{display:"flex",justifyContent:"start"}}>
            <Nav.Link onClick={logoutFun}>Log-Out <i class="fa-solid fa-right-from-bracket"></i></Nav.Link>
        </Col>       */}
      </Row> 

      <Navbar expand="lg" style={{backgroundColor: "#660505"}}>
      <Container fluid>
        <Navbar.Brand href="#"></Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" style={{backgroundColor:"rgb(233, 224, 227)"}} />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="m-auto my-2 my-lg-0"
            style={{ maxHeight: '100px' }}
            navbarScroll
          >
            <Nav.Link href="/" className=' mx-2 text-white'>Home</Nav.Link>
            <Nav.Link href="/#/ReturnAndRefundPrivacy" className=' mx-2 text-white'>Return & Refund Policy</Nav.Link>
            <Nav.Link href="/#/Cancelation" className=' mx-2 text-white'>Cancelation Policy</Nav.Link>
            <Nav.Link href="/#/terms&Condition" className=' mx-2 text-white'>Terms & Condition</Nav.Link>
            <Nav.Link href="/#/PrivacyPolicy" className=' mx-2 text-white'>Privacy Policy</Nav.Link>
          </Nav>
          
        </Navbar.Collapse>
        </Container>
    </Navbar>
    </div>
    <div className='p-md-4 p-3 mx-md-5'>
      <Nav.Link href="/privacyPolicy" style={{color: "rgb(134, 28, 18)"}}><h3><b>Privacy Policy</b></h3></Nav.Link>
      <p>
      We respect the privacy of those who visit our website. In accordance with this, we have created this policy in order to demonstrate our privacy commitment to our users.
      </p>
      <h5>What this Privacy Policy Covers?</h5>
      <p>
      This Privacy Policy covers our treatment of personally identifiable information that we collect when you are on our site, and when you use our services. This policy also covers our treatment of any personally identifiable information that third parties share with us.</p>
      <p>
      This policy does not apply to the practices of organizations that we do not own or control, or to people that we do not employ or manage.
      </p>
      <h5>Information Collection and Use</h5>
      <p>
      We collect personally identifiable information when you register on our website, when you use our services, and when you visit our pages. We may also receive personally identifiable information from third parties.
      </p>
      <p>
      When you register with us, we ask for your name, email address, zip code, occupation, industry, and personal interests. Once you register with us and sign in to our services, you are not anonymous to us.
      </p>
      <p>
      The e-mail or contact number address You submit to receive information from Dakshin Bharat Jain Sabha will never be disclosed to any third party. All e-mail that You receive will be from the domain names jainboardingsangli@gmail.com
      </p>
      <p>
      TechnoAarv Solutions Pvt Ltd has adopted and adheres to stringent security standards designed to protect non-public personal information at this Website against accidental or unauthorized access or disclosure. Among the safeguards that TechnoAarv Solutions Pvt Ltd has developed for this Website are administrative, physical and technical barriers that together form a protective firewall around the information stored at this site.
      </p>
      <p>
      We use this information for three general purposes: to customize the content you see, to fulfill your requests for certain services, and to contact you about services.
      </p>
      <p>
      We also automatically receive and record information on our server logs from your browser including your IP address, cookie information and the page you requested. This information is not linked to your account and will not be used to identify you.
      </p>
      <h5>Information Sharing and Disclosure</h5>
      <p>We will not sell or rent your personally identifiable information to anyone. We will send personally identifiable information about you to other companies or people when:</p>
      <p>
      We have your consent to share the information. <br/>
      We respond to subpoenas, court orders or legal process,;<br/>
      or We find that your actions on our web sites violate the Terms of Service
      </p>
      <h5>Changes to this Privacy Policy</h5>
      <p>
      The privacy policy is subject to modification from time to time. If we decide to change our privacy policy, we will post those changes here so that you will always know what information we gather, how we might use that information, and whether we will disclose it to anyone. Any significant changes to our privacy policy will be announced on our home page. If you do not agree with the changes in our policy you can simply discontinue to visit our website.
      </p>
      <h5>Questions or Suggestions</h5>
      <p>If you have questions or suggestions send an email to us:</p>
      <p><a >jainboardingsangli@gmail.com</a></p>
      </div>
      <div>
      <Container fluid className='footer1-container p-md-2 m-0 px-md-5' style={{boxSizing:"border-box"}}>
      <Row className='py-1' >
        <Col sm={12} md={5} className='text-white text-center  py-3 border'>
        <div >
          <img src={Logo}  alt="" width={100} height={100}/>
        </div> 
        <div className='text-center' style={{color: "rgb(134, 28, 18)"}}>
            <h6 className='fw-bolder'>दक्षिण भारत जैन सभेचे</h6>
            <h5 className='fw-bolder'>शेठ रा. ध. दावडा दिगंबर जैन बोर्डिंग, सांगली </h5>
            <h6 className='fw-bolder'>जैन वधू - वर नोंदणी 2024</h6>
        </div>
        </Col>
        <Col sm={12} md={3} className='text-center py-3 border' style={{lineHeight:"40px"}}>
            <div className='text-start ps-5'>
            <h4  style={{color:"rgb(134, 28, 18)", paddingBottom:"15px"}}><b>Information</b></h4>
            </div>
            <div className='text-start ps-5'>
            <Link to="/PrivacyPolicy"
                  style={{textDecoration:"none", color:"white",color: "rgb(134, 28, 18)" }} >
                   <i style={{paddingRight:"10px"}} class="fa-solid fa-greater-than"></i>   
                    Privacy Policy 
            </Link><br/>
            <Link to="/terms&Condition"
                  style={{textDecoration:"none", color:"white",color: "rgb(134, 28, 18)"}} >
                  <i style={{paddingRight:"10px"}} class="fa-solid fa-greater-than"></i>  
                  Terms & Condition
            </Link><br/>
            <Link to="/ReturnAndRefundPrivacy"
                  style={{textDecoration:"none", color:"white",color: "rgb(134, 28, 18)"}} >
                  <i style={{paddingRight:"10px"}} class="fa-solid fa-greater-than"></i>  
                  Return & Refund Policy
            </Link><br/>
            <Link to="/Cancelation"
                  style={{textDecoration:"none", color:"white",color: "rgb(134, 28, 18)"}} >
                  <i style={{paddingRight:"10px"}} class="fa-solid fa-greater-than"></i>  
                  Cancelation Policy
            </Link><br/>
            {/* <Link to="/Registration1"
                  style={{textDecoration:"none", color:"white",color: "rgb(134, 28, 18)"}} >
                  <i style={{paddingRight:"10px"}} class="fa-solid fa-greater-than"></i>  
                  Registration
            </Link><br/> */}
            <Link to="/loginBarCode"
                  style={{textDecoration:"none", color:"white",color: "rgb(134, 28, 18)"}} >
                  <i style={{paddingRight:"10px"}} class="fa-solid fa-greater-than"></i>  
                  Admin Login(Entry Pass)
            </Link><br/>
            <Link to="/admin"
                  style={{textDecoration:"none", color:"white",color: "rgb(134, 28, 18)"}} >
                  <i style={{paddingRight:"10px"}} class="fa-solid fa-greater-than"></i>  
                  Admin Login
            </Link><br/>
            </div>
        </Col>
        <Col sm={12} md={4} className='text-center py-3' style={{lineHeight:"40px"}}>
            <div className='text-start ps-5'>
            <h4  style={{color: "rgb(134, 28, 18)", paddingBottom:"15px"}}><b>Contact</b></h4>
            </div>
            <div className='text-start ps-5'>
            <a style={{textDecoration: "none",  color: "rgb(134, 28, 18)" }}> 
              <i style={{ paddingRight: "10px" }} class="fa-solid fa-address-book"></i>
              <span>Dakshin Bharat Jain Sabha's Sheth RDD Jain Boarding, Sangli</span>
              </a>
              <br/>
            <a style={{textDecoration: "none",  color: "rgb(134, 28, 18)" }}> 
              <i style={{ paddingRight: "10px" }} class="fa-solid fa-location-dot"></i>
              <b>Address</b> : <span>37, Mahavirnagar, Opp Sangli Highschool, Sangli-416416</span>
              </a>
              <br/>
            <a href="mailto:jainboardingsangli@gmail.com" style={{textDecoration: "none",  color: "rgb(134, 28, 18)" }}>
              <i style={{ paddingRight: "10px" }} className="fa-solid fa-envelope"></i>   
              <b>Email</b> : <span>jainboardingsangli@gmail.com</span>
              </a>
              <br/>
              <a href="tel:02332623206" style={{ textDecoration: "none",  color: "rgb(134, 28, 18)" }}>
              <i style={{ paddingRight: "10px" }} className="fa-solid fa-phone"></i> 
             <b>Contact</b> : 0233 2623206
            </a><br/>
            </div>
        </Col>
      </Row>
      <Row>
        <Col>
        <div style={{display:"flex", justifyContent:"center", color:"white", padding:"20px 0px 20px 0px", borderTop:"1px solid rgb(134, 28, 18) ",color: "rgb(134, 28, 18)"}}>
            &copy; 2024 Jain Boarding | Managed By : TechnoAarv Solution
        </div>
        </Col>
      </Row>
    </Container>
      </div>
    </div>
   )
} 

export default PrivacyPolacy

