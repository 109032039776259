import React, { useState,useEffect } from 'react'
import './Members.css'
import AdminMasterHeader from '../../CommonComponenets/AdminMasterHeader'
import axios from 'axios';
import { Col, Form, Row } from 'react-bootstrap';
import { PaymentApproveSave, adminPaymentPendingList, adminPaymentPendingListOnline, memberImgPath } from '../../AllApi/AdminApi/AdminApi';
import { MDBDataTable } from 'mdbreact';
import { toast } from 'react-toastify';
import AdminMasterFooter from '../../CommonComponenets/AdminMasterFooter';

const PaymentPendingMemberOnline = () => {

  const [record, setRecord] = useState([])
  const [showLargePhoto, setShowLargePhoto] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);

     //----------------------------------API code------------------------------------------

     useEffect(() => {
      const tokenValue = sessionStorage.getItem('token')
  
      const tokenConfig = {
        headers: {
          token: tokenValue,
        },
      }
  
      const fetchMemberData = async () => {
        try {
          const response = await axios.get(
            adminPaymentPendingListOnline,
            tokenConfig);
          setRecord(response.data);

        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
    fetchMemberData()
  }, [])


  //--------------------------Image click code-----------------------------------

  const handleImageClick = (member) => {
    
    setSelectedMember(member)
    setShowLargePhoto(true)
  }

  const handleCloseLargePhoto = () => { 
    setShowLargePhoto(false);
  };


   //CSS for Information-------------------------------------------------------------------
 
  const footerStyle = {
    backgroundColor:"#660505",
    boxShadow: "0 2px 4px -2px rgba(0,0,0,.5)",
    padding:"8px",
    textAlign:"center",
    color:"#fff",
    fontSize:"12px",
    bottom:0,
    width:"100%",
    letterSpacing:"1px",
  }

  //=====================================DATA TABLE============================================


const columns = [
  {
    label: 'Sr.no',
    field: 'srNo',
    sort: 'asc',
  },
  {
    label: 'Member Id',
    field: 'member_id',
    sort: 'asc',
  },
  {
    label: 'Reg.Id',
    field: 'reg_id',
    sort: 'asc',
  },
  {
    label: 'Name',
    field: 'name_en',
    sort: 'asc',
  },
  {
    label: 'Gender',
    field: 'gender',
    sort: 'asc',
  },
  {
    label: 'Email Id',
    field: 'emailid',
    sort: 'asc',
  },
  {
    label: 'Mobile No',
    field: 'mobileno',
    sort: 'asc',
  },
  {
    label: 'View Photo',
    field: 'action1',
  },

];


const customRows = record.map((item, index) => {
  const { gender, name_en, mobileno, emailid,member_id,reg_id,marital_status } = item;
  const genderLabel = gender === 1 ? 'Male' : 'Female';
  return {
    srNo: index + 1,
    marital_status,
    member_id,
    reg_id,
    name_en,
    gender:genderLabel,
    mobileno,
    emailid,
    action1: (
      <button className="viewButtons1" type='submit' onClick={() => handleImageClick(item)}>View Photo</button>
      
    ),
  };
});

//=====================================================================================================

  return (
   
    <>
    <div className="row m-0">
      <div className='p-0'> <AdminMasterHeader/> </div>
      <div className="filter-buttons row">

        <div className='col-12 ps-0'>
        <h2 style={{ fontWeight: '550',textAlign:"start"}}>Payment Pending Members</h2>
        </div>
        </div>
        

     <Row className='row p-0 m-0' style={{justifyContent:"center",display:"flex",textAlign:"center"}}>
        <div className="col-12 col-sm-1"></div>
        <div className="col-12 col-sm-10">
            <MDBDataTable
              className='mb-5 custom-datatable'
              striped
              bordered
              hover
              small
              paging={!showLargePhoto}
              data={{ columns, rows: customRows }}
              style={{ textAlign: "center", fontSize: "14px" ,}}/>
        </div>
        <div className="col-12 col-sm-1"></div>
    </Row>

     
     {showLargePhoto && (
        <div className="large-photo-modal">
          <div className="modal-contentPhotoApp col-12 col-md-4">
          <span className="close1 ms-auto fs-1" onClick={handleCloseLargePhoto}>&times;</span>
            <div className='col-12' style={{textAlign:"center",display:"flex",height:"350px"}}>
            <img
              src={`${memberImgPath}/${selectedMember.image}`}
              alt={`${selectedMember.name_en}'s Photo`}
              className="large-photo"
            />
          </div>
          </div>
        </div>
      )}


    </div>
    <AdminMasterFooter/>
    </>
  )
}

export default PaymentPendingMemberOnline