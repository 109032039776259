import React,{useEffect,useState} from 'react'
import axios from 'axios'
import './LoginQr.css'
import {useNavigate} from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Logo from '../assets/Mainlogo.jpg';
import Logo2 from '../assets/logo2.png';
import beforelogo from '../assets/login-before.png'
import Registartion1 from '../UserComponents/LoginNavComponents/Registration1';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import { BaseIP, Forgetuser, QrAdminLogin } from '../AllApi/UserApi/UserApi';
import { Form, Popover } from 'react-bootstrap';
import { toast } from 'react-toastify';



function LoginBarCode() {

    const navigate = useNavigate()
    
     // Scroll to the top when component is mounted
 useEffect(() => {
  window.scrollTo(0, 0);
}, []);

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    
  
    const saveFun = async (e) => {
      e.preventDefault()
      
  
      const logObj = {
        email: email,
        password: password,
      }
  
      try {
        const response = await axios.post(
          QrAdminLogin,
          logObj,
        )
  
        if (response.data === 'NOTFOUND') {
        //   alert('Invalid login details')
        toast.error('Invalid login details', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        } else if (response.data !== null) {
          const token = response.data.token
          axios.defaults.headers.common['token'] = `Bearer ${token}`
  
          sessionStorage.setItem('userdata',JSON.stringify(response.data.userdata),
          )
          sessionStorage.setItem('token','Bearer ' + response.data.token)
          navigate('/scanPage')
        } else {
        //   alert('Invalid login details')
        toast.error('Invalid login details', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,

          });
        }
      } catch (error) {
        console.error('Error:', error) 
        toast.error('Server Error', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        
      }
    }
    
    const back = () => {
      sessionStorage.removeItem("userdata");
      sessionStorage.clear()
      navigate("/");       
    }

  return (<>
    <Container fluid className='p-0 '>
      <Row className='header m-0 p-0 py-1'>
        <Col md={3} sm={12} style={{display:"flex",justifyContent:"center"}}>
            <div>
                <img src={Logo2} style={{mixBlendMode:"darken"}} alt="" width={100} height={100}/>
            </div> 
       </Col> 
       
       <Col md={5} sm={12} style={{display:"flex",justifyContent:"center"}}>
        <div className='text-center '>
            <h5 className='fw-bolder'>दक्षिण भारत जैन सभेचे</h5>
            <h3 className='fw-bolder'>शेठ रा. ध. दावडा दिगंबर जैन बोर्डिंग, सांगली </h3>
            <h5 className='fw-bolder'>जैन वधू - वर नोंदणी 2024</h5>
        </div>
        </Col>    

        <Col md={3} sm={12} style={{display:"flex",justifyContent:"center"}}>
            <div>
                <img src={Logo} style={{mixBlendMode:"darken"}} alt="" width={100} height={100}/>
            </div> 
       </Col>    

       <Col md={1} sm={12} style={{display:"flex",justifyContent:"center"}}>
       <Nav.Link onClick={back}><button className='py-2 px-4 rounded' style={{backgroundColor:"red",color:"white" ,border:"0px"}}><b>Back</b></button></Nav.Link>
        </Col> 
      </Row>   
    <Row className='wrap pb-5 m-0'>
        <Col md={6}></Col>       
        <Col md={6}> <div className='login-container pt-md-5'>     
         <div className='login-form py-5'>
            <form onSubmit={saveFun}>
                <div className='input'>
                    <div style={{display:"flex",justifyContent:"center"}}>
                       <img  src={beforelogo}/>
                    </div>
                    
                    <h1 className='pt-3'>Entry Pass Login</h1>
                    <input id='input' value={email} type="email" placeholder='Email' onChange={(e)=>setEmail(e.target.value) }  required/>

                    <input id='input1' value={password} type="password" placeholder='Password' onChange={(e)=> setPassword(e.target.value)}  required/>

                    <div className='login-button'>
                    <button type="submit"  className='login-btn' >LOGIN</button>
                    </div>
                </div>

            </form> 
           
          </div>
        </div>
       </Col>
    </Row>

      {/* -----------------footer login --------------------------- */}
      <Container fluid className='footer1-container p-md-2 m-0 px-md-5' style={{boxSizing:"border-box"}}>
      <Row className='py-1' >
        <Col sm={12} md={5} className='text-white text-center  py-3 border'>
        <div >
          <img src={Logo}  alt="" width={100} height={100}/>
        </div> 
        <div className='text-center' style={{color: "rgb(134, 28, 18)"}}>
            <h6 className='fw-bolder'>दक्षिण भारत जैन सभेचे</h6>
            <h5 className='fw-bolder'>शेठ रा. ध. दावडा दिगंबर जैन बोर्डिंग, सांगली </h5>
            <h6 className='fw-bolder'>जैन वधू - वर नोंदणी 2024</h6>
        </div>
        </Col>
        <Col sm={12} md={3} className='text-center py-3 border' style={{lineHeight:"40px"}}>
            <div className='text-start ps-5'>
            <h4  style={{color:"rgb(134, 28, 18)", paddingBottom:"15px"}}><b>Information</b></h4>
            </div>
            <div className='text-start ps-5'>
            <Link to="/PrivacyPolicy"
                  style={{textDecoration:"none", color:"white",color: "rgb(134, 28, 18)" }} >
                   <i style={{paddingRight:"10px"}} class="fa-solid fa-greater-than"></i>   
                    Privacy Policy 
            </Link><br/>
            <Link to="/terms&Condition"
                  style={{textDecoration:"none", color:"white",color: "rgb(134, 28, 18)"}} >
                  <i style={{paddingRight:"10px"}} class="fa-solid fa-greater-than"></i>  
                  Terms & Condition
            </Link><br/>
            <Link to="/ReturnAndRefundPrivacy"
                  style={{textDecoration:"none", color:"white",color: "rgb(134, 28, 18)"}} >
                  <i style={{paddingRight:"10px"}} class="fa-solid fa-greater-than"></i>  
                  Return & Refund Policy
            </Link><br/>
            <Link to="/Cancelation"
                  style={{textDecoration:"none", color:"white",color: "rgb(134, 28, 18)"}} >
                  <i style={{paddingRight:"10px"}} class="fa-solid fa-greater-than"></i>  
                  Cancelation Policy
            </Link><br/>
            {/* <Link to="/Registration1"
                  style={{textDecoration:"none", color:"white",color: "rgb(134, 28, 18)"}} >
                  <i style={{paddingRight:"10px"}} class="fa-solid fa-greater-than"></i>  
                  Registration
            </Link><br/> */}
            <Link to="/loginBarCode"
                  style={{textDecoration:"none", color:"white",color: "rgb(134, 28, 18)"}} >
                  <i style={{paddingRight:"10px"}} class="fa-solid fa-greater-than"></i>  
                  Admin Login(Entry Pass)
            </Link><br/>
            <Link to="/admin"
                  style={{textDecoration:"none", color:"white",color: "rgb(134, 28, 18)"}} >
                  <i style={{paddingRight:"10px"}} class="fa-solid fa-greater-than"></i>  
                  Admin Login
            </Link><br/>
            </div>
        </Col>
        <Col sm={12} md={4} className='text-center py-3' style={{lineHeight:"40px"}}>
            <div className='text-start ps-5'>
            <h4  style={{color: "rgb(134, 28, 18)", paddingBottom:"15px"}}><b>Contact</b></h4>
            </div>
            <div className='text-start ps-5'>
            <a style={{textDecoration: "none",  color: "rgb(134, 28, 18)" }}> 
              <i style={{ paddingRight: "10px" }} class="fa-solid fa-address-book"></i>
              <span>Dakshin Bharat Jain Sabha's Sheth RDD Jain Boarding, Sangli</span>
              </a>
              <br/>
            <a style={{textDecoration: "none",  color: "rgb(134, 28, 18)" }}> 
              <i style={{ paddingRight: "10px" }} class="fa-solid fa-location-dot"></i>
              <b>Address</b> : <span>37, Mahavirnagar, Opp Sangli Highschool, Sangli-416416</span>
              </a>
              <br/>
            <a href="mailto:jainboardingsangli@gmail.com" style={{textDecoration: "none",  color: "rgb(134, 28, 18)" }}>
              <i style={{ paddingRight: "10px" }} className="fa-solid fa-envelope"></i>   
              <b>Email</b> : <span>jainboardingsangli@gmail.com</span>
              </a>
              <br/>
              <a href="tel:02332623206" style={{ textDecoration: "none",  color: "rgb(134, 28, 18)" }}>
              <i style={{ paddingRight: "10px" }} className="fa-solid fa-phone"></i> 
             <b>Contact</b> : 0233 2623206
            </a><br/>
            </div>
        </Col>
      </Row>
      <Row>
        <Col>
        <div style={{display:"flex", justifyContent:"center", color:"white", padding:"20px 0px 20px 0px", borderTop:"1px solid rgb(134, 28, 18) ",color: "rgb(134, 28, 18)"}}>
            &copy; 2024 Jain Boarding | Managed By : TechnoAarv Solution
        </div>
        </Col>
      </Row>
    </Container>

    </Container>
    </>
  )    
}

    export default LoginBarCode


