import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import { FormControl } from 'react-bootstrap';
import beforelogo from '../../assets/registration-before.png';
import afterlogo from '../../assets/login-after.png';
import Row from 'react-bootstrap/Row';
import axios from 'axios';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Logo from '../../assets/Mainlogo.jpg';
import Logo2 from '../../assets/logo2.png';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import '../../UserComponents/UserComponent.css';
import { Link } from 'react-router-dom';
import { BaseIP, MemberRegistration } from '../../AllApi/UserApi/UserApi';
import { toast } from 'react-toastify';
import { Spinner } from 'react-bootstrap';
import { DatePicker, Modal } from 'antd';
import moment from 'moment';


const Registration1 = () => {

  const navigate = useNavigate()
  const [fName, setFName] = useState('');
  const [mName, setMName] = useState('');
  const [lName, setLName] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [gender, setGender] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  // const [confirmPassword, setConfirmPassword] = useState('');  

  const [isLoading, setIsLoading] = useState(false); // Add isLoading state

  const [isedit, setIsedit] = useState(false)
  const reg = {
    padding: "10px 20px 20px 20px",
  }

  const input = {
    border: "1px solid",
    margin: "10px 10px 10px 0px",
  }
  const inputDate = {
    border: "1px solid",
    margin: "10px 10px 10px 0px",
    height:"37px",
  }




  //Registartion Code

  //validation
  const [validated, setValidated] = useState("")
  const [Regshow, setRegshow] = useState(false);
  const handleRegClose = () => setRegshow(false);
  const handleRegShow = () => setRegshow(true);
  //data
  // const [Name, setName] = useState();
  // const [Email, setEmail] = useState();
  // const [MobileNo, setMobileNo] = useState();
  // const [City, setCity] = useState();
  // const [password, setPassword] = useState("");
  //password cheack
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [isGenderSelected, setIsGenderSelected] = useState(false);
  const [open, setOpen] = useState(false);

  const hideModal = () => {
    setOpen(false);
    navigate('/');
  };

  function handlePasswordChange(value) {
    setPassword(value);
    setPasswordsMatch(true);
  }

  function handleConfirmPasswordChange(value) {
    setConfirmPassword(value);
    setPasswordsMatch(value === password);
  }

  //Registration Handle Function
  const handleSubmitReg = (event) => {

    event.preventDefault();
    setIsLoading(true)
    // const form = event.currentTarget;
    // if (form.checkValidity() === false) {
    //   event.preventDefault();
    //   event.stopPropagation();
    //   setValidated(false);
    //   alert("Fill All Details")
    //   return;
    // }
    // setValidated(true);

    if (passwordsMatch) {
      if (isGenderSelected && isAgeValid()) {
        const reg_data = {
          fname: fName,
          mname: mName,
          lname: lName,
          mob_no: mobileNumber,
          email: email,
          password: password,
          gender: gender,
          dateofbirth: dateOfBirth
        }

        axios
          .post(MemberRegistration, reg_data)
          .then((res) => {
            setIsLoading(false);
            if (res.data === "SAVED") {
              // navigate('/');
              setOpen(true);
              // toast.success('Account Created Succesfully! Please login and register', {
              //   position: "top-right",
              //   autoClose: 3000,
              //   hideProgressBar: false,
              //   closeOnClick: true,
              //   pauseOnHover: true,
              //   draggable: true,
              // });              

            }
            else if (res.data === "ALREADYEXIST") {
              setIsLoading(false);
              toast.error('Email id already registered. Try with another email ..!!', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
            }
          })

          .catch((err) => {
            // alert("Failed To Record Your Registration , try again");
            setIsLoading(false);
            toast.error('Failed To Record Your Registration , try again', {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          });
      }
      else {
        // alert("Please select gender and ensure you meet the age criteria.");
        toast.error('Please select gender and ensure you meet the age criteria.', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }

    } else {
      // alert("password  not match");
      toast.error('password  not match.', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      setPasswordsMatch(false);
    }
  };

  const handalegender = (e) => {
    setGender(e.target.value)
    setIsGenderSelected(true)
    setIsedit(true)

  }
  // function getTodayFormatted() {
  //   const today = new Date();
  //   const year = today.getFullYear();
  //   const month = (today.getMonth() + 1).toString().padStart(2, '0');
  //   const day = today.getDate().toString().padStart(2, '0');
  //   return `${year}-${month}-${day}`;
  // }

  //const checkevaliddob=() =>{
  // const selctedob = date
  // getTodayFormatted()
  // const todaydate=getTodayFormatted()

  // const age = selctedob - todaydate
  //}
  const isAgeValid = () => {
    const selectedDate = new Date(dateOfBirth);
    const today = new Date();
    const ageDiff = today.getFullYear() - selectedDate.getFullYear();

    if (gender === "1" && ageDiff >= 21) {
      return true;
    } else if (gender === "2" && ageDiff >= 18) {
      return true;
    } else {
      return false;
    }
  };



  const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY', 'DD-MM-YYYY', 'DD-MM-YY'];


  return (
    <div>
      <div>
        <Row className='header m-0 p-0 py-1'>
          <Col md={3} sm={12} style={{ display: "flex", justifyContent: "center" }}>
            <div>
              <img src={Logo2} style={{ mixBlendMode: "darken" }} alt="" width={100} height={100} />
            </div>
          </Col>

          <Col md={6} sm={12} style={{ display: "flex", justifyContent: "center" }}>
            <div className='text-center '>
              <h5 className='fw-bolder'>दक्षिण भारत जैन सभेचे</h5>
              <h3 className='fw-bolder'>शेठ रा. ध. दावडा दिगंबर जैन बोर्डिंग, सांगली </h3>
              <h5 className='fw-bolder'>जैन वधू - वर नोंदणी 2024</h5>
            </div>
          </Col>

          <Col md={3} sm={12} style={{ display: "flex", justifyContent: "center" }}>
            <div>
              <img src={Logo} style={{ mixBlendMode: "darken" }} alt="" width={100} height={100} />
            </div>
          </Col>

          {/* <Col md={1} sm={12} style={{ display: "flex", justifyContent: "start" }}>
            <Nav.Link onClick={logoutFun}>Log-Out <i class="fa-solid fa-right-from-bracket"></i></Nav.Link>
          </Col> */}
        </Row>

        <Navbar expand="lg" style={{ backgroundColor: "#660505" }}>
          <Container fluid>
            <Navbar.Brand href="#"></Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll" style={{ backgroundColor: "rgb(233, 224, 227)" }} />
            <Navbar.Collapse id="navbarScroll">
              <Nav
                className="m-auto my-2 my-lg-0"
                style={{ maxHeight: '100px' }}
                navbarScroll
              >
                <Nav.Link href="/" className=' mx-2 text-white'>Login</Nav.Link>
                {/* <Nav.Link href="/registration1" className=' mx-2 text-white'>Registration</Nav.Link> */}
                <Nav.Link href="/#/terms&Condition" className=' mx-2 text-white'>Terms & Condition</Nav.Link>
                <Nav.Link href="/#/PrivacyPolicy" className=' mx-2 text-white'>Privacy Policy</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>

      <div className='regimg' >

      <div className='row m-0 pt-4 pb-0 text-center'>
         <span style={{fontSize:"18px",color:"white"}}>* Please fill form in English only.</span>
      </div>
        <Container >

          <div className='row' style={{ height: "100%", padding: "55px 0px" }}>
            <div className="col-1"></div>
            <div className="col-10 border rounded" style={{ backgroundColor: "#f2f6f9" }}>

              <Row className='mb-3'>
                <div style={{ display: "flex", justifyContent: "center", paddingTop: "7px" }}>
                  <img src={beforelogo} height={20} />
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <h2 className='mt-1 mb-0 pb-1' style={{ color: "rgb(134, 28, 18)" }}><b>Account Creation Form [Part-I]</b></h2>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img src={afterlogo} />
                </div>
              </Row>

              <div className='' style={reg}>
                {/* <h2 style={title}>Registration Form</h2> */}


                <Form onSubmit={handleSubmitReg}>

                  <div className='row'>
                    <div className="col-md-4">
                      <label htmlFor="firstName">Candidate's First Name:</label>
                      <FormControl
                        style={input}
                        type="text"
                        id="firstName"
                        value={fName}
                        onChange={(e) => setFName(e.target.value)}
                        required
                      />
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="middleName">Candidate's Middle Name:</label>
                      <FormControl
                        style={input}
                        type="text"
                        id="middleName"
                        value={mName}
                        onChange={(e) => setMName(e.target.value)}
                        required
                      />
                    </div>


                    <div className="col-md-4">
                      <label htmlFor="lastName">Candidate's Last Name:</label>
                      <FormControl
                        style={input}
                        type="text"
                        id="lastName"
                        value={lName}
                        onChange={(e) => setLName(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="mobileNumber">Parent's Mobile Number:</label>
                      <FormControl
                        pattern="[0-9]{10}"
                        maxlength="10"
                        title='Please enter 10 digit num.'
                        style={input}
                        type="tel"
                        id="mobileNumber"
                        value={mobileNumber}
                        onChange={(e) => {
                        const value = e.target.value;
                        if (/^\d*$/.test(value) && value.length <= 10) {
                          setMobileNumber(value); // Call setMobileNumber with the new value
                        }
                      }}
                        required
                      />

                    </div>

                    <div className="col-md-6">
                      <label htmlFor="gender">Gender :</label>
                      <Form.Select aria-label="Default select example"
                        style={input}
                        value={gender}
                        onChange={(e) => handalegender(e)}
                        required
                      >
                        <option value="">Select Gender</option>
                        <option value="1">Male</option>
                        <option value="2">Female</option>
                      </Form.Select>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="dateOfBirth">Date of birth:</label> <br/>
                      <DatePicker
                              style={inputDate} className='w-100'
                              id="dateOfBirth"
                              value={dateOfBirth} // Check for value and format it
                              format={dateFormatList}
                              onChange={(date) => setDateOfBirth(date)} 
                              required
                            />
                            
                            {/* Conditional rendering for warnings */}
                            {!isGenderSelected && (
                              <span style={{ color: "red" }}>Please select gender first.</span>
                            )}
                            {isGenderSelected && !isAgeValid() && (
                              <p style={{ color: "red" }}>
                                Age criteria not met. (Male: 21+ years, Female: 18+ years)
                              </p>
                            )}
                    </div>



                    <div className="col-md-6">
                      <label htmlFor="email">Email ID:</label>
                      <FormControl style={input}
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>


                    <div className="col-md-6">
                      <Form.Group controlId="validationCustom06">
                        <label htmlFor="password">Create Password:</label>
                        <FormControl
                          style={input}
                          type="password"
                          id="password"
                          value={password}
                          // isInvalid={!passwordsMatch}
                          onChange={(e) => handlePasswordChange(e.target.value)}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Password do not match
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>


                    <div className="col-md-6">
                      <Form.Group controlId="validationCustom06">
                        <label htmlFor="confirmPassword">Confirm Password:</label>
                        <FormControl
                          style={input}
                          type="password"
                          id="confirmPassword"
                          value={confirmPassword}
                          // isInvalid={!passwordsMatch}
                          onChange={(e) => handleConfirmPasswordChange(e.target.value)}
                          required
                        />
                         {!passwordsMatch && <small className="text-danger">Passwords do not match.</small>}
                      </Form.Group>
                    </div>

                  </div>

                  <div style={{ textAlign: "center", paddingTop: "10px", }}>
                  {isLoading ? (
              <Spinner animation="border" variant="primary" /> // Show Spinner while loading
            ) : (
                    <button type="submit" className='rounded' style={{ padding: "7px 19px", backgroundColor: "rgb(134, 28, 18)", border: "0px", color: "white", fontSize: "20px" }} >
                      Create Account
                    </button>
                    )}
                  </div>
                </Form>
              </div>
            </div>
            <div className="col-1"></div>
          </div>
        </Container>
        <div className='m-0'>
          <Container fluid className='footer1-container p-md-2 m-0 px-md-5' style={{ boxSizing: "border-box" }}>
          <Row className='py-1' >
        <Col sm={12} md={5} className='text-white text-center  py-3 '>
        <div >
          <img src={Logo}  alt="" width={100} height={100}/>
        </div> 
        <div className='text-center' style={{color: "rgb(134, 28, 18)"}}>
            <h6 className='fw-bolder'>दक्षिण भारत जैन सभेचे</h6>
            <h5 className='fw-bolder'>शेठ रा. ध. दावडा दिगंबर जैन बोर्डिंग, सांगली </h5>
            <h6 className='fw-bolder'>जैन वधू - वर नोंदणी 2024</h6>
        </div>
        </Col>
        <Col sm={12} md={3} className='text-center py-3 ' style={{lineHeight:"40px"}}>
            <div className='text-start ps-5'>
            <h4  style={{color:"rgb(134, 28, 18)", paddingBottom:"15px"}}><b>Information</b></h4>
            </div>
            <div className='text-start ps-5'>
            <Link to="/PrivacyPolicy"
                  style={{textDecoration:"none", color:"white",color: "rgb(134, 28, 18)" }} >
                   <i style={{paddingRight:"10px"}} class="fa-solid fa-greater-than"></i>   
                    Privacy Policy 
            </Link><br/>
            <Link to="/terms&Condition"
                  style={{textDecoration:"none", color:"white",color: "rgb(134, 28, 18)"}} >
                  <i style={{paddingRight:"10px"}} class="fa-solid fa-greater-than"></i>  
                  Terms & Condition
            </Link><br/>
            <Link to="/ReturnAndRefundPrivacy"
                  style={{textDecoration:"none", color:"white",color: "rgb(134, 28, 18)"}} >
                  <i style={{paddingRight:"10px"}} class="fa-solid fa-greater-than"></i>  
                  Return & Refund Policy
            </Link><br/>
            <Link to="/Cancelation"
                  style={{textDecoration:"none", color:"white",color: "rgb(134, 28, 18)"}} >
                  <i style={{paddingRight:"10px"}} class="fa-solid fa-greater-than"></i>  
                  Cancelation Policy
            </Link><br/>
            {/* <Link to="/Registration1"
                  style={{textDecoration:"none", color:"white",color: "rgb(134, 28, 18)"}} >
                  <i style={{paddingRight:"10px"}} class="fa-solid fa-greater-than"></i>  
                  Registration
            </Link><br/> */}
            <Link to="/loginBarCode"
                  style={{textDecoration:"none", color:"white",color: "rgb(134, 28, 18)"}} >
                  <i style={{paddingRight:"10px"}} class="fa-solid fa-greater-than"></i>  
                  Admin Login(Entry Pass)
            </Link><br/>
            <Link to="/admin"
                  style={{textDecoration:"none", color:"white",color: "rgb(134, 28, 18)"}} >
                  <i style={{paddingRight:"10px"}} class="fa-solid fa-greater-than"></i>  
                  Admin Login
            </Link><br/>
            </div>
        </Col>
        <Col sm={12} md={4} className='text-center py-3' style={{lineHeight:"40px"}}>
            <div className='text-start ps-5'>
            <h4  style={{color: "rgb(134, 28, 18)", paddingBottom:"15px"}}><b>Contact</b></h4>
            </div>
            <div className='text-start ps-5'>
            <a style={{textDecoration: "none",  color: "rgb(134, 28, 18)" }}> 
              <i style={{ paddingRight: "10px" }} class="fa-solid fa-address-book"></i>
              <span>Dakshin Bharat Jain Sabha's Sheth R D Dawada Digambar Jain Boarding, Sangli</span>
              </a>
              <br/>
            <a style={{textDecoration: "none",  color: "rgb(134, 28, 18)" }}> 
              <i style={{ paddingRight: "10px" }} class="fa-solid fa-location-dot"></i>
              <b>Address</b> : <span>37, Mahavirnagar, Opp Sangli Highschool, Sangli-416416</span>
              </a>
              <br/>
            <a href="mailto:jainboardingsangli@gmail.com" style={{textDecoration: "none",  color: "rgb(134, 28, 18)" }}>
              <i style={{ paddingRight: "10px" }} className="fa-solid fa-envelope"></i>   
              <b>Email</b> : <span>jainboardingsangli@gmail.com</span>
              </a>
              <br/>
              <a href="tel:02332623206" style={{ textDecoration: "none",  color: "rgb(134, 28, 18)" }}>
              <i style={{ paddingRight: "10px" }} className="fa-solid fa-phone"></i> 
             <b>Contact</b> : 0233 2623206
            </a><br/>
            </div>
        </Col>
      </Row>
            <Row>
              <Col>
                <div style={{ display: "flex", justifyContent: "center", color: "white", padding: "20px 0px 20px 0px", borderTop: "1px solid rgb(134, 28, 18) ", color: "rgb(134, 28, 18)" }}>
                  &copy; 2024 Jain Boarding | Managed By : TechnoAarv Solution
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>

      <Modal
        title="Note"
        open={open}
        closable={false}
        footer={[
        <button style={{padding:"5px 15px",border:"1px solid",borderRadius:"5px",backgroundColor:"#790903",color:"white"}} key="ok" onClick={hideModal}>
          Proceed to Part-II
        </button>,
      ]}  
      >
        <p className='text-danger'>पार्ट-1 फोर्म यशस्वीरीत्या भरला गेला आहे, पार्ट-2 चा फोर्म भरण्यासाठी परत लॉगीन करा</p>
        <p className='text-danger'>Account Created Successfully! Please login again and complete the Account Creation Form Part-II</p>
      </Modal>

    </div>

    
  )
}

export default Registration1


